import React, { useState } from "react";
import { Button } from "@mui/material";
import KaikkiKohteet from './KaikkiKohteet';  // Assuming it's in the same directory

const ChooseCustomerForDownload = () => {
    const [customer, setCustomer] = useState("");

    const handleButtonClick = (customerName) => {
        setCustomer(customerName);
    };

    // Function to determine if the current button is the active one
    const isActive = (customerName) => customer === customerName;

    const buttonStyle = (customerName) => ({
        margin: '10px',
        backgroundColor: isActive(customerName) ? '#1565c0' : '#1976d2', // Active color is darker
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1565c0',
        },
    });

    return (
        <div style={{ textAlign: 'center' }}>
            <Button
                sx={{
                    ...buttonStyle("EnersenseUudisrakentaminen"),
                    backgroundImage: 'url("/enersense_logo_2020_color.svg")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
                onClick={() => handleButtonClick("EnersenseUudisrakentaminen")}
            >
                Enersense
            </Button>
            <Button
                sx={{
                    ...buttonStyle("CableCrew"),
                    backgroundImage: 'url("/CableCrew_logo_rgb.png")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
                onClick={() => handleButtonClick("CableCrew")}
            >
                Cable Crew
            </Button>
            <Button
                sx={{
                    ...buttonStyle("Valoo"),
                    backgroundImage: 'url("/Valoo-Primary-white.svg")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
                onClick={() => handleButtonClick("Valoo")}
            >
                Valoo
            </Button>
            <Button
                sx={{
                    ...buttonStyle("Jeho"),
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
                onClick={() => handleButtonClick("Jeho")}
            >
                Jeho
            </Button>
            {customer && <KaikkiKohteet customeri={customer} />}
        </div>
    );
};

export default ChooseCustomerForDownload;
