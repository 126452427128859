// UnAuthorized.js

import React from 'react';

const UnAuthorized = () => {
    return (
        <div style={styles.container}>
            <h2>Unauthorized Access</h2>
            <p>You do not have permission to view this content.</p>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        color: '#333',
        padding: '20px',
        textAlign: 'center'
    }
}

export default UnAuthorized;
