import React, { useState } from 'react';
import DurationPicker from "./DurationPicker";
import {Alert, Box, Button, Snackbar, TextField, Typography} from "@mui/material";
import {ApolloClient, InMemoryCache, gql, createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {useAuth0} from "@auth0/auth0-react";



const EltelUudisrakentaminen = () => {
    const {user, isAuthenticated, isLoading} = useAuth0();

    const [tyomaaId, setTyomaaId] = useState('');
    const [tyomaanosoite, setTyomaanosoite] = useState('');
    const [tyoAlkoi, setTyoalkoi] = useState(undefined); // use null for date fields
    const [tyoLoppui, setTyoLoppui] = useState(undefined); // use null for date fields
    const [kaytettyAika, setKaytettyAika] = useState({ hours: 0, minutes: 0 });

    const tyonNimi = 'Eltel uudisrakentaminen';
    const nimiMerkki = user.email;

    const [page, setPage] = useState(0);

    const [oktTonttisuunnittelu, setOktTonttisuunnittelu] = useState(0);
    const [oktTonttisuunnitteluAika, setOktTonttisuunnitteluAika] = useState({ hours: 0, minutes: 0 });

    const [oktTonttikaivuu, setOktTonttikaivuu] = useState(0);
    const [oktTonttikaivuuAika, setOktTonttikaivuuAika] = useState({ hours: 0, minutes: 0 });

    const [tonttikaivuuMetri, setTonttikaivuuMetri] = useState(0);
    const [tonttikaivuuMetriAika, setTonttikaivuuMetriAika] = useState({ hours: 0, minutes: 0 });

    const [kaivinkonetyotunti, setKaivinkonetyotunti] = useState(0);
    const [kaivinkonetyotuntiAika, setKaivinkonetyotuntiAika] = useState({ hours: 0, minutes: 0 });

    const [aputyotunti, setAputyotunti] = useState(0);
    const [aputyotuntiAika, setAputyotuntiAika] = useState({ hours: 0, minutes: 0 });

    const [kuormaAutoTyotunti, setKuormaAutoTyotunti] = useState(0);
    const [kuormaAutoTyotuntiAika, setKuormaAutoTyotuntiAika] = useState({ hours: 0, minutes: 0 });

    const [taloyhtioMetriKaivuu, setTaloyhtioMetriKaivuu] = useState(0);
    const [taloyhtioMetriKaivuuAika, setTaloyhtioMetriKaivuuAika] = useState({ hours: 0, minutes: 0 });

    const [muut, setMuut] = useState('');
    const [muutTime, setMuutTime] = useState({ hours: 0, minutes: 0 });


    const [open, setOpen] = useState(false);

    const httpLink = createHttpLink({
        uri: 'https://kuoppapekka.fi/v1/graphql',
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem('accessToken');

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });


    const INSERT_CONSTRUCTION_DATA = gql`
  mutation InsertConstructionData(
    $tyomaanid: String,
    $tyomaanosoite: String,
    $tyo_alkoi: date,
    $tyo_loppui: date,
    $kaytetty_aika: String,
    $okt_tonttisuunnittelu: Int,
    $okt_tonttisuunnittelu_time: String,
    $okt_tonttikaivuu: Int,
    $okt_tonttikaivuu_time: String,
    $tonttikaivuu_metri: Int,
    $tonttikaivuu_metri_time: String,
    $kaivinkonetyotunti: Int,
    $kaivinkonetyotunti_time: String,
    $aputyotunti: Int,
    $aputyotunti_time: String,
    $kuorma_auto_tyotunti: Int,
    $kuorma_auto_tyotunti_time: String,
    $taloyhtio_metri_kaivuu: Int,
    $taloyhtio_metri_kaivuu_time: String,
    $tyon_nimi: String,
    $nimimerkki: String,
    $muu_tyo: String,
    $muu_tyo_time: String,
    $kuitupuhallus_metria: Int,
    $kuitupuhallus_metria_time: String,
    $tonttikaivuu_max30_metria: Int,
    $tonttikaivuu_max30_metria_time: String,
    $tonttikaivuu_yli30_metria: Int,
    $tonttikaivuu_yli30_metria_time: String,
    $katukivi_laatta_m2: Int,
    $katukivi_laatta_m2_time: String,
  ) {
    insert_construction_data(objects: {
      tyomaanid: $tyomaanid,
      tyomaanosoite: $tyomaanosoite,
      tyo_alkoi: $tyo_alkoi,
      tyo_loppui: $tyo_loppui,
      kaytetty_aika: $kaytetty_aika,
      okt_tonttisuunnittelu: $okt_tonttisuunnittelu,
      okt_tonttisuunnittelu_time: $okt_tonttisuunnittelu_time,
      okt_tonttikaivuu: $okt_tonttikaivuu,
      okt_tonttikaivuu_time: $okt_tonttikaivuu_time,
      tonttikaivuu_metri: $tonttikaivuu_metri,
      tonttikaivuu_metri_time: $tonttikaivuu_metri_time,
      kaivinkonetyotunti: $kaivinkonetyotunti,
      kaivinkonetyotunti_time: $kaivinkonetyotunti_time,
      aputyotunti: $aputyotunti,
      aputyotunti_time: $aputyotunti_time,
      kuorma_auto_tyotunti: $kuorma_auto_tyotunti,
      kuorma_auto_tyotunti_time: $kuorma_auto_tyotunti_time,
      taloyhtio_metri_kaivuu: $taloyhtio_metri_kaivuu,
      taloyhtio_metri_kaivuu_time: $taloyhtio_metri_kaivuu_time,
      tyon_nimi: $tyon_nimi,
      nimimerkki: $nimimerkki,
      muu_tyo: $muu_tyo,
      muu_tyo_time: $muu_tyo_time,
      kuitupuhallus_metria: $kuitupuhallus_metria,
      kuitupuhallus_metria_time: $kuitupuhallus_metria_time,
      tonttikaivuu_max30_metria: $tonttikaivuu_max30_metria,
      tonttikaivuu_max30_metria_time: $tonttikaivuu_max30_metria_time,
      tonttikaivuu_yli30_metria: $tonttikaivuu_yli30_metria,
      tonttikaivuu_yli30_metria_time: $tonttikaivuu_yli30_metria_time,
      katukivi_laatta_m2: $katukivi_laatta_m2,
      katukivi_laatta_m2_time: $katukivi_laatta_m2_time,
    }) {
      affected_rows
    }
  }
`;

    const handleCloseSend = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        window.location.reload();
    };


    const handleSubmit = () => {
        client.mutate({
            mutation: INSERT_CONSTRUCTION_DATA,
            variables: {
                tyomaanid: tyomaaId,
                tyomaanosoite: tyomaanosoite,
                tyo_alkoi: tyoAlkoi,
                tyo_loppui: tyoLoppui,
                kaytetty_aika: `${kaytettyAika.hours}:${kaytettyAika.minutes}`,
                okt_tonttisuunnittelu: oktTonttisuunnittelu,
                okt_tonttisuunnittelu_time: `${oktTonttisuunnitteluAika.hours}:${oktTonttisuunnitteluAika.minutes}`,
                okt_tonttikaivuu: oktTonttikaivuu,
                okt_tonttikaivuu_time: `${oktTonttikaivuuAika.hours}:${oktTonttikaivuuAika.minutes}`,
                tonttikaivuu_metri: tonttikaivuuMetri,
                tonttikaivuu_metri_time: `${tonttikaivuuMetriAika.hours}:${tonttikaivuuMetriAika.minutes}`,
                kaivinkonetyotunti: kaivinkonetyotunti,
                kaivinkonetyotunti_time: `${kaivinkonetyotuntiAika.hours}:${kaivinkonetyotuntiAika.minutes}`,
                aputyotunti: aputyotunti,
                aputyotunti_time: `${aputyotuntiAika.hours}:${aputyotuntiAika.minutes}`,
                kuorma_auto_tyotunti: kuormaAutoTyotunti,
                kuorma_auto_tyotunti_time: `${kuormaAutoTyotuntiAika.hours}:${kuormaAutoTyotuntiAika.minutes}`,
                taloyhtio_metri_kaivuu: taloyhtioMetriKaivuu,
                taloyhtio_metri_kaivuu_time: `${taloyhtioMetriKaivuuAika.hours}:${taloyhtioMetriKaivuuAika.minutes}`,
                tyon_nimi: tyonNimi,
                nimimerkki: nimiMerkki,
                muu_tyo: muut,
                muu_tyo_time: `${muutTime.hours}:${muutTime.minutes}`
            },
        }).then(data => {
                setOpen(true);
        } )
            .catch(error => console.error(error));
    };



    const formatDuration = (duration) => {
        return `${duration.hours} hours, ${duration.minutes} minutes`;
    };

    if (page === 0) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                        gap: '16px', // Space between the form elements
                        padding: '16px', // Add padding around the form
                    }}
                >
                    <Typography variant="body1">
                        Työmaa ID
                    </Typography>
                    <TextField
                        type="text"
                        value={tyomaaId}
                        onChange={(e) => setTyomaaId(e.target.value)}
                        required
                    />
                    <Typography variant="body1">
                        Työmaanosoite <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        type="text"
                        value={tyomaanosoite}
                        onChange={(e) => setTyomaanosoite(e.target.value)}
                        required
                    />
                    <Typography variant="body1">
                        Työ alkoi
                    </Typography>
                    <TextField
                        type="datetime-local"
                        value={tyoAlkoi}
                        onChange={(e) => setTyoalkoi(e.target.value)}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Typography variant="body1">
                        Työ loppui
                    </Typography>
                    <TextField
                        type="datetime-local"
                        value={tyoLoppui}
                        onChange={(e) => setTyoLoppui(e.target.value)}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Typography variant="body1">
                        Käyetty aika
                    </Typography>
                    <DurationPicker
                        value={kaytettyAika}
                        onChange={setKaytettyAika}
                        label="Lisää aika"
                    />
                    <p>{formatDuration(kaytettyAika)}</p>
                    <Button
                        variant="contained"
                        onClick={() => setPage(1)}
                        disabled={!tyomaanosoite || tyomaanosoite.trim() === ''}
                    >
                        Jatka eteenpäin
                    </Button>
                </Box>
            );
    }

    if (open === true) {
        return (
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSend}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSend} severity="success" sx={{ width: '100%' }}>
                    Lähetys onnistui!
                </Alert>
            </Snackbar>
        )
    }

    if (page === 1) {
    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => setPage(0)}>
                Palaa taaksepäin
            </Button>
            <div className={'div-page3'}>
                <p>OKT Tonttisuunnittelu (kpl)</p>
                <input type="number" value={oktTonttisuunnittelu} onChange={(e) => setOktTonttisuunnittelu(e.target.value)} />
                <DurationPicker value={oktTonttisuunnitteluAika} onChange={setOktTonttisuunnitteluAika} label="Lisää aika" />
                <p>{formatDuration(oktTonttisuunnitteluAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>OKT Tonttikaivuu (kpl)</p>
                <input type="number" value={oktTonttikaivuu} onChange={(e) => setOktTonttikaivuu(e.target.value)} />
                <DurationPicker value={oktTonttikaivuuAika} onChange={setOktTonttikaivuuAika} label="Lisää aika" />
                <p>{formatDuration(oktTonttikaivuuAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>Tonttikaivuu (Metriä)</p>
                <input type="number" value={tonttikaivuuMetri} onChange={(e) => setTonttikaivuuMetri(e.target.value)} />
                <DurationPicker value={tonttikaivuuMetriAika} onChange={setTonttikaivuuMetriAika} label="Lisää aika" />
                <p>{formatDuration(tonttikaivuuMetriAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>Taloyhtiö Metri Kaivuu (metriä)</p>
                <input type="number" value={taloyhtioMetriKaivuu} onChange={(e) => setTaloyhtioMetriKaivuu(e.target.value)} />
                <DurationPicker value={taloyhtioMetriKaivuuAika} onChange={setTaloyhtioMetriKaivuuAika} label="Lisää aika" />
                <p>{formatDuration(taloyhtioMetriKaivuuAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>Kaivinkonetyotunti (tuntia)</p>
                <DurationPicker value={kaivinkonetyotuntiAika} onChange={setKaivinkonetyotuntiAika} label="Lisää aika" />
                <p>{formatDuration(kaivinkonetyotuntiAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>Aputyotunti (tuntia)</p>
                <DurationPicker value={aputyotuntiAika} onChange={setAputyotuntiAika} label="Lisää aika" />
                <p>{formatDuration(aputyotuntiAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>Kuorma-auto Tyotunti (tuntia)</p>
                <DurationPicker value={kuormaAutoTyotuntiAika} onChange={setKuormaAutoTyotuntiAika} label="Lisää aika" />
                <p>{formatDuration(kuormaAutoTyotuntiAika)}</p>
            </div>
            <div className={'div-page3'}>
                <p>Muut työt (max 255 merkkiä)</p>
                <input type="text" value={muut} onChange={(e) => setMuut(e.target.value)} />
                <DurationPicker value={muutTime} onChange={setMuutTime} label="Lisää aika" />
                <p>{formatDuration(muutTime)}</p>
            </div>

            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </div>
    );
    }
}


export default EltelUudisrakentaminen;