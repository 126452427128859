import React from 'react';
import { Button } from "@mui/material";
import { useQuery, gql } from '@apollo/client';
import * as XLSX from 'xlsx';

const GET_KAIVUUTAVAT_DATA = gql`
  query MyQuery($tyomaa: String!) {
    construction_data(where: {tyomaanid: {_eq: $tyomaa}}) {
      kaivuu_tapa
      katukivi_laatta_m2
      tonttikaivuu_yli30_metria
      tyomaanosoite
      pistemainen_kaivuu_kpl
    }
    kaivuutapa {
      id
      kaivuu_tapa
    }
  }
`;

const LataaKaivuutavat = ({ tyomaa }) => {
    const { loading, error, data } = useQuery(GET_KAIVUUTAVAT_DATA, {
        variables: { tyomaa }
    });

    const handleExport = () => {
        if (data) {
            // Prepare the kaivuutapa map
            const kaivuutapaMap = new Map();
            data.kaivuutapa.forEach(item => {
                kaivuutapaMap.set(item.id, item.kaivuu_tapa);
            });

            // Prepare the data
            const rows = [
                [`Tyomaa: ${tyomaa}`],
                [],
                ["Tyomaan Osoite", "Kaivuu Tapa", "Katukivi / Laatta m2", "Tonttikaivuu m", "Pistemäinen kaivuu"],
                ...data.construction_data.map(item => [
                    item.tyomaanosoite,
                    kaivuutapaMap.get(item.kaivuu_tapa) || '', // Display the kaivuu_tapa from kaivuutapa map or an empty string if not available
                    item.katukivi_laatta_m2,
                    item.tonttikaivuu_yli30_metria,
                    item.pistemainen_kaivuu_kpl
                ])
            ];

            // Create a worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Kaivuutavat");

            // Write to file
            XLSX.writeFile(workbook, "Kaivuutavat.xlsx");
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Button onClick={handleExport}>Lataa kaivuutavat</Button>
    );
}

export default LataaKaivuutavat;
