import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import UnAuthorized from './UnAuthorized';

const LoginButton = (setPage) => {
    const { user, isAuthenticated, getIdTokenClaims, loginWithRedirect, isLoading, error } = useAuth0();

    // State to track if an error has occurred
    const [hasErrorOccurred, setErrorOccurred] = useState(false);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (error) {
            setErrorOccurred(true); // set the error state to true if an error occurs
            return; // Exit the effect to avoid any further logic if an error is detected
        }

        if (isAuthenticated) {
            getIdTokenClaims().then((claims) => {
                localStorage.setItem('accessToken', claims.__raw)
            });
        } else if (!hasErrorOccurred) {
            // Only redirect if an error hasn't previously occurred
            loginWithRedirect();
        }
    }, [getIdTokenClaims, isAuthenticated, isLoading, loginWithRedirect, user, error, hasErrorOccurred]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error || hasErrorOccurred) {
        return <UnAuthorized />;
    }

    return (
        <div>
            <Button
                variant="contained"
                size="large"
                sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    padding: { xs: "12px 24px", sm: "16px 32px" },
                    backgroundColor: "primary.main",
                    ":hover": {
                        backgroundColor: "primary.dark",
                    },
                }}
                onClick={loginWithRedirect}
            >
                {isAuthenticated ? "Log out" : "Log in"}
            </Button>
        </div>
    );
};

export default LoginButton;
