import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ConstructionStatus = ({ constructionData, projects }) => {
    const [selectedProjectName, setSelectedProjectName] = useState(null);

    console.log(constructionData);
    const getJobDetails = (address) => {
        const jobsForAddress = constructionData.filter(job => job.tyomaanosoite === address);

        let compiledMuuTyo = [];
        jobsForAddress.forEach(job => {
            if (job.muu_tyo !== null && job.muu_tyo !== undefined) {
                compiledMuuTyo.push(job.muu_tyo);
            }
        });
        let muuTyoString = compiledMuuTyo.length > 0 ? compiledMuuTyo.join(", ") : "";

        const job = jobsForAddress[0] || {};
        const asennusStatus = job.sisa_asennus === 1 || job.sisa_asennus_laitteen_kanssa === 1 ? 'Valmis' : 'Tekemätön';

        return {
            Osoite: address,
            Suunnittelu: job.okt_tonttisuunnittelu === 1 ? 'Valmis' : 'Tekemätön',
            Kaivuu: job.tonttikaivuu_max30_metria >= 1 ? 'Valmis' : 'Tekemätön',
            Asennus: asennusStatus,
            lisatietoja: "" + muuTyoString
        };
    };

    const handleProjectSelection = (projectName) => {
        setSelectedProjectName(projectName === selectedProjectName ? null : projectName);
    };

    const downloadProjectData = (project) => {
        const wsData = project.addresses.map(addressObj => getJobDetails(addressObj.address));
        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Project Data");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${project.project_name}.xlsx`);
    };

    return (
        <div>
            {projects.map(project => {
                const addresses = project.addresses.map(addressObj => addressObj.address);
                const completedCounts = addresses.reduce((acc, address) => {
                    const jobDetails = getJobDetails(address);
                    acc.suunnitteluValmis += jobDetails.Suunnittelu === 'Valmis' ? 1 : 0;
                    acc.kaivuuValmis += jobDetails.Kaivuu === 'Valmis' ? 1 : 0;
                    acc.asennusValmis += jobDetails.Asennus === 'Valmis' ? 1 : 0;
                    acc.valmiitaOsoitteita += (jobDetails.Suunnittelu === 'Valmis' && jobDetails.Kaivuu === 'Valmis' && jobDetails.Asennus === 'Valmis') ? 1 : 0;
                    return acc;
                }, { suunnitteluValmis: 0, kaivuuValmis: 0, asennusValmis: 0, valmiitaOsoitteita: 0 });

                return (
                    <div key={project.project_name}>
                        <h3 onClick={() => handleProjectSelection(project.project_name)} style={{ cursor: 'pointer' }}>
                            {project.project_name} - Valmiita osoitteita: {completedCounts.valmiitaOsoitteita}/{addresses.length}<br />
                            Valmiita suunnitteluja: {completedCounts.suunnitteluValmis}/{addresses.length}<br />
                            Valmiita kaivuita: {completedCounts.kaivuuValmis}/{addresses.length}<br />
                            Valmiita asennuksia: {completedCounts.asennusValmis}/{addresses.length}
                        </h3>
                        {selectedProjectName === project.project_name && (
                            <>
                                <button onClick={() => downloadProjectData(project)}>Lataa Projekti Data</button>
                                <ul>
                                    {addresses.map(address => {
                                        const jobDetails = getJobDetails(address);
                                        return (
                                            <li key={address}>
                                                <strong>{jobDetails.Osoite}</strong><br />
                                                <span style={{ color: jobDetails.Suunnittelu === 'Valmis' ? 'green' : 'red' }}>Suunnittelu: {jobDetails.Suunnittelu}</span><br />
                                                <span style={{ color: jobDetails.Kaivuu === 'Valmis' ? 'green' : 'red' }}>Kaivuu: {jobDetails.Kaivuu}</span><br />
                                                <span style={{ color: jobDetails.Asennus === 'Valmis' ? 'green' : 'red' }}>Asennus: {jobDetails.Asennus}</span>
                                                <h1>Lisätietoja: {jobDetails.lisatietoja}</h1>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ConstructionStatus;
