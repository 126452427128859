import React, {useEffect, useState} from "react";
import {ApolloClient, createHttpLink, gql, InMemoryCache, useApolloClient, useQuery} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import Download from "./Download";
import {Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography} from "@mui/material";
import PasinDownload from "./PasinDownload";
import {useAuth0} from "@auth0/auth0-react";
import LataaAsfalttiosoitteet from "./LataaAsfalttiosoitteet";
import LataaKaivuutavat from "./LataaKaivuutavat";

const KaikkiKohteet = (customeri) => {

    const [selectedUser, setSelectedUser] = useState('');
    const { user, isAuthenticated, loginWithPopup, getAccessTokenWithPopup } = useAuth0();
    const [customer, setCustomer] = useState(customeri)


    useEffect(() => {
        setCustomer(customeri);
    }, [customeri]); // Dependency array includes 'customeri' to watch for changes



    // Extract the roles from the user object
    const hasuraClaims = user?.['https://hasura.io/jwt/claims'] || {};
    const allowedRoles = hasuraClaims['x-hasura-allowed-roles'] || [];

    // Check for 'admin' role
    const isAdmin = allowedRoles.includes('superadmin');

    const FETCH_ALL_DATA = gql`
  query FetchAllData($customerNames: [String!]!) {
    construction_data(
      order_by: { created_date: desc }, 
      where: { tyomaanidToProjectname1: { projects_customer: { customer_name: { _in: $customerNames } } } }, 
      limit: 100
    ) {
      tyomaanosoite
      id
    }
    fetchTyomaa: construction_data(
      distinct_on: tyomaanid, 
      where: { tyomaanid: { _neq: "", _is_null: false }, tyomaanidToProjectname1: { projects_customer: { customer_name: { _in: $customerNames } } } }, 
      limit: 100
    ) {
      tyomaanid
    }
  }
`;



    const FETCH_SPECIFIC_DATA = gql`
query FetchSpecificData($id: Int!) {
  construction_data(where: {id: {_eq: $id}}) {
      aputyotunti_time, jatkamisen_valmistelu_time, kaivinkonetyotunti_time, katukivi_laatta_m2_time, kaytetty_aika, kuitujen_jatkaminen_time, kuitupuhallus_ftth_time, kuitupuhallus_metria_time, kuorma_auto_tyotunti_time, muu_tyo, muu_tyo_time, nimimerkki, okt_tonttikaivuu_time, okt_tonttisuunnittelu_time, pistemainen_kaivuu_kpl_time, runkokaapelin_kasittely_time, sisa_asennus_time, taloyhtio_metri_kaivuu_time, tilaajakaapelin_kasittely_time, tontin_sisainen_kaivuu_paallystamaton_time, tontin_sisainen_kaivuu_paallystetty_time, tontinulkoinen_kaivuu_paallystamaton_metria_time, tontinulkoinen_kaivuu_paallystetty_metria_time, tontinulkopuolinen_nurmikko_m2_time, tonttikaivuu_max30_metria_time, tonttikaivuu_metri_time, tonttikaivuu_yli30_metria_time, tyomaanid, tyomaanosoite, tyon_nimi, valokuitujen_kasittely_time, tyo_alkoi, tyo_loppui, aputyotunti, id, jatkamisen_valmistelu, kaivinkonetyotunti, katukivi_laatta_m2, kuitujen_jatkaminen, kuitupuhallus_ftth, kuitupuhallus_metria, kuorma_auto_tyotunti, okt_tonttikaivuu, okt_tonttisuunnittelu, pistemainen_kaivuu_kpl, runkokaapelin_kasittely, sisa_asennus, taloyhtio_metri_kaivuu, tilaajakaapelin_kasittely, tontin_sisainen_kaivuu_paallystamaton, tontin_sisainen_kaivuu_paallystetty, tontinulkoinen_kaivuu_paallystamaton_metria, tontinulkoinen_kaivuu_paallystetty_metria, tontinulkopuolinen_nurmikko_m2, tonttikaivuu_max30_metria, tonttikaivuu_metri, tonttikaivuu_yli30_metria, valokuitujen_kasittely, created_date, liittyman_kayttoonotto,
liittyman_kayttoonotto_time,
  }
}`;


        const client = useApolloClient(); // added this line
        const [selectedData, setSelectedData] = useState(null);
    const [activeButton, setActiveButton] = useState(null);

    const customerNames = customer.customeri === "Jeho" ? ["EnersenseUudisrakentaminen", "CableCrew"] : [customer.customeri];

    const { loading, error, data } = useQuery(FETCH_ALL_DATA, {
        variables: { customerNames }
    });

        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;

    const handleButtonClick = async (id, index) => {
        if (index === activeButton) {
            setSelectedData(null);
            setActiveButton(null);
        } else {
            const { data } = await client.query({
                query: FETCH_SPECIFIC_DATA,
                variables: { id }
            });
            setSelectedData(data.construction_data[0]);
            setActiveButton(index);
        }
    };

    const fieldMap = {
        "tyomaanid": "Työmaan ID",
        "tyomaanosoite": "Työmaan osoite",
        "tyo_alkoi": "Työ alkoi",
        "tyo_loppui": "Työ loppui",
        "kaytetty_aika": "Käytetty aika",
        "nimimerkki": "Nimimerkki",
        "tyon_nimi": "Työn nimi",
        "okt_tonttisuunnittelu": "OKT Tonttisuunnittelu",
        "okt_tonttisuunnittelu_time": "OKT Tonttisuunnittelu aika",
        "muu_tyo": "Muu työ",
        "muu_tyo_time": "Muu työ aika",
        "okt_tonttikaivuu": "OKT Tonttikaivuu",
        "okt_tonttikaivuu_time": "OKT Tonttikaivuu aika",
        "tonttikaivuu_metri": "Tonttikaivuu metriä",
        "tonttikaivuu_metri_time": "Tonttikaivuu metriä aika",
        "kaivinkonetyotunti_time": "Kaivinkonetyötunti aika",
        "aputyotunti_time": "Aputyötunti aika",
        "kuorma_auto_tyotunti_time": "Kuorma-auto työtunti aika",
        "taloyhtio_metri_kaivuu": "Taloyhtiö metriä kaivuu",
        "taloyhtio_metri_kaivuu_time": "Taloyhtiö metriä kaivuu aika",
        "sisa_asennus": "Sisäasennus",
        "sisa_asennus_time": "Sisäasennus aika",
        "tonttikaivuu_max30_metria": "Tonttikaivuu max 30 metriä",
        "tonttikaivuu_max30_metria_time": "Tonttikaivuu max 30 metriä aika",
        "tonttikaivuu_yli30_metria": "Tonttikaivuu yli 30 metriä",
        "tonttikaivuu_yli30_metria_time": "Tonttikaivuu yli 30 metriä aika",
        "katukivi_laatta_m2": "Katukivi/laatta m²",
        "katukivi_laatta_m2_time": "Katukivi/laatta m² aika",
        "kuitupuhallus_metria": "Kuitupuhallus metriä",
        "kuitupuhallus_metria_time": "Kuitupuhallus metriä aika",
        "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
        "kuitupuhallus_ftth_time": "Kuitupuhallus FTTH aika",
        "pistemainen_kaivuu_kpl": "Pistemainen kaivuu kpl",
        "pistemainen_kaivuu_kpl_time": "Pistemainen kaivuu kpl aika",
        "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoinen kaivuu päällystetty metriä",
        "tontinulkoinen_kaivuu_paallystetty_metria_time": "Tontinulkoinen kaivuu päällystetty metriä aika",
        "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoinen kaivuu päällystämätön metriä",
        "tontinulkoinen_kaivuu_paallystamaton_metria_time": "Tontinulkoinen kaivuu päällystämätön metriä aika",
        "tontinulkopuolinen_nurmikko_m2": "Tontin ulkopuolinen nurmikko m²",
        "tontinulkopuolinen_nurmikko_m2_time": "Tontin ulkopuolinen nurmikko m² aika",
        "tontin_sisainen_kaivuu_paallystetty": "Tontin sisäinen kaivuu päällystetty",
        "tontin_sisainen_kaivuu_paallystetty_time": "Tontin sisäinen kaivuu päällystetty aika",
        "tontin_sisainen_kaivuu_paallystamaton": "Tontin sisäinen kaivuu päällystämätön",
        "tontin_sisainen_kaivuu_paallystamaton_time": "Tontin sisäinen kaivuu päällystämätön aika",
        "jatkamisen_valmistelu": "Jatkamisen valmistelu",
        "jatkamisen_valmistelu_time": "Jatkamisen valmistelu aika",
        "runkokaapelin_kasittely": "Runkokaapelin käsittely",
        "runkokaapelin_kasittely_time": "Runkokaapelin käsittely aika",
        "tilaajakaapelin_kasittely": "Tilaajakaapelin käsittely",
        "tilaajakaapelin_kasittely_time": "Tilaajakaapelin käsittely aika",
        "valokuitujen_kasittely": "Valokuitujen käsittely",
        "valokuitujen_kasittely_time": "Valokuitujen käsittely aika",
        "kuitujen_jatkaminen": "Kuitujen jatkaminen",
        "kuitujen_jatkaminen_time": "Kuitujen jatkaminen aika",
        "liittyman_kayttoonotto": "Liittyman käyttöönotto",
        "liittyman_kayttoonotto_time": "Liittyman käyttöönotto aika",
    };

    const tyomaa = data.fetchTyomaa;


    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" component="div" gutterBottom>
                Kaikki kohteet
            </Typography>

            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel id="user-select-label">Valitse projekti</InputLabel>
                <Select
                    labelId="project-select-label"
                    value={selectedUser}
                    onChange={(event) => setSelectedUser(event.target.value)}
                    label="Select Project"
                >
                    {tyomaa.map((tyomaa, index) => (
                        <MenuItem key={index} value={tyomaa.tyomaanid}>
                            {tyomaa.tyomaanid}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {isAdmin ? (
                <div>
                <PasinDownload tyomaa={selectedUser} customeri={customer} />
                <LataaAsfalttiosoitteet tyomaa={selectedUser}></LataaAsfalttiosoitteet>
                    <LataaKaivuutavat tyomaa={selectedUser}></LataaKaivuutavat>
                </div>
            ) : (
                <Download tyomaa={selectedUser} />
            )}
            <Typography variant="h6" component="div" gutterBottom>
                Lataa kaikki
            </Typography>
            {data.construction_data.map((item, index) => (
                <Paper key={index} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="body1" gutterBottom>
                        {item.tyomaanosoite}
                    </Typography>
                    <Stack spacing={1} direction="row">
                        <Button variant="contained" color="primary" fullWidth onClick={() => handleButtonClick(item.id, index)}>
                            {index === activeButton ? 'Piilota' : 'Näytä lisää'}
                        </Button>
                    </Stack>
                    {index === activeButton && selectedData && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6" component="div" gutterBottom>
                                Selected Construction Data
                            </Typography>
                            {Object.entries(selectedData).map(([key, value]) => {
                                if (value === null || value === "0:0" || value === 0) {
                                    return null;
                                }
                                const mappedKey = fieldMap[key] || key;
                                return <Typography key={key} variant="body2">{mappedKey}: {value}</Typography>;
                            })}
                        </Box>
                    )}
                </Paper>
            ))}
        </Box>
    );


}

export default KaikkiKohteet;
