import React, { useState } from 'react';
import { Autocomplete, TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import { useMutation, gql } from "@apollo/client";

// Define the GraphQL mutation
const ADD_ADDRESS = gql`
  mutation AddAddress($project_id: Int!, $address: String!) {
    insert_addresses(objects: {project_id: $project_id, address: $address}) {
      affected_rows
    }
  }
`;

const AddressComponent = ({
                              setTyomaanosoite,
                              tyomaanosoite,
                              projectName,
                              relevantAddresses,
                              projectsWithAddresses
                          }) => {
    const [inputValue, setInputValue] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [buttonClicked, setButtonClicked] = useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Use the useMutation hook
    const [addAddress] = useMutation(ADD_ADDRESS);

    const handleAddAddress = () => {
        setButtonClicked(true);  // Hide the button

        const matchingProject = projectsWithAddresses.find(project => {
            console.log(`Comparing: "${project.name}" with "${projectName}"`);
            return project.name.toLowerCase().trim() === projectName.toLowerCase().trim();
        });
        console.log("Matching project object:", matchingProject);
        const projectId = matchingProject?.project_id;

        if (projectId) {
            addAddress({
                variables: {
                    project_id: projectId,
                    address: inputValue
                }
            }).then(response => {
                console.log("Address added:", response);
                setSnackbarMessage('Osoite lisätty tietokantaan. Projektille ' + projectName);
                setSeverity('success');
                setTyomaanosoite(inputValue);
                setSnackbarOpen(true);
            }).catch(error => {
                console.error("Error adding address:", error);
                setSnackbarMessage('Error adding address.');
                setSeverity('error');
                setSnackbarOpen(true);
            });
        } else {
            console.error("Project ID not found for project name:", projectName);
            setSnackbarMessage('Project ID not found.');
            setSeverity('error');
            setSnackbarOpen(true);
        }
    };

    return (
        <div>
            <Typography>
                Työmaanosoite <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Autocomplete
                style={{ width: '60vw' }}
                freeSolo
                value={tyomaanosoite}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    setTyomaanosoite(newValue);
                }}
                options={relevantAddresses}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        type="text"
                        required
                        style={{ width: '100%' }}
                    />
                )}
            />
            {inputValue && projectName && !relevantAddresses.includes(inputValue) && !buttonClicked && (
                <Button onClick={handleAddAddress} style={{ marginTop: '10px' }}>
                    Lisää uusi osoite
                </Button>
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AddressComponent;
