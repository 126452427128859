import React, {useState} from "react";
import {ApolloClient, createHttpLink, gql, InMemoryCache, useApolloClient, useQuery} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import Download from "./Download";
import {useAuth0} from "@auth0/auth0-react";
import EltelJalkiliittymaUpdate from "./EltelJalkiliittymaUpdate";
import EltelUudisrakentaminenUpdate from "./EltelUudisrakentaminenUpdate";
import EnersenseJalkiliittyminenUpdate from "./EnersenseJalkiliittyminenUpdate";
import EnersenseUudisrakentaminen from "./EnersenseUudisrakentaminen";
import EnersenseUudisrakentaminenUpdate from "./EnersenseUudisrakentaminenUpdate";
import {Box, Button, Dialog, DialogActions, DialogTitle, Paper, Stack, Typography} from "@mui/material";
import UpdateConstructionData from "./UpdateConstructionData";
import { format } from 'date-fns';


const KaikkiKohteet = () => {
    const {user, isAuthenticated, isLoading} = useAuth0();
    const today = format(new Date(), 'yyyy-MM-dd');

    // State for controlling whether the dialog is open
    const [open, setOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);

// Open the dialog
    const openDialog = (id) => {
        setIdToDelete(id);
        setOpen(true);
    };

// Close the dialog
    const closeDialog = () => {
        setIdToDelete(null);
        setOpen(false);
    };

// Perform the deletion and close the dialog
    const deleteItem = async () => {
        await client.mutate({
            mutation: DELETE_CONSTRUCTION_DATA,
            variables: { id: idToDelete },
        });
        refetch();
        closeDialog();
    };



    const FETCH_ALL_DATA = gql`
  query FetchAllData($userName: String!) {
    construction_data(
      where: {nimimerkki: {_eq: $userName}}, 
      order_by: {created_date: asc}
    ) {
      tyomaanosoite
      id
      created_date
    }
  }
`;



    const FETCH_SPECIFIC_DATA = gql`
query FetchSpecificData($id: Int!) {
  construction_data(where: {id: {_eq: $id}}) {
    tyomaanid
    tyomaanosoite
    tyo_alkoi
    tyo_loppui
    kaytetty_aika
    nimimerkki
    tyon_nimi
    okt_tonttisuunnittelu
    okt_tonttisuunnittelu_time
    muu_tyo
    muu_tyo_time
    okt_tonttikaivuu
    okt_tonttikaivuu_time
    tonttikaivuu_metri
    tonttikaivuu_metri_time
    kaivinkonetyotunti
    kaivinkonetyotunti_time
    aputyotunti
    aputyotunti_time
    kuorma_auto_tyotunti
    kuorma_auto_tyotunti_time
    taloyhtio_metri_kaivuu
    taloyhtio_metri_kaivuu_time
    sisa_asennus
    sisa_asennus_time
    sisa_asennus_laitteen_kanssa
    sisa_asennus_laitteen_kanssa_time
    tonttikaivuu_max30_metria
    tonttikaivuu_max30_metria_time
    tonttikaivuu_yli30_metria
    tonttikaivuu_yli30_metria_time
    katukivi_laatta_m2
    katukivi_laatta_m2_time
    kuitupuhallus_metria
    kuitupuhallus_metria_time
    kuitupuhallus_ftth
    kuitupuhallus_ftth_time
    pistemainen_kaivuu_kpl
    pistemainen_kaivuu_kpl_time
    tontinulkoinen_kaivuu_paallystetty_metria
    tontinulkoinen_kaivuu_paallystetty_metria_time
    tontinulkoinen_kaivuu_paallystamaton_metria
    tontinulkoinen_kaivuu_paallystamaton_metria_time
    tontinulkopuolinen_nurmikko_m2
    tontinulkopuolinen_nurmikko_m2_time
    tontin_sisainen_kaivuu_paallystetty
    tontin_sisainen_kaivuu_paallystetty_time
    tontin_sisainen_kaivuu_paallystamaton
    tontin_sisainen_kaivuu_paallystamaton_time
    id
    jatkamisen_valmistelu
    jatkamisen_valmistelu_time
    runkokaapelin_kasittely
    runkokaapelin_kasittely_time
    tilaajakaapelin_kasittely
    tilaajakaapelin_kasittely_time
    valokuitujen_kasittely
    valokuitujen_kasittely_time
    kuitujen_jatkaminen
    kuitujen_jatkaminen_time
    liittyman_kayttoonotto
    liittyman_kayttoonotto_time
    created_date
    varauksen_liittaminen_runkoon
    tyon_alatyyli
    teletyo
    suunnittelu_lisatietoja
sisa_asennus_lisatietoja
kaivuu_tuntityo_selite
hitaus_tuntityo_selite
puhallus_lisatietoja
kayttoonotto_lisatietoja
is_asfalttia
suunnittelu_tuntia
sisa_asennus_tuntia
kayttoon_otto_tuntia
puhallus_tuntia
kaivuu_tapa
etakayttoonotto
  }
  kaivuutapa {
    id
    kaivuu_tapa
  }
}`;



    const client = useApolloClient(); // added this line
        const [selectedData, setSelectedData] = useState(null);
    const [forUpdateData, setForUpdateData] = useState(null);

    const [activeButton, setActiveButton] = useState(null);
    const [currentPage, setCurrentPage] = useState('default');

    const { loading, error, data, refetch } = useQuery(FETCH_ALL_DATA, {
        variables: { userName: user.email },
    });


        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;

    const handleButtonClick = async (id, index) => {
        if (index === activeButton) {
            setSelectedData(null);
            setActiveButton(null);
        } else {
            const { data } = await client.query({
                query: FETCH_SPECIFIC_DATA,
                variables: { id }
            });
            setSelectedData(data.construction_data[0]);
            setActiveButton(index);

        }
    };

    const DELETE_CONSTRUCTION_DATA = gql`
mutation DeleteConstructionData($id: Int!) {
  delete_construction_data_by_pk(id: $id) {
    id
  }
}`;


    const handleButtonClickDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            await client.mutate({
                mutation: DELETE_CONSTRUCTION_DATA,
                variables: { id },
            });
            // Fetch data again
            refetch();
        }
    };


    const fieldMap = {
        "tyomaanid": "Projekti",
        "tyomaanosoite": "Työmaan osoite",
        "tyo_alkoi": "Työ alkoi",
        "tyo_loppui": "Työ loppui",
        "kaytetty_aika": "Käytetty aika",
        "nimimerkki": "Nimimerkki",
        "tyon_nimi": "Työn nimi",
        "okt_tonttisuunnittelu": "OKT Tonttisuunnittelu",
        "okt_tonttisuunnittelu_time": "OKT Tonttisuunnittelu aika",
        "muu_tyo": "Muu työ",
        "muu_tyo_time": "Muu työ aika",
        "okt_tonttikaivuu": "OKT Tonttikaivuu",
        "okt_tonttikaivuu_time": "OKT Tonttikaivuu aika",
        "tonttikaivuu_metri": "Tonttikaivuu metriä",
        "tonttikaivuu_metri_time": "Tonttikaivuu metriä aika",
        "kaivinkonetyotunti_time": "Kaivinkonetyötunti aika",
        "aputyotunti_time": "Aputyötunti aika",
        "kuorma_auto_tyotunti_time": "Kuorma-auto työtunti aika",
        "taloyhtio_metri_kaivuu": "Taloyhtiö metriä kaivuu",
        "taloyhtio_metri_kaivuu_time": "Taloyhtiö metriä kaivuu aika",
        "sisa_asennus": "Sisäasennus",
        "sisa_asennus_time": "Sisäasennus aika",
        "tonttikaivuu_max30_metria": "Tonttikaivuu max 30 metriä",
        "tonttikaivuu_max30_metria_time": "Tonttikaivuu max 30 metriä aika",
        "tonttikaivuu_yli30_metria": "Tonttikaivuu yli 30 metriä",
        "tonttikaivuu_yli30_metria_time": "Tonttikaivuu yli 30 metriä aika",
        "katukivi_laatta_m2": "Katukivi/laatta m²",
        "katukivi_laatta_m2_time": "Katukivi/laatta m² aika",
        "kuitupuhallus_metria": "Kuitupuhallus metriä",
        "kuitupuhallus_metria_time": "Kuitupuhallus metriä aika",
        "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
        "kuitupuhallus_ftth_time": "Kuitupuhallus FTTH aika",
        "pistemainen_kaivuu_kpl": "Pistemainen kaivuu kpl",
        "pistemainen_kaivuu_kpl_time": "Pistemainen kaivuu kpl aika",
        "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoinen kaivuu päällystetty metriä",
        "tontinulkoinen_kaivuu_paallystetty_metria_time": "Tontinulkoinen kaivuu päällystetty metriä aika",
        "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoinen kaivuu päällystämätön metriä",
        "tontinulkoinen_kaivuu_paallystamaton_metria_time": "Tontinulkoinen kaivuu päällystämätön metriä aika",
        "tontinulkopuolinen_nurmikko_m2": "Tontin ulkopuolinen nurmikko m²",
        "tontinulkopuolinen_nurmikko_m2_time": "Tontin ulkopuolinen nurmikko m² aika",
        "tontin_sisainen_kaivuu_paallystetty": "Tontin sisäinen kaivuu päällystetty",
        "tontin_sisainen_kaivuu_paallystetty_time": "Tontin sisäinen kaivuu päällystetty aika",
        "tontin_sisainen_kaivuu_paallystamaton": "Tontin sisäinen kaivuu päällystämätön",
        "tontin_sisainen_kaivuu_paallystamaton_time": "Tontin sisäinen kaivuu päällystämätön aika",
        "id": "ID"
    };

    const handleButtonClickEditpage = async (id, index) => {

            const { data } = await client.query({
                query: FETCH_SPECIFIC_DATA,
                variables: { id }
            });

        setForUpdateData({
            constructionData: data.construction_data[0],
            kaivuutapa: data.kaivuutapa
        });

        setCurrentPage("paivitys");

    }





    if (currentPage === 'default') {

        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" component="div" gutterBottom>
                    Tehdyt työkohteet
                </Typography>
                {data.construction_data.map((item, index) => {
                    const isCreatedToday = format(new Date(item.created_date), 'yyyy-MM-dd') === today;

                    return (
                        <Paper key={index} sx={{ p: 2, mb: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                {item.tyomaanosoite}
                            </Typography>
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleButtonClick(item.id, index)}
                                >
                                    {index === activeButton ? 'Piilota' : 'Näytä lisää'}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={isCreatedToday ? () => handleButtonClickEditpage(item.id, index) : () => alert('Ota yhteyttä Pasiin, jos koitat muokata tai poistaa jo tehtyä kohdetta!')}
                                    disabled={!isCreatedToday}
                                >
                                    Muokkaa
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={isCreatedToday ? () => openDialog(item.id) : () => alert('Ota yhteyttä Pasiin, jos koitat muokata tai poistaa jo tehtyä kohdetta!')}
                                    disabled={!isCreatedToday}
                                >
                                    Poista
                                </Button>
                            </Stack>
                            {index === activeButton && selectedData && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Selected Construction Data
                                    </Typography>
                                    {Object.entries(selectedData).map(([key, value]) => {
                                        if (value === null || value === "0:0" || value === 0) {
                                            return null;
                                        }
                                        const mappedKey = fieldMap[key] || key;
                                        return <Typography key={key} variant="body2">{mappedKey}: {value}</Typography>;
                                    })}
                                </Box>
                            )}
                        </Paper>
                    );
                })}
                <Dialog
                    open={open}
                    onClose={closeDialog}
                >
                    <DialogTitle>
                        Are you sure you want to delete this item?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button onClick={deleteItem}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );

    }

    if (currentPage === 'Eltel jalkiliittyma') {
        return (
            <div>
                <EltelJalkiliittymaUpdate forUpdateData={forUpdateData} />
            </div>
        )
    }

    if (currentPage === 'Eltel uudisrakentaminen') {
        return (
            <div>
                <EltelUudisrakentaminenUpdate forUpdateData={forUpdateData} />
            </div>
        )
    }

    if (currentPage === 'EnersenseJalkiliittyminen') {
        return (
            <div>
                <EnersenseJalkiliittyminenUpdate forUpdateData={forUpdateData} />
            </div>
        )
    }

    if (currentPage === 'EnersenseUudisrakentaminen') {
        return (
            <div>
                <EnersenseUudisrakentaminenUpdate forUpdateData={forUpdateData} />
            </div>
        )
    }

    if (currentPage === 'paivitys') {
        console.log("paivitys")
        return (
            <div>
                <UpdateConstructionData forUpdateData={forUpdateData} />
            </div>
        )
    }





}

export default KaikkiKohteet;
