import React from 'react';
import { Button } from "@mui/material";
import { useQuery, gql } from '@apollo/client';
import * as XLSX from 'xlsx';

const GET_CONSTRUCTION_DATA = gql`
  query MyQuery($tyomaa: String!) {
    construction_data(where: {is_asfalttia: {_eq: 1}, _and: {tyomaanid: {_eq: $tyomaa}}}) {
      tyomaanosoite
      is_asfalttia
    }
  }
`;

const LataaAsfalttiosoitteet = ({ tyomaa }) => {
    const { loading, error, data } = useQuery(GET_CONSTRUCTION_DATA, {
        variables: { tyomaa }
    });

    const handleExport = () => {
        if (data) {
            // Prepare the data
            const rows = [
                [`Tyomaa: ${tyomaa}`],
                [],
                ...data.construction_data.map(item => [item.tyomaanosoite])
            ];

            // Create a worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "AsfalttiOsoitteet");

            // Write to file
            XLSX.writeFile(workbook, "AsfalttiOsoitteet.xlsx");
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Button onClick={handleExport}>Lataa asfaltti osoitteet</Button>
    );
}

export default LataaAsfalttiosoitteet;
