import React, {useState} from 'react';
import {useQuery, gql, useLazyQuery} from '@apollo/client';
import * as XLSX from 'xlsx';
import {daDK} from "@mui/material/locale";


//Tonin työmaat on kova koodattu tähän laiskuuttani. Jos pitää joskus muuttaa niin tietokantaan uusi taulu jossa on admin tunnari jonka alla on alihankkijat.
const FETCH_ALL_DATA = gql`
  query FetchAllData(
    $startDate: timestamp!,
    $endDate: timestamp!,
    $tyomaaId: String
  ) {
    construction_data(
      order_by: [
        {tyomaanosoite: asc} 
      ],
      where: {
        created_date: {_gte: $startDate, _lte: $endDate},
        tyomaanid: { _eq: $tyomaaId },
        nimimerkki: { _in: ["ramonrakennuspalvelu@gmail.com", "evormula@gmail.com", "simo.rossilahti@gmail.com"]}
      }
    ) {
      aputyotunti_time, 
      jatkamisen_valmistelu_time, 
      kaivinkonetyotunti_time, 
      katukivi_laatta_m2_time, 
      kaytetty_aika, 
      kuitujen_jatkaminen_time, 
      kuitupuhallus_ftth_time, 
      kuitupuhallus_metria_time, 
      kuorma_auto_tyotunti_time, 
      muu_tyo, 
      muu_tyo_time, 
      nimimerkki, 
      okt_tonttikaivuu_time, 
      okt_tonttisuunnittelu_time, 
      pistemainen_kaivuu_kpl_time, 
      runkokaapelin_kasittely_time, 
      sisa_asennus_time, 
      taloyhtio_metri_kaivuu_time, 
      tilaajakaapelin_kasittely_time, 
      tontin_sisainen_kaivuu_paallystamaton_time, 
      tontin_sisainen_kaivuu_paallystetty_time, 
      tontinulkoinen_kaivuu_paallystamaton_metria_time, 
      tontinulkoinen_kaivuu_paallystetty_metria_time, 
      tontinulkopuolinen_nurmikko_m2_time, 
      tonttikaivuu_max30_metria_time, 
      tonttikaivuu_metri_time, 
      tonttikaivuu_yli30_metria_time, 
      tyomaanid, 
      tyomaanosoite, 
      tyon_nimi, 
      valokuitujen_kasittely_time, 
      tyo_alkoi, 
      tyo_loppui, 
      aputyotunti, 
      id, 
      jatkamisen_valmistelu, 
      kaivinkonetyotunti, 
      katukivi_laatta_m2, 
      kuitujen_jatkaminen, 
      kuitupuhallus_ftth, 
      kuitupuhallus_metria, 
      kuorma_auto_tyotunti, 
      okt_tonttikaivuu, 
      okt_tonttisuunnittelu, 
      pistemainen_kaivuu_kpl, 
      runkokaapelin_kasittely, 
      sisa_asennus, 
      sisa_asennus_laitteen_kanssa, 
      taloyhtio_metri_kaivuu, 
      tilaajakaapelin_kasittely, 
      tontin_sisainen_kaivuu_paallystamaton, 
      tontin_sisainen_kaivuu_paallystetty, 
      tontinulkoinen_kaivuu_paallystamaton_metria, 
      tontinulkoinen_kaivuu_paallystetty_metria, 
      tontinulkopuolinen_nurmikko_m2, 
      tonttikaivuu_max30_metria, 
      tonttikaivuu_metri, 
      tonttikaivuu_yli30_metria, 
      valokuitujen_kasittely, 
      created_date,
      liittyman_kayttoonotto,
      liittyman_kayttoonotto_time,
      teletyo
      kayttoon_otto_tuntia
sisa_asennus_lisatietoja
kaivuu_tuntityo_selite
hitaus_tuntityo_selite
puhallus_lisatietoja
kayttoonotto_lisatietoja
suunnittelu_tuntia
sisa_asennus_tuntia
    }
  }
`;


function Download(tyomaa) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [tyontekija, setTyontekija] = useState("kaikki");
    const tyomaaId = tyomaa.tyomaa;

    const summarizeData = (data, fieldMap) => {
        const summary = {};
        data.forEach(item => {
            for (let [field, value] of Object.entries(item)) {
                if (fieldMap[field] && value !== null && value !== '' && !isNaN(value)) {
                    if (!summary[field]) {
                        summary[field] = 0;
                    }
                    summary[field] += parseInt(value, 10);
                }
            }
        });
        return summary;
    };

    const handleExportToExcel = (fetchedData) => {
        const formattedData = [];
        const excludedAttributes = [
            "__typename",
            "tyomaanosoite",
            "tyomaanid",
            "nimimerkki",
            "tyon_nimi",
            "created_date",
            "id"
        ];

        const TYON_NIMI_MAP = {
            'Eltel jalkiliittyma': 'Eltel Jälkiliittymä',
            'Eltel uudisrakentaminen': 'Eltel uudiskohde',
            'EnersenseJalkiliittyminen': 'Enersense Jälkiliittymä',
            'EnersenseUudisrakentaminen': 'Enersense uudiskohde',
        };

        const fieldMap = {
            "sisa_asennus": "Sisäasennus",
            "sisa_asennus_laitteen_kanssa": "Sisäasennus päätelaitteen kanssa",
            "okt_tonttikaivuu": "Tonttikaivuu",
            "okt_tonttisuunnittelu": "Tonttisuunnittelu",
            "muu_tyo": "Muu Työ",
            "tonttikaivuu_metri": "Tonttikaivuu Metri",
            "kaivinkonetyotunti": "Kaivinkone Työtunti",
            "kaivinkonetyotunti_time": "Kaivinkone Työtunti",
            "aputyotunti_time": "Aputyötunti",
            "kuorma_auto_tyotunti_time": "Kuorma-auto Työtunti",
            "taloyhtio_metri_kaivuu": "Taloyhtiö Metri Kaivuu",
            "tonttikaivuu_max30_metria": "Tonttikaivuu Max 30 Metriä",
            "tonttikaivuu_yli30_metria": "Tonttikaivuu, pituus Metriä",
            "kuitupuhallus_metria": "Kuitupuhallus Metriä",
            "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
            "pistemainen_kaivuu_kpl": "Pistemäinen Kaivuu Kpl",
            "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoisen Kaivuu Päällystetty Metriä",
            "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoisen Kaivuu Päällystämätön Metriä",
            "tontinulkopuolinen_nurmikko_m2": "Tontinulkopuolinen Nurmikko m²",
            "tontin_sisainen_kaivuu_paallystetty": "Tontin Sisäinen Kaivuu Päällystetty",
            "tontin_sisainen_kaivuu_paallystamaton": "Tontin Sisäinen Kaivuu Päällystämätön",
            "jatkamisen_valmistelu": "Jatkamisen tai päättämisen valmistelu ja lopputyöt",
            "katukivi_laatta_m2": "Katukivi Laatta m²",
            "kuitujen_jatkaminen": "Kuitujen Jatkaminen",
            "runkokaapelin_kasittely": "Runkokaapelin pään käsittely",
            "tilaajakaapelin_kasittely": "Tilaajakaapelin pään käsittely",
            "valokuitujen_kasittely": "Valokuitujen Käsittely",
            "tyo_alkoi": "Työ Alkoi",
            "tyo_loppui": "Työ Loppui",
            "aputyotunti": "Aputyötunti",
            "liittyman_kayttoonotto": "Liittyman käyttöönotto",
            "teletyo": "Teletyö",
            "suunnittelu_tuntia": "Suunnittelu tuntia",
            "sisa_asennus_tuntia": "Sisaasennus tuntia",
            "kayttoon_otto_tuntia": "Käyttöönotto tuntia",
            "puhallus_tuntia": "Puhallus tuntia",
            "sisa_asennus_lisatietoja": "Sisäasennus lisätietoja",
            "kaivuu_tuntityo_selite": "Kaivuu lisätietoja",
            "hitaus_tuntityo_selite": "Teletyö lisätietoja",
            "puhallus_lisatietoja": "Puhallustyö lisätietoja",
            "kayttoonotto_lisatietoja": "Käyttöönotto lisätietoja"

        };

        fetchedData.construction_data.forEach((item) => {
            const tyo = TYON_NIMI_MAP[item.tyon_nimi] || item.tyon_nimi;
            const itemData = [];
            itemData.push({ A: `Nimi: ${item.nimimerkki}` });
            itemData.push({ A: `Työmaanosoite: ${item.tyomaanosoite}`, B: `Projekti: ${item.tyomaanid}`, C: `Työ: ${tyo}` });
            itemData.push({});
            itemData.push({ A: "Tuote", B: "Yksikkö", C: "Arvo" });

            for (let [field, value] of Object.entries(item)) {
                if (!field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== "") {
                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let unit = field.includes("metri") ? "metriä" : field.includes("tunti") ? "tuntia" : field.includes("elety") ? "tuntia" :"Kpl";
                    itemData.push({ A: friendlyField, B: unit, C: value });
                }
            }
            itemData.push({}, {}, {});
            formattedData.push(...itemData);
        });

// Special handling for summary
        const summary = fetchedData.construction_data.reduce((acc, item) => {
            Object.keys(fieldMap).forEach(field => {
                acc[field] = acc[field] || 0;
                if (field === "tonttikaivuu_yli30_metria") {
                    // Count each non-zero occurrence of the field
                    if (item[field] > 0) acc[field] += 1;
                } else {
                    // Regular summation for other fields
                    acc[field] += item[field] || 0;
                }
            });
            return acc;
        }, {});

        formattedData.push({ A: "Yhteensä" });
        Object.keys(summary).forEach(key => {
            if (summary[key] !== 0 && summary[key] !== null && !isNaN(summary[key])) {
                let friendlyField = fieldMap[key] ? fieldMap[key] : key;
                // Use the 'occurrences' wording only for the specific field
                const summaryValue = key === "tonttikaivuu_yli30_metria" ? summary[key] : summary[key];

                if (key === "tonttikaivuu_yli30_metria") {
                    friendlyField = "Tonttikaivuuta"
                }

                formattedData.push({ A: friendlyField, B: summaryValue });
            }
        });

        const ws = XLSX.utils.json_to_sheet(formattedData, { header: ["A", "B", "C"], skipHeader: true });

// Setting column widths
        ws['!cols'] = [
            { width: 30 }, // Width for column A
            { width: 30 }, // Width for column B
            { width: 30 }  // Width for column C
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx");

    };



        const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL_DATA, {
        onCompleted: data => handleExportToExcel(data)
    });

    const handleFetchAndExport = () => {
        fetchData({ variables: { startDate, endDate, tyomaaId }});
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;


    return (
        <div>
            <label>
                Alku pvm:
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </label>
            <label>
                Loppu pvm:
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </label>
            <button onClick={handleFetchAndExport}>Lataa excel</button>
        </div>
    );

}

export default Download;
