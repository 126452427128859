import React, {useEffect, useState} from 'react';
import DurationPicker from "./DurationPicker";
import {Alert, Box, Button, Snackbar, TextField, Typography} from "@mui/material";
import {ApolloClient, InMemoryCache, gql, createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {useAuth0} from "@auth0/auth0-react";



const EnersenseJalkiLiittyminen = ({ forUpdateData }) => {
    const {user, isAuthenticated, isLoading} = useAuth0();

    const [tyomaaId, setTyomaaId] = useState('');
    const [tyomaanosoite, setTyomaanosoite] = useState('');
    const [tyoAlkoi, setTyoalkoi] = useState(undefined); // use null for date fields
    const [tyoLoppui, setTyoLoppui] = useState(undefined); // use null for date fields
    const [kaytettyAika, setKaytettyAika] = useState({ hours: 0, minutes: 0 });

    const [nimimerkki, setNimimerkki] = useState(user.email);
    const [tyonNimi, setTyonNimi] = useState('EnersenseJalkiliittyminen');

    const [tonttisuunnittelu, setTonttisuunnittelu] = useState(0);
    const [tonttisuunnitteluTime, setTonttisuunnitteluTime] = useState({ hours: 0, minutes: 0 });

    const [sisaAsennus, setSisaAsennus] = useState(0);
    const [sisaAsennusTime, setSisaAsennusTime] = useState({ hours: 0, minutes: 0 });

    const [pistemainenKaivuu, setPistemainenKaivuu] = useState(0);
    const [pistemainenKaivuuTime, setPistemainenKaivuuTime] = useState({ hours: 0, minutes: 0 });


    const [tontinSisainenKaivuuMax30Metria, setTontinSisainenKaivuuMax30Metria] = useState(0);
    const [tontinSisainenKaivuuMax30MetriaTime, setTontinSisainenKaivuuMax30MetriaTime] = useState({ hours: 0, minutes: 0 });

    const [tontinSisainenKaivuuYli30Metria, setTontinSisainenKaivuuYli30Metria] = useState(0);
    const [tontinSisainenKaivuuYli30MetriaTime, setTontinSisainenKaivuuYli30MetriaTime] = useState({ hours: 0, minutes: 0 });

    const [tontinUlkoinenPaallystettyKaivuu, setTontinUlkoinenPaallystettyKaivuu] = useState(0);
    const [tontinUlkoinenPaallystettyKaivuuTime, setTontinUlkoinenPaallystettyKaivuuTime] = useState({ hours: 0, minutes: 0 });

    const [tontinUlkoinenPaallystamatonKaivuu, setTontinUlkoinenPaallystamatonKaivuu] = useState(0);
    const [tontinUlkoinenPaallystamatonKaivuuTime, setTontinUlkoinenPaallystamatonKaivuuTime] = useState({ hours: 0, minutes: 0 });

    const [tontinUlkopuolinenNurmikkoM2, setTontinUlkopuolinenNurmikkoM2] = useState(0);
    const [tontinUlkopuolinenNurmikkoM2Time, setTontinUlkopuolinenNurmikkoM2Time] = useState({ hours: 0, minutes: 0 });

    const [katukivetysLaattaM2, setKatukivetysLaattaM2] = useState(0);
    const [katukivetysLaattaM2Time, setKatukivetysLaattaM2Time] = useState({ hours: 0, minutes: 0 });

       const [kuitupuhallus, setKuitupuhallus] = useState(0);
        const [kuitupuhallusTime, setKuitupuhallusTime] = useState({ hours: 0, minutes: 0 });
        const [kaivinkonetyoTunti, setKaivinkonetyoTunti] = useState(0);
        const [kaivinkonetyoTuntiTime, setKaivinkonetyoTuntiTime] = useState({ hours: 0, minutes: 0 });
        const [aputyoTunti, setAputyoTunti] = useState(0);
        const [aputyoTuntiTime, setAputyoTuntiTime] = useState({ hours: 0, minutes: 0 });
        const [kuormaAutoTyotunti, setKuormaAutoTyotunti] = useState(0);
        const [kuormaAutoTyotuntiTime, setKuormaAutoTyotuntiTime] = useState({ hours: 0, minutes: 0 });
        const [muut, setMuut] = useState('');
        const [muutTime, setMuutTime] = useState({ hours: 0, minutes: 0 });





    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);

    const httpLink = createHttpLink({
        uri: 'https://kuoppapekka.fi/v1/graphql',
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem('accessToken');

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    useEffect(() => {
        if (forUpdateData) {
            setTyomaaId(forUpdateData.tyomaanid || "");
            setTyomaanosoite(forUpdateData.tyomaanosoite || "");
            setTyoalkoi(forUpdateData.tyo_alkoi !== "" ? forUpdateData.tyo_alkoi : null);
            setTyoLoppui(forUpdateData.tyo_loppui !== "" ? forUpdateData.tyo_loppui : null);
            setKaytettyAika({ hours: forUpdateData.kaytetty_aika?.split(':')[0] || "0", minutes: forUpdateData.kaytetty_aika?.split(':')[1] || "0" });

            setTonttisuunnittelu(forUpdateData.okt_tonttisuunnittelu || 0);
            setTonttisuunnitteluTime({ hours: forUpdateData.okt_tonttisuunnittelu_time?.split(':')[0] || "0", minutes: forUpdateData.okt_tonttisuunnittelu_time?.split(':')[1] || "0" });


            setSisaAsennus(forUpdateData.sisa_asennus || 0);
            setSisaAsennusTime({ hours: forUpdateData.sisa_asennus_time?.split(':')[0] || "0", minutes: forUpdateData.sisa_asennus_time?.split(':')[1] || "0" });


            setPistemainenKaivuu(forUpdateData.pistemainen_kaivuu_kpl || 0);
            setPistemainenKaivuuTime({ hours: forUpdateData.pistemainen_kaivuu_kpl_time?.split(':')[0] || "0", minutes: forUpdateData.pistemainen_kaivuu_kpl_time?.split(':')[1] || "0" });

            setTontinSisainenKaivuuMax30Metria(forUpdateData.tonttikaivuu_max30_metria || 0);
            setTontinSisainenKaivuuMax30MetriaTime({ hours: forUpdateData.tonttikaivuu_max30_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tonttikaivuu_max30_metria_time?.split(':')[1] || "0" });

            setTontinSisainenKaivuuYli30Metria(forUpdateData.tonttikaivuu_yli30_metria || 0);
            setTontinSisainenKaivuuYli30MetriaTime({ hours: forUpdateData.tonttikaivuu_yli30_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tonttikaivuu_max30_metria_time?.split(':')[1] || "0" });



            setTontinUlkoinenPaallystettyKaivuu(forUpdateData.tontinulkoinen_kaivuu_paallystetty_metria || 0);
            setTontinUlkoinenPaallystettyKaivuuTime({ hours: forUpdateData.tontinulkoinen_kaivuu_paallystetty_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tontinulkoinen_kaivuu_paallystetty_metria_time?.split(':')[1] || "0" });

            setTontinUlkoinenPaallystamatonKaivuu(forUpdateData.tontinulkoinen_kaivuu_paallystamaton_metria || 0);
            setTontinUlkoinenPaallystamatonKaivuuTime({ hours: forUpdateData.tontinulkoinen_kaivuu_paallystamaton_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tontinulkoinen_kaivuu_paallystamaton_metria_time?.split(':')[1] || "0" });

            setTontinUlkopuolinenNurmikkoM2(forUpdateData.tontinulkopuolinen_nurmikko_m2 || 0);
            setTontinUlkopuolinenNurmikkoM2Time({ hours: forUpdateData.tontinulkopuolinen_nurmikko_m2_time?.split(':')[0] || "0", minutes: forUpdateData.tontinulkopuolinen_nurmikko_m2_time?.split(':')[1] || "0" });



            setKatukivetysLaattaM2(forUpdateData.katukivi_laatta_m2 || 0);
            setKatukivetysLaattaM2Time({ hours: forUpdateData.katukivi_laatta_m2_time?.split(':')[0] || "0", minutes: forUpdateData.katukivi_laatta_m2_time?.split(':')[1] || "0" });


            setKuitupuhallus(forUpdateData.kuitupuhallus_metria || 0);
            setKuitupuhallusTime({ hours: forUpdateData.kuitupuhallus_metria_time?.split(':')[0] || "0", minutes: forUpdateData.kuitupuhallus_ftth_time?.split(':')[1] || "0" });


            setKaivinkonetyoTunti(forUpdateData.kaivinkonetyotunti || 0);
            setKaivinkonetyoTuntiTime({ hours: forUpdateData.kaivinkonetyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.kaivinkonetyotunti_time?.split(':')[1] || "0" });


            setAputyoTunti(forUpdateData.aputyotunti || 0);
            setAputyoTuntiTime({ hours: forUpdateData.aputyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.aputyotunti_time?.split(':')[1] || "0" });

            setKuormaAutoTyotunti(forUpdateData.kuorma_auto_tyotunti || 0);
            setKuormaAutoTyotuntiTime({ hours: forUpdateData.kuorma_auto_tyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.kuorma_auto_tyotunti_time?.split(':')[1] || "0" });

            setMuut(forUpdateData.muu_tyo || "");
            setMuutTime({ hours: forUpdateData.muu_tyo_time?.split(':')[0] || "0", minutes: forUpdateData.muu_tyo_time?.split(':')[1] || "0" });
        }
    }, [forUpdateData]);



    const UPDATE_CONSTRUCTION_DATA = gql`
    mutation UpdateConstructionData(
        $id: Int!, 
        $tyomaanid: String,
        $tyomaanosoite: String,
        $tyo_alkoi: date,
        $tyo_loppui: date,
        $kaytetty_aika: String,
        $nimimerkki: String,
        $tyon_nimi: String,
        $okt_tonttisuunnittelu: Int,
        $okt_tonttisuunnittelu_time: String,
        $kaivinkonetyotunti: Int,
        $kaivinkonetyotunti_time: String,
        $aputyotunti: Int,
        $aputyotunti_time: String,
        $kuorma_auto_tyotunti: Int,
        $kuorma_auto_tyotunti_time: String,
        $sisa_asennus: Int,
        $sisa_asennus_time: String,
        $tonttikaivuu_max30_metria: Int,
        $tonttikaivuu_max30_metria_time: String,
        $tonttikaivuu_yli30_metria: Int,
        $tonttikaivuu_yli30_metria_time: String,
        $katukivi_laatta_m2: Int,
        $katukivi_laatta_m2_time: String,
        $kuitupuhallus_metria: Int,
        $kuitupuhallus_metria_time: String,
        $pistemainen_kaivuu_kpl: Int,
        $pistemainen_kaivuu_kpl_time: String,
        $tontinulkoinen_kaivuu_paallystetty_metria: Int,
        $tontinulkoinen_kaivuu_paallystetty_metria_time: String,
        $tontinulkoinen_kaivuu_paallystamaton_metria: Int,
        $tontinulkoinen_kaivuu_paallystamaton_metria_time: String,
        $tontinulkopuolinen_nurmikko_m2: Int,
        $tontinulkopuolinen_nurmikko_m2_time: String,
        $muu_tyo: String,
        $muu_tyo_time: String
    ) {
        update_construction_data(
            where: { id: { _eq: $id } },
            _set: {
                tyomaanid: $tyomaanid,
                tyomaanosoite: $tyomaanosoite,
                tyo_alkoi: $tyo_alkoi,
                tyo_loppui: $tyo_loppui,
                kaytetty_aika: $kaytetty_aika,
                nimimerkki: $nimimerkki,
                tyon_nimi: $tyon_nimi,
                okt_tonttisuunnittelu: $okt_tonttisuunnittelu,
                okt_tonttisuunnittelu_time: $okt_tonttisuunnittelu_time,
                kaivinkonetyotunti: $kaivinkonetyotunti,
                kaivinkonetyotunti_time: $kaivinkonetyotunti_time,
                aputyotunti: $aputyotunti,
                aputyotunti_time: $aputyotunti_time,
                kuorma_auto_tyotunti: $kuorma_auto_tyotunti,
                kuorma_auto_tyotunti_time: $kuorma_auto_tyotunti_time,  
                sisa_asennus: $sisa_asennus,
                sisa_asennus_time: $sisa_asennus_time,
                tonttikaivuu_max30_metria: $tonttikaivuu_max30_metria,
                tonttikaivuu_max30_metria_time: $tonttikaivuu_max30_metria_time,
                tonttikaivuu_yli30_metria: $tonttikaivuu_yli30_metria,
                tonttikaivuu_yli30_metria_time: $tonttikaivuu_yli30_metria_time,
                katukivi_laatta_m2: $katukivi_laatta_m2,
                katukivi_laatta_m2_time: $katukivi_laatta_m2_time,
                kuitupuhallus_metria: $kuitupuhallus_metria,
                kuitupuhallus_metria_time: $kuitupuhallus_metria_time,
                pistemainen_kaivuu_kpl: $pistemainen_kaivuu_kpl,
                pistemainen_kaivuu_kpl_time: $pistemainen_kaivuu_kpl_time,
                tontinulkoinen_kaivuu_paallystetty_metria: $tontinulkoinen_kaivuu_paallystetty_metria,
                tontinulkoinen_kaivuu_paallystetty_metria_time: $tontinulkoinen_kaivuu_paallystetty_metria_time,
                tontinulkoinen_kaivuu_paallystamaton_metria: $tontinulkoinen_kaivuu_paallystamaton_metria,
                tontinulkoinen_kaivuu_paallystamaton_metria_time: $tontinulkoinen_kaivuu_paallystamaton_metria_time,
                tontinulkopuolinen_nurmikko_m2: $tontinulkopuolinen_nurmikko_m2,
                tontinulkopuolinen_nurmikko_m2_time: $tontinulkopuolinen_nurmikko_m2_time,
                muu_tyo: $muu_tyo,
                muu_tyo_time: $muu_tyo_time,
            }
        ) {
            affected_rows
        }
    }
`;

    const handleCloseSend = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        window.location.reload();
    };


    const handleSubmit = () => {
        client.mutate({
            mutation: UPDATE_CONSTRUCTION_DATA,
            variables: {
                id: forUpdateData.id,
                tyomaanid: tyomaaId,
                tyomaanosoite: tyomaanosoite,
                tyo_alkoi: tyoAlkoi,
                tyo_loppui: tyoLoppui,
                kaytetty_aika: `${kaytettyAika.hours}:${kaytettyAika.minutes}`,
                nimimerkki: nimimerkki,
                tyon_nimi: tyonNimi,
                okt_tonttisuunnittelu: tonttisuunnittelu,
                okt_tonttisuunnittelu_time: `${tonttisuunnitteluTime.hours}:${tonttisuunnitteluTime.minutes}`,
                kaivinkonetyotunti: kaivinkonetyoTunti,
                kaivinkonetyotunti_time: `${kaivinkonetyoTuntiTime.hours}:${kaivinkonetyoTuntiTime.minutes}`,
                aputyotunti: aputyoTunti,
                aputyotunti_time: `${aputyoTuntiTime.hours}:${aputyoTuntiTime.minutes}`,
                kuorma_auto_tyotunti: kuormaAutoTyotunti,
                kuorma_auto_tyotunti_time: `${kuormaAutoTyotuntiTime.hours}:${kuormaAutoTyotuntiTime.minutes}`,
                sisa_asennus: sisaAsennus,
                sisa_asennus_time: `${sisaAsennusTime.hours}:${sisaAsennusTime.minutes}`,
                tonttikaivuu_max30_metria: tontinSisainenKaivuuMax30Metria,
                tonttikaivuu_max30_metria_time: `${tontinSisainenKaivuuMax30MetriaTime.hours}:${tontinSisainenKaivuuMax30MetriaTime.minutes}`,
                tonttikaivuu_yli30_metria: tontinSisainenKaivuuYli30Metria,
                tonttikaivuu_yli30_metria_time: `${tontinSisainenKaivuuYli30MetriaTime.hours}:${tontinSisainenKaivuuYli30MetriaTime.minutes}`,
                katukivi_laatta_m2: katukivetysLaattaM2,
                katukivi_laatta_m2_time: `${katukivetysLaattaM2Time.hours}:${katukivetysLaattaM2Time.minutes}`,
                kuitupuhallus_metria: kuitupuhallus,
                kuitupuhallus_metria_time: `${kuitupuhallusTime.hours}:${kuitupuhallusTime.minutes}`,
                pistemainen_kaivuu_kpl: pistemainenKaivuu,
                pistemainen_kaivuu_kpl_time: `${pistemainenKaivuuTime.hours}:${pistemainenKaivuuTime.minutes}`,
                tontinulkoinen_kaivuu_paallystetty_metria: tontinUlkoinenPaallystettyKaivuu,
                tontinulkoinen_kaivuu_paallystetty_metria_time: `${tontinUlkoinenPaallystettyKaivuuTime.hours}:${tontinUlkoinenPaallystettyKaivuuTime.minutes}`,
                tontinulkoinen_kaivuu_paallystamaton_metria: tontinUlkoinenPaallystamatonKaivuu,
                tontinulkoinen_kaivuu_paallystamaton_metria_time: `${tontinUlkoinenPaallystamatonKaivuuTime.hours}:${tontinUlkoinenPaallystamatonKaivuuTime.minutes}`,
                tontinulkopuolinen_nurmikko_m2: tontinUlkopuolinenNurmikkoM2,
                tontinulkopuolinen_nurmikko_m2_time: `${tontinUlkopuolinenNurmikkoM2Time.hours}:${tontinUlkopuolinenNurmikkoM2Time.minutes}`,
                muu_tyo: muut,
                muu_tyo_time: `${muutTime.hours}:${muutTime.minutes}`
      },
        }).then(data => {
            setOpen(true);
        }).catch(error =>{
            console.error(error)
        })
    };



    const formatDuration = (duration) => {
        return `${duration.hours} hours, ${duration.minutes} minutes`;
    };

    if (page === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    gap: '16px', // Space between the form elements
                    padding: '16px', // Add padding around the form
                }}
            >
                <Typography variant="body1">
                    Työmaa ID
                </Typography>
                <TextField
                    type="text"
                    value={tyomaaId}
                    onChange={(e) => setTyomaaId(e.target.value)}
                    required
                />
                <Typography variant="body1">
                    Työmaanosoite <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                    type="text"
                    value={tyomaanosoite}
                    onChange={(e) => setTyomaanosoite(e.target.value)}
                    required
                />
                <Typography variant="body1">
                    Työ alkoi
                </Typography>
                <TextField
                    type="datetime-local"
                    value={tyoAlkoi}
                    onChange={(e) => setTyoalkoi(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Työ loppui
                </Typography>
                <TextField
                    type="datetime-local"
                    value={tyoLoppui}
                    onChange={(e) => setTyoLoppui(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Käyetty aika
                </Typography>
                <DurationPicker
                    value={kaytettyAika}
                    onChange={setKaytettyAika}
                    label="Lisää aika"
                />
                <p>{formatDuration(kaytettyAika)}</p>
                <Button
                    variant="contained"
                    onClick={() => setPage(1)}
                    disabled={!tyomaanosoite || tyomaanosoite.trim() === ''}
                >
                    Jatka eteenpäin
                </Button>
            </Box>
        );
    }

    if (open === true) {
        return (
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSend}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSend} severity="success" sx={{ width: '100%' }}>
                    Lähetys onnistui!
                </Alert>
            </Snackbar>
        )
    }

    if (page === 1) {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => setPage(0)}>
                    Palaa taaksepäin
                </Button>
                <div className={'div-page3'}>
                    <p>Tonttisuunnittelu (kpl)</p>
                    <input type="number" value={tonttisuunnittelu} onChange={(e) => setTonttisuunnittelu(e.target.value)} />
                    <DurationPicker value={tonttisuunnitteluTime} onChange={setTonttisuunnitteluTime} label="Lisää aika" />
                    <p>{formatDuration(tonttisuunnitteluTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Sisä Asennus (kpl)</p>
                    <input type="number" value={sisaAsennus} onChange={(e) => setSisaAsennus(e.target.value)} />
                    <DurationPicker value={sisaAsennusTime} onChange={setSisaAsennusTime} label="Lisää aika" />
                    <p>{formatDuration(sisaAsennusTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Pistemainen Kaivuu (kpl)</p>
                    <input type="number" value={pistemainenKaivuu} onChange={(e) => setPistemainenKaivuu(e.target.value)} />
                    <DurationPicker value={pistemainenKaivuuTime} onChange={setPistemainenKaivuuTime} label="Lisää aika" />
                    <p>{formatDuration(pistemainenKaivuuTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin Sisäinen Kaivuu Max 30 Metriä (kpl)</p>
                    <input type="number" value={tontinSisainenKaivuuMax30Metria} onChange={(e) => setTontinSisainenKaivuuMax30Metria(e.target.value)} />
                    <DurationPicker value={tontinSisainenKaivuuMax30MetriaTime} onChange={setTontinSisainenKaivuuMax30MetriaTime} label="Lisää aika" />
                    <p>{formatDuration(tontinSisainenKaivuuMax30MetriaTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin Sisainen Kaivuu Yli 30 Metriä (Metriä)</p>
                    <input type="number" value={tontinSisainenKaivuuYli30Metria} onChange={(e) => setTontinSisainenKaivuuYli30Metria(e.target.value)} />
                    <DurationPicker value={tontinSisainenKaivuuYli30MetriaTime} onChange={setTontinSisainenKaivuuYli30MetriaTime} label="Lisää aika" />
                    <p>{formatDuration(tontinSisainenKaivuuYli30MetriaTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin Ulkopuolinen Päällystetty Kaivuu (Metriä)</p>
                    <input type="number" value={tontinUlkoinenPaallystettyKaivuu} onChange={(e) => setTontinUlkoinenPaallystettyKaivuu(e.target.value)} />
                    <DurationPicker value={tontinUlkoinenPaallystettyKaivuuTime} onChange={setTontinUlkoinenPaallystettyKaivuuTime} label="Lisää aika" />
                    <p>{formatDuration(tontinUlkoinenPaallystettyKaivuuTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin Ulkopuolinen Päällystämätön Kaivuu (Metriä)</p>
                    <input type="number" value={tontinUlkoinenPaallystamatonKaivuu} onChange={(e) => setTontinUlkoinenPaallystamatonKaivuu(e.target.value)} />
                    <DurationPicker value={tontinUlkoinenPaallystamatonKaivuuTime} onChange={setTontinUlkoinenPaallystamatonKaivuuTime} label="Lisää aika" />
                    <p>{formatDuration(tontinUlkoinenPaallystamatonKaivuuTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin Ulkopuolinen Nurmikko (M2)</p>
                    <input type="number" value={tontinUlkopuolinenNurmikkoM2} onChange={(e) => setTontinUlkopuolinenNurmikkoM2(e.target.value)} />
                    <DurationPicker value={tontinUlkopuolinenNurmikkoM2Time} onChange={setTontinUlkopuolinenNurmikkoM2Time} label="Lisää aika" />
                    <p>{formatDuration(tontinUlkopuolinenNurmikkoM2Time)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Katukivetys Laatta (M2)</p>
                    <input type="number" value={katukivetysLaattaM2} onChange={(e) => setKatukivetysLaattaM2(e.target.value)} />
                    <DurationPicker value={katukivetysLaattaM2Time} onChange={setKatukivetysLaattaM2Time} label="Lisää aika" />
                    <p>{formatDuration(katukivetysLaattaM2Time)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kuitupuhallus (Metriä)</p>
                    <input type="number" value={kuitupuhallus} onChange={(e) => setKuitupuhallus(e.target.value)} />
                    <DurationPicker value={kuitupuhallusTime} onChange={setKuitupuhallusTime} label="Lisää aika" />
                    <p>{formatDuration(kuitupuhallusTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kaivinkone Työtunti (Tuntia)</p>
                    <DurationPicker value={kaivinkonetyoTuntiTime} onChange={setKaivinkonetyoTuntiTime} label="Lisää aika" />
                    <p>{formatDuration(kaivinkonetyoTuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Aputyö Tunti (Tuntia)</p>
                    <DurationPicker value={aputyoTuntiTime} onChange={setAputyoTuntiTime} label="Lisää aika" />
                    <p>{formatDuration(aputyoTuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kuorma-auto Työtunti (Tuntia)</p>
                    <DurationPicker value={kuormaAutoTyotuntiTime} onChange={setKuormaAutoTyotuntiTime} label="Lisää aika" />
                    <p>{formatDuration(kuormaAutoTyotuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Muut työt (max 255 merkkiä)</p>
                    <input type="text" value={muut} onChange={(e) => setMuut(e.target.value)} />
                    <DurationPicker value={muutTime} onChange={setMuutTime} label="Lisää aika" />
                    <p>{formatDuration(muutTime)}</p>
                </div>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        );

    }
}


export default EnersenseJalkiLiittyminen;