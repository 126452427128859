import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, TextField } from '@mui/material';

const numberRange = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
};

const DurationPicker = ({ value, onChange, label }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hours, setHours] = useState(value.hours || 0);
    const [minutes, setMinutes] = useState(value.minutes || 0);

    const togglePicker = () => {
        setIsOpen(!isOpen);
    };

    const handleHoursChange = (e) => {
        setHours(e.target.value);
    };

    const handleMinutesChange = (e) => {
        setMinutes(e.target.value);
    };

    const handleSubmit = () => {
        onChange({ hours, minutes });
        togglePicker();
    };

    const hourOptions = numberRange(0, 24).map((value) => (
        <MenuItem key={value} value={value}>
            {value}
        </MenuItem>
    ));

    const minuteOptions = numberRange(0, 59).map((value) => (
        <MenuItem key={value} value={value}>
            {value}
        </MenuItem>
    ));

    return (
        <div>
            <Button onClick={togglePicker}>{label || 'Select Duration'}</Button>
            <Dialog open={isOpen} onClose={togglePicker}>
                <DialogTitle>{label || 'Select Duration'}</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label="Hours"
                        value={hours}
                        onChange={handleHoursChange}
                    >
                        {hourOptions}
                    </TextField>
                    <TextField
                        select
                        label="Minutes"
                        value={minutes}
                        onChange={handleMinutesChange}
                    >
                        {minuteOptions}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={togglePicker}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DurationPicker;
