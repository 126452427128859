import React, {useState} from "react";
import {ApolloClient, createHttpLink, gql, InMemoryCache, useApolloClient, useQuery} from "@apollo/client";
import {useAuth0} from "@auth0/auth0-react";
import ConstructionStatus from "./ConstructionStatus";

const KaikkiKohteet = () => {
    const {user, isAuthenticated, isLoading} = useAuth0();

    // State for controlling whether the dialog is open
    const [open, setOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);

// Open the dialog
    const openDialog = (id) => {
        setIdToDelete(id);
        setOpen(true);
    };

// Close the dialog
    const closeDialog = () => {
        setIdToDelete(null);
        setOpen(false);
    };

// Perform the deletion and close the dialog



    const FETCH_ALL_DATA = gql`
query GetProjectsAndAddresses {
  construction_data {
    sisa_asennus
    tyomaanosoite
    tonttikaivuu_max30_metria
    okt_tonttisuunnittelu
    muu_tyo
    sisa_asennus_laitteen_kanssa
  }
  projects {
    addresses {
      address
      address_id
      project_id
    }
    project_name
    project_id
  }
}

`;



    const client = useApolloClient(); // added this line


    const { loading, error, data, refetch } = useQuery(FETCH_ALL_DATA);



    const fieldMap = {
        "tyomaanid": "Projekti",
        "tyomaanosoite": "Työmaan osoite",
        "tyo_alkoi": "Työ alkoi",
        "tyo_loppui": "Työ loppui",
        "kaytetty_aika": "Käytetty aika",
        "nimimerkki": "Nimimerkki",
        "tyon_nimi": "Työn nimi",
        "okt_tonttisuunnittelu": "OKT Tonttisuunnittelu",
        "okt_tonttisuunnittelu_time": "OKT Tonttisuunnittelu aika",
        "muu_tyo": "Muu työ",
        "muu_tyo_time": "Muu työ aika",
        "okt_tonttikaivuu": "OKT Tonttikaivuu",
        "okt_tonttikaivuu_time": "OKT Tonttikaivuu aika",
        "tonttikaivuu_metri": "Tonttikaivuu metriä",
        "tonttikaivuu_metri_time": "Tonttikaivuu metriä aika",
        "kaivinkonetyotunti_time": "Kaivinkonetyötunti aika",
        "aputyotunti_time": "Aputyötunti aika",
        "kuorma_auto_tyotunti_time": "Kuorma-auto työtunti aika",
        "taloyhtio_metri_kaivuu": "Taloyhtiö metriä kaivuu",
        "taloyhtio_metri_kaivuu_time": "Taloyhtiö metriä kaivuu aika",
        "sisa_asennus": "Sisäasennus",
        "sisa_asennus_time": "Sisäasennus aika",
        "tonttikaivuu_max30_metria": "Tonttikaivuu max 30 metriä",
        "tonttikaivuu_max30_metria_time": "Tonttikaivuu max 30 metriä aika",
        "tonttikaivuu_yli30_metria": "Tonttikaivuu yli 30 metriä",
        "tonttikaivuu_yli30_metria_time": "Tonttikaivuu yli 30 metriä aika",
        "katukivi_laatta_m2": "Katukivi/laatta m²",
        "katukivi_laatta_m2_time": "Katukivi/laatta m² aika",
        "kuitupuhallus_metria": "Kuitupuhallus metriä",
        "kuitupuhallus_metria_time": "Kuitupuhallus metriä aika",
        "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
        "kuitupuhallus_ftth_time": "Kuitupuhallus FTTH aika",
        "pistemainen_kaivuu_kpl": "Pistemainen kaivuu kpl",
        "pistemainen_kaivuu_kpl_time": "Pistemainen kaivuu kpl aika",
        "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoinen kaivuu päällystetty metriä",
        "tontinulkoinen_kaivuu_paallystetty_metria_time": "Tontinulkoinen kaivuu päällystetty metriä aika",
        "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoinen kaivuu päällystämätön metriä",
        "tontinulkoinen_kaivuu_paallystamaton_metria_time": "Tontinulkoinen kaivuu päällystämätön metriä aika",
        "tontinulkopuolinen_nurmikko_m2": "Tontin ulkopuolinen nurmikko m²",
        "tontinulkopuolinen_nurmikko_m2_time": "Tontin ulkopuolinen nurmikko m² aika",
        "tontin_sisainen_kaivuu_paallystetty": "Tontin sisäinen kaivuu päällystetty",
        "tontin_sisainen_kaivuu_paallystetty_time": "Tontin sisäinen kaivuu päällystetty aika",
        "tontin_sisainen_kaivuu_paallystamaton": "Tontin sisäinen kaivuu päällystämätön",
        "tontin_sisainen_kaivuu_paallystamaton_time": "Tontin sisäinen kaivuu päällystämätön aika",
        "id": "ID"
    };



    console.log(data);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;



    return (
        <div>
            <h1>Näytä jäljellä olevat kohteet</h1>
            <ConstructionStatus constructionData={data.construction_data} projects={data.projects}></ConstructionStatus>
        </div>
    )


}

export default KaikkiKohteet;
