import React, {useEffect, useState} from 'react';
import DurationPicker from "./DurationPicker";
import {Alert, Box, Button, Snackbar, TextField, Typography} from "@mui/material";
import {ApolloClient, InMemoryCache, gql, createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {useAuth0} from "@auth0/auth0-react";



function EltelJalkiliittymaUpdate({ forUpdateData }) {
    const {user, isAuthenticated, isLoading} = useAuth0();


    const [tyomaaId, setTyomaaId] = useState('');
    const [tyomaanosoite, setTyomaanosoite] = useState('');
    const [tyoAlkoi, setTyoalkoi] = useState(null); // use null for date fields
    const [tyoLoppui, setTyoLoppui] = useState(null); // use null for date fields
    const [kaytettyAika, setKaytettyAika] = useState({ hours: 0, minutes: 0 });

    const tyonNimi = 'Eltel jalkiliittyma';
    const nimiMerkki = user.email;

    const [page, setPage] = useState(0);

    const [oktTonttisuunnittelu, setOktTonttisuunnittelu] = useState(0);
    const [oktTonttisuunnitteluAika, setOktTonttisuunnitteluAika] = useState({ hours: 0, minutes: 0 });

    const [oktTonttikaivuu, setOktTonttikaivuu] = useState(0);
    const [oktTonttikaivuuAika, setOktTonttikaivuuAika] = useState({ hours: 0, minutes: 0 });

    const [tontinulkopuolinenKaivuuPaallystetty, setTontinulkopuolinenKaivuuPaallystetty] = useState(0);
    const [tontinulkopuolinenKaivuuPaallystettyAika, setTontinulkopuolinenKaivuuPaallystettyAika] = useState({ hours: 0, minutes: 0 });

    const [tontinulkopuolinenKaivuuPaallystamaton, setTontinulkopuolinenKaivuuPaallystamaton] = useState(0);
    const [tontinulkopuolinenKaivuuPaallystamatonAika, setTontinulkopuolinenKaivuuPaallystamatonAika] = useState({ hours: 0, minutes: 0 });

    const [tontinSisainenKaivuuPaallystetty, setTontinSisainenKaivuuPaallystetty] = useState(0);
    const [tontinSisainenKaivuuPaallystettyAika, setTontinSisainenKaivuuPaallystettyAika] = useState({ hours: 0, minutes: 0 });

    const [tontinSisainenKaivuuPaallystamaton, setTontinSisainenKaivuuPaallystamaton] = useState(0);
    const [tontinSisainenKaivuuPaallystamatonAika, setTontinSisainenKaivuuPaallystamatonAika] = useState({ hours: 0, minutes: 0 });

    const [kuitupuhallusFtth, setKuitupuhallusFtth] = useState(0);
    const [kuitupuhallusFtthAika, setKuitupuhallusFtthAika] = useState({ hours: 0, minutes: 0 });

    const [aputyoTunti, setAputyoTunti] = useState(0);
    const [kaivinkoneTuntiTime, setKaivinkoneTuntiTime] = useState({ hours: 0, minutes: 0 });

    const [aputyoTuntiTime, setAputyoTuntiTime] = useState({ hours: 0, minutes: 0 });
    const [kuormaAutoTyotunti, setKuormaAutoTyotunti] = useState(0);
    const [kuormaAutoTyotuntiTime, setKuormaAutoTyotuntiTime] = useState({ hours: 0, minutes: 0 });
    const [muut, setMuut] = useState('');
    const [muutTime, setMuutTime] = useState({ hours: 0, minutes: 0 });



    const [open, setOpen] = useState(false);

    const httpLink = createHttpLink({
        uri: 'https://kuoppapekka.fi/v1/graphql',
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem('accessToken');

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    useEffect(() => {
        if (forUpdateData) {
            setTyomaaId(forUpdateData.tyomaanid || "");
            setTyomaanosoite(forUpdateData.tyomaanosoite || "");
            setTyoalkoi(forUpdateData.tyo_alkoi !== "" ? forUpdateData.tyo_alkoi : null);
            setTyoLoppui(forUpdateData.tyo_loppui !== "" ? forUpdateData.tyo_loppui : null);
            setKaytettyAika({ hours: forUpdateData.kaytetty_aika?.split(':')[0] || "0", minutes: forUpdateData.kaytetty_aika?.split(':')[1] || "0" });

            setOktTonttikaivuu(forUpdateData.okt_tonttikaivuu || 0);
            setOktTonttikaivuuAika({ hours: forUpdateData.okt_tonttikaivuu_time?.split(':')[0] || "0", minutes: forUpdateData.okt_tonttikaivuu_time?.split(':')[1] || "0" });

            setOktTonttisuunnittelu(forUpdateData.okt_tonttisuunnittelu || 0);
            setOktTonttisuunnitteluAika({ hours: forUpdateData.okt_tonttisuunnittelu_time?.split(':')[0] || "0", minutes: forUpdateData.okt_tonttisuunnittelu_time?.split(':')[1] || "0" });

            setTontinulkopuolinenKaivuuPaallystetty(forUpdateData.tontinulkoinen_kaivuu_paallystetty_metria || 0);
            setTontinulkopuolinenKaivuuPaallystettyAika({ hours: forUpdateData.tontinulkoinen_kaivuu_paallystetty_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tontinulkoinen_kaivuu_paallystetty_metria_time?.split(':')[1] || "0" });

            setTontinulkopuolinenKaivuuPaallystamaton(forUpdateData.tontinulkoinen_kaivuu_paallystamaton_metria || 0);
            setTontinulkopuolinenKaivuuPaallystamatonAika({ hours: forUpdateData.tontinulkoinen_kaivuu_paallystamaton_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tontinulkoinen_kaivuu_paallystamaton_metria_time?.split(':')[1] || "0" });

            setTontinSisainenKaivuuPaallystetty(forUpdateData.tontin_sisainen_kaivuu_paallystetty || 0);
            setTontinSisainenKaivuuPaallystettyAika({ hours: forUpdateData.tontin_sisainen_kaivuu_paallystetty_time?.split(':')[0] || "0", minutes: forUpdateData.tontin_sisainen_kaivuu_paallystetty_time?.split(':')[1] || "0" });

            setTontinSisainenKaivuuPaallystamaton(forUpdateData.tontin_sisainen_kaivuu_paallystamaton || 0);
            setTontinSisainenKaivuuPaallystamatonAika({ hours: forUpdateData.tontin_sisainen_kaivuu_paallystamaton_time?.split(':')[0] || "0", minutes: forUpdateData.tontin_sisainen_kaivuu_paallystamaton_time?.split(':')[1] || "0" });

            setKuitupuhallusFtth(forUpdateData.kuitupuhallus_ftth || 0);
            setKuitupuhallusFtthAika({ hours: forUpdateData.kuitupuhallus_ftth_time?.split(':')[0] || "0", minutes: forUpdateData.kuitupuhallus_ftth_time?.split(':')[1] || "0" });

            setAputyoTunti(forUpdateData.aputyotunti || 0);
            setAputyoTuntiTime({ hours: forUpdateData.aputyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.aputyotunti_time?.split(':')[1] || "0" });

            setKuormaAutoTyotunti(forUpdateData.kuorma_auto_tyotunti || 0);
            setKuormaAutoTyotuntiTime({ hours: forUpdateData.kuorma_auto_tyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.kuorma_auto_tyotunti_time?.split(':')[1] || "0" });

            setMuut(forUpdateData.muu_tyo || "");
            setMuutTime({ hours: forUpdateData.muu_tyo_time?.split(':')[0] || "0", minutes: forUpdateData.muu_tyo_time?.split(':')[1] || "0" });
        }
    }, [forUpdateData]);




    const UPDATE_CONSTRUCTION_DATA = gql`
mutation UpdateConstructionData(
  $id: Int!,
  $tyomaanid: String,
  $tyomaanosoite: String,
  $tyo_alkoi: date,
  $tyo_loppui: date,
  $kaytetty_aika: String,
  $okt_tonttikaivuu: Int,
  $okt_tonttikaivuu_time: String,
  $tontinulkoinen_kaivuu_paallystetty_metria: Int,
  $tontinulkoinen_kaivuu_paallystetty_metria_time: String,
  $tontinulkoinen_kaivuu_paallystamaton_metria: Int,
  $tontinulkoinen_kaivuu_paallystamaton_metria_time: String,
  $tontin_sisainen_kaivuu_paallystetty: Int,
  $tontin_sisainen_kaivuu_paallystetty_time: String,
  $tontin_sisainen_kaivuu_paallystamaton: Int,
  $tontin_sisainen_kaivuu_paallystamaton_time: String,
  $kuitupuhallus_ftth: Int,
  $kuitupuhallus_ftth_time: String,
  $kaivinkonetyotunti: Int,
  $kaivinkonetyotunti_time: String,
  $aputyotunti: Int,
  $aputyotunti_time: String,
  $kuorma_auto_tyotunti: Int,
  $kuorma_auto_tyotunti_time: String,
  $tyon_nimi: String,
  $nimimerkki: String,
  $muu_tyo: String,
  $muu_tyo_time: String
) {
  update_construction_data(where: {id: {_eq: $id}}, _set: {
    tyomaanid: $tyomaanid,
    tyomaanosoite: $tyomaanosoite,
    tyo_alkoi: $tyo_alkoi,
    tyo_loppui: $tyo_loppui,
    kaytetty_aika: $kaytetty_aika,
    okt_tonttikaivuu: $okt_tonttikaivuu,
    okt_tonttikaivuu_time: $okt_tonttikaivuu_time,
    tontinulkoinen_kaivuu_paallystetty_metria: $tontinulkoinen_kaivuu_paallystetty_metria,
    tontinulkoinen_kaivuu_paallystetty_metria_time: $tontinulkoinen_kaivuu_paallystetty_metria_time,
    tontinulkoinen_kaivuu_paallystamaton_metria: $tontinulkoinen_kaivuu_paallystamaton_metria,
    tontinulkoinen_kaivuu_paallystamaton_metria_time: $tontinulkoinen_kaivuu_paallystamaton_metria_time,
    tontin_sisainen_kaivuu_paallystetty: $tontin_sisainen_kaivuu_paallystetty,
    tontin_sisainen_kaivuu_paallystetty_time: $tontin_sisainen_kaivuu_paallystetty_time,
    tontin_sisainen_kaivuu_paallystamaton: $tontin_sisainen_kaivuu_paallystamaton,
    tontin_sisainen_kaivuu_paallystamaton_time: $tontin_sisainen_kaivuu_paallystamaton_time,
    kuitupuhallus_ftth: $kuitupuhallus_ftth,
    kuitupuhallus_ftth_time: $kuitupuhallus_ftth_time,
    kaivinkonetyotunti: $kaivinkonetyotunti,
    kaivinkonetyotunti_time: $kaivinkonetyotunti_time,
    aputyotunti: $aputyotunti,
    aputyotunti_time: $aputyotunti_time,
    kuorma_auto_tyotunti: $kuorma_auto_tyotunti,
    kuorma_auto_tyotunti_time: $kuorma_auto_tyotunti_time,
    tyon_nimi: $tyon_nimi,
    nimimerkki: $nimimerkki,
    muu_tyo: $muu_tyo,
    muu_tyo_time: $muu_tyo_time,
  }) {
    affected_rows
  }
}
`;



    const handleCloseSend = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        window.location.reload();
    };


    const handleSubmit = () => {
        client.mutate({
            mutation: UPDATE_CONSTRUCTION_DATA,
            variables: {
                id: forUpdateData.id,
                tyomaanid: tyomaaId,
                tyomaanosoite: tyomaanosoite,
                tyo_alkoi: tyoAlkoi,
                tyo_loppui: tyoLoppui,
                kaytetty_aika: `${kaytettyAika.hours}:${kaytettyAika.minutes}`,
                okt_tonttikaivuu: oktTonttikaivuu,
                okt_tonttikaivuu_time: `${oktTonttikaivuuAika.hours}:${oktTonttikaivuuAika.minutes}`,
                tontinulkoinen_kaivuu_paallystetty_metria: tontinulkopuolinenKaivuuPaallystetty,
                tontinulkoinen_kaivuu_paallystetty_metria_time: `${tontinulkopuolinenKaivuuPaallystettyAika.hours}:${tontinulkopuolinenKaivuuPaallystettyAika.minutes}`,
                tontinulkoinen_kaivuu_paallystamaton_metria: tontinulkopuolinenKaivuuPaallystamaton,
                tontinulkoinen_kaivuu_paallystamaton_metria_time: `${tontinulkopuolinenKaivuuPaallystamatonAika.hours}:${tontinulkopuolinenKaivuuPaallystamatonAika.minutes}`,
                tontin_sisainen_kaivuu_paallystetty: tontinSisainenKaivuuPaallystetty,
                tontin_sisainen_kaivuu_paallystetty_time: `${tontinSisainenKaivuuPaallystettyAika.hours}:${tontinSisainenKaivuuPaallystettyAika.minutes}`,
                tontin_sisainen_kaivuu_paallystamaton: tontinSisainenKaivuuPaallystamaton,
                tontin_sisainen_kaivuu_paallystamaton_time: `${tontinSisainenKaivuuPaallystamatonAika.hours}:${tontinSisainenKaivuuPaallystamatonAika.minutes}`,
                kuitupuhallus_ftth: kuitupuhallusFtth,
                kuitupuhallus_ftth_time: `${kuitupuhallusFtthAika.hours}:${kuitupuhallusFtthAika.minutes}`,
                aputyotunti: aputyoTunti,
                aputyotunti_time: `${aputyoTuntiTime.hours}:${aputyoTuntiTime.minutes}`,
                kuorma_auto_tyotunti: kuormaAutoTyotunti,
                kuorma_auto_tyotunti_time: `${kuormaAutoTyotuntiTime.hours}:${kuormaAutoTyotuntiTime.minutes}`,
                tyon_nimi: tyonNimi,
                nimimerkki: nimiMerkki,
                muu_tyo: muut,
                muu_tyo_time: `${muutTime.hours}:${muutTime.minutes}`
            },
        }).then(data => {
            setOpen(true);
        })
            .catch(error => console.error(error));
    };


    const formatDuration = (duration) => {
        return `${duration.hours} hours, ${duration.minutes} minutes`;
    };

    if (page === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    gap: '16px', // Space between the form elements
                    padding: '16px', // Add padding around the form
                }}
            >
                <Typography variant="body1">
                    Työmaa ID
                </Typography>
                <TextField
                    type="text"
                    value={tyomaaId}
                    onChange={(e) => setTyomaaId(e.target.value)}
                    required
                />
                <Typography variant="body1">
                    Työmaanosoite <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                    type="text"
                    value={tyomaanosoite}
                    onChange={(e) => setTyomaanosoite(e.target.value)}
                    required
                />
                <Typography variant="body1">
                    Työ alkoi
                </Typography>
                <TextField
                    type="datetime-local"
                    value={tyoAlkoi}
                    onChange={(e) => setTyoalkoi(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Työ loppui
                </Typography>
                <TextField
                    type="datetime-local"
                    value={tyoLoppui}
                    onChange={(e) => setTyoLoppui(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Käyetty aika
                </Typography>
                <DurationPicker
                    value={kaytettyAika}
                    onChange={setKaytettyAika}
                    label="Lisää aika"
                />
                <p>{formatDuration(kaytettyAika)}</p>
                <Button
                    variant="contained"
                    onClick={() => setPage(1)}
                    disabled={!tyomaanosoite || tyomaanosoite.trim() === ''}
                >
                    Jatka eteenpäin
                </Button>
            </Box>
        );
    }

    if (open === true) {
        return (
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSend}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSend} severity="success" sx={{ width: '100%' }}>
                    Lähetys onnistui!
                </Alert>
            </Snackbar>
        )
    }

    if (page === 1) {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => setPage(0)}>
                    Palaa taaksepäin
                </Button>
                <div className={'div-page3'}>
                    <p>OKT Tonttisuunnittelu (kpl)</p>
                    <input type="number" value={oktTonttisuunnittelu} onChange={(e) => setOktTonttisuunnittelu(e.target.value)} />
                    <DurationPicker value={oktTonttisuunnitteluAika} onChange={setOktTonttisuunnitteluAika} label="Lisää aika" />
                    <p>{formatDuration(oktTonttisuunnitteluAika)}</p>
                </div>
{/*                <div className={'div-page3'}>
                    <p>OKT Tonttikaivuu (kpl)</p>
                    <input type="number" value={oktTonttikaivuu} onChange={(e) => setOktTonttikaivuu(e.target.value)} />
                    <DurationPicker value={oktTonttikaivuuAika} onChange={setOktTonttikaivuuAika} label="Lisää aika" />
                    <p>{formatDuration(oktTonttikaivuuAika)}</p>
                </div>*/}
                <div className={'div-page3'}>
                    <p>Tontin ulkopuolinen kaivuu päällystetty (Metriä)</p>
                    <input type="number" value={tontinulkopuolinenKaivuuPaallystetty} onChange={(e) => setTontinulkopuolinenKaivuuPaallystetty(e.target.value)} />
                    <DurationPicker value={tontinulkopuolinenKaivuuPaallystettyAika} onChange={setTontinulkopuolinenKaivuuPaallystettyAika} label="Lisää aika" />
                    <p>{formatDuration(tontinulkopuolinenKaivuuPaallystettyAika)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin ulkopuolinen kaivuu päällystämätön (Metriä)</p>
                    <input type="number" value={tontinulkopuolinenKaivuuPaallystamaton} onChange={(e) => setTontinulkopuolinenKaivuuPaallystamaton(e.target.value)} />
                    <DurationPicker value={tontinulkopuolinenKaivuuPaallystamatonAika} onChange={setTontinulkopuolinenKaivuuPaallystamatonAika} label="Lisää aika" />
                    <p>{formatDuration(tontinulkopuolinenKaivuuPaallystamatonAika)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin sisäinen kaivuu päällystetty (Metriä)</p>
                    <input type="number" value={tontinSisainenKaivuuPaallystetty} onChange={(e) => setTontinSisainenKaivuuPaallystetty(e.target.value)} />
                    <DurationPicker value={tontinSisainenKaivuuPaallystettyAika} onChange={setTontinSisainenKaivuuPaallystettyAika} label="Lisää aika" />
                    <p>{formatDuration(tontinSisainenKaivuuPaallystettyAika)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tontin sisäinen kaivuu päällystämätön (Metriä)</p>
                    <input type="number" value={tontinSisainenKaivuuPaallystamaton} onChange={(e) => setTontinSisainenKaivuuPaallystamaton(e.target.value)} />
                    <DurationPicker value={tontinSisainenKaivuuPaallystamatonAika} onChange={setTontinSisainenKaivuuPaallystamatonAika} label="Lisää aika" />
                    <p>{formatDuration(tontinSisainenKaivuuPaallystamatonAika)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kuitupuhallus FTTH (kpl)</p>
                    <input type="number" value={kuitupuhallusFtth} onChange={(e) => setKuitupuhallusFtth(e.target.value)} />
                    <DurationPicker value={kuitupuhallusFtthAika} onChange={setKuitupuhallusFtthAika} label="Lisää aika" />
                    <p>{formatDuration(kuitupuhallusFtthAika)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kaivinkone (tuntia)</p>
                    <DurationPicker value={kaivinkoneTuntiTime} onChange={setKaivinkoneTuntiTime} label="Lisää aika" />
                    <p>{formatDuration(kaivinkoneTuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Aputyötunti (tuntia)</p>
                    <DurationPicker value={aputyoTuntiTime} onChange={setAputyoTuntiTime} label="Lisää aika" />
                    <p>{formatDuration(aputyoTuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kuorma-auto Työtunti (tuntia)</p>
                    <DurationPicker value={kuormaAutoTyotuntiTime} onChange={setKuormaAutoTyotuntiTime} label="Lisää aika" />
                    <p>{formatDuration(kuormaAutoTyotuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Muut työt (max 255 merkkiä)</p>
                    <input type="text" value={muut} onChange={(e) => setMuut(e.target.value)} />
                    <DurationPicker value={muutTime} onChange={setMuutTime} label="Lisää aika" />
                    <p>{formatDuration(muutTime)}</p>
                </div>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        );
    }
}


export default EltelJalkiliittymaUpdate;