import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {Button} from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById('root'));

const httpLink = createHttpLink({
    uri: 'https://kuoppapekka.fi/v1/graphql',
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('accessToken');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

root.render(
  <React.StrictMode>
      <Auth0Provider
          domain="dev-hyujp0t5.eu.auth0.com"
          clientId="035w1OrVs0uDNH0Twj95Ve2WUspXyfB4"
          authorizationParams={{
              redirect_uri: window.location.origin
          }}
      >
          <Button href={"https://kuoppapekka.fi"}>Palaa etusivulle! </Button>
           <ApolloProvider client={client}>
          <App />
           </ApolloProvider>

      </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
