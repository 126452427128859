import React, {useEffect, useState} from 'react';
import './App.css';
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import Profile from "./Profile";
import {
    Alert,
    Box,
    Button,
    Container,
    Snackbar,
    snackbarClasses,
    Stack,
    styled,
    TextField,
    Typography
} from "@mui/material";
import EnersenseUudisrakentaminen from "./EnersenseUudisrakentaminen";
import EnersenseJalkiLiittyminen from "./EnersenseJalkiliittyminen";
import EltelJalkiliittyma from "./EltelJalkiliittyma";
import KaikkiKohteet from "./KaikkiKohteet";
import EltelUudisrakentaminen from "./EltelUudisrakentaminen";
import KaikkiOmatKohteet from "./KaikkiOmatKohteet";
import KaikkiJaljella from "./KaikkiJaljella";
import ChooseCustomerForDownload from "./ChooseCustomerForDownload";


const App = () => {
    const [companyName, setCompanyName] = useState(null);
    const [page, setPage] = useState(1);



    const { user, isAuthenticated, loginWithPopup, getAccessTokenWithPopup } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) {
            setPage('aloitus');
        }
    }, [isAuthenticated]);



        const [open, setOpen] = useState(false);

        const handleClickSend = () => {
            setPage(0);
            setOpen(true);
        };

        const handleCloseSend = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };




        if (page === 1) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh', // Sets the minimum height to 100% of the viewport height
                }}
            >
                <h1>Kirjaudu sisään</h1>
                <LoginButton />
            </Box>
        )
    }


        if (page === 2) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh', // Sets the minimum height to 100% of the viewport height
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="div" gutterBottom>
                            Lisää uusi työkohde
                        </Typography>
                        <Stack spacing={2} direction="column">
{/*                          <Button variant="contained" fullWidth onClick={() => setPage('EltelJalkiliittyma')}>
                              Eltel jälkiliittymä
                          </Button>
                          <Button variant="contained" fullWidth onClick={() => setPage('EltelUudiskohde')}>
                              Eltel Uuudiskohde
                          </Button>
                          <Button variant="contained" fullWidth onClick={() => setPage('EnersenseJalkiliittyma')}>
                              Enersense jälkiliittymä
                          </Button>*/}
                            <Button variant="contained" fullWidth onClick={() => setPage('EnersenseUudiskohde')}>
                                Enersense
                            </Button>
                            <Button variant="contained" fullWidth onClick={() => setPage('CableCrew')}>
                                Cable Crew
                            </Button>
                            <Button variant="contained" fullWidth onClick={() => setPage('Valoo')}>
                                Valoo
                            </Button>
                            <Button variant="contained" fullWidth onClick={() => setPage('EnersenseTeletyo')}>
                                Enersense Teletyö
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            );

        }

        if (page === 'EltelJalkiliittyma') {
            return (
                <div>
                    <Profile></Profile>
                    <EltelJalkiliittyma></EltelJalkiliittyma>
                </div>
            )
        }

    if (page === 'EltelUudiskohde') {
        return (
            <div>
                <Profile></Profile>
                <EltelUudisrakentaminen></EltelUudisrakentaminen>
            </div>
        )
    }
    if (page === 'EnersenseJalkiliittyma') {
        return (
            <div>
                <Profile></Profile>
                <EnersenseJalkiLiittyminen></EnersenseJalkiLiittyminen>
            </div>
        )
    }
    if (page === 'EnersenseUudiskohde') {
        return (
            <div>
                <Profile></Profile>
                <EnersenseUudisrakentaminen tyon_nimi={'EnersenseUudisrakentaminen'} tyon_alatyyli={''}></EnersenseUudisrakentaminen>
            </div>
        )
    }

    if (page === 'EnersenseTeletyo') {
        console.log("teletyo")
        return (
            <div>
                <Profile></Profile>
                <EnersenseUudisrakentaminen tyon_nimi={'EnersenseUudisrakentaminen'} tyon_alatyyli={'Teletyo'}></EnersenseUudisrakentaminen>
            </div>
        )
    }

        if (page === 'CableCrew') {
            return (
                <div>
                    <Profile></Profile>
                    <EnersenseUudisrakentaminen tyon_nimi={'CableCrew'} tyon_alatyyli={''}></EnersenseUudisrakentaminen>
                </div>
            )
        }

            if (page === 'Valoo') {
                return (
                    <div>
                        <Profile></Profile>
                        <EnersenseUudisrakentaminen tyon_nimi={'Valoo'} tyon_alatyyli={''}></EnersenseUudisrakentaminen>
                    </div>
                )
            }


    if (page === 'aloitus') {
        const isAdmin = user['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes('admin');

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center" // This prop centers vertically
                alignItems="center" // This prop centers horizontally
                minHeight="100vh" // This prop makes sure the Box fills the whole height of the screen
            >
                <Container maxWidth="sm">
                    <Stack spacing={2} direction="column">
                        <Button variant="contained" fullWidth onClick={() => setPage(2)}>
                            Lisää uusi työkohde
                        </Button>
                        {isAdmin && (
                            <Button variant="contained" fullWidth onClick={() => setPage('show')}>
                                Näytä tehdyt työkohteet
                            </Button>
                        )}
                        {isAdmin && (
                            <Button variant="contained" fullWidth onClick={() => setPage('show-remain')}>
                                Näytä jäljellä olevat työkohteet
                            </Button>
                        )}
                        <Button variant="contained" fullWidth onClick={() => setPage('show-own')}>
                            Näytä omat tehdyt työkohteet
                        </Button>
                    </Stack>
                </Container>
            </Box>
        )
    }

    if (page === 'show') {
        return (
            <div>
                <ChooseCustomerForDownload></ChooseCustomerForDownload>
            </div>
        )

    }

    if (page === 'show-own') {
        return (
            <div>
                <KaikkiOmatKohteet></KaikkiOmatKohteet>
            </div>
        )
    }

    if (page === 'show-remain') {
        return (
            <div>
                <KaikkiJaljella></KaikkiJaljella>
            </div>
        )
    }





};

export default App;