import React, { useState } from 'react';
import DurationPicker from "./DurationPicker";
import {
    Alert,
    Box,
    Button, FormControlLabel, FormHelperText,
    Grid,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {ApolloClient, InMemoryCache, gql, createHttpLink, useQuery} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {useAuth0} from "@auth0/auth0-react";
import AddressComponent from "./AddressComponent";



const EnersenseUudisrakentaminen = ({ tyon_nimi, tyon_alatyyli }) => {
    const {user, isAuthenticated, isLoading} = useAuth0();

    const [tyomaaId, setTyomaaId] = useState('');
    const [tyomaanosoite, setTyomaanosoite] = useState('');
    const [tyoAlkoi, setTyoalkoi] = useState(""); // use null for date fields
    const [tyoLoppui, setTyoLoppui] = useState(""); // use null for date fields
    const [kaytettyAika, setKaytettyAika] = useState({hours: 0, minutes: 0});
    const [teletyo, setTeletyo] = useState(0);

    const [suunnitteluLisatietoja, setSuunnitteluLisatietoja] = useState("");
    const [sisaAsennusLisatietoja, setSisaAsennusLisatietoja] = useState("");
    const [kaivuuTuntityoSelite, setKaivuuTuntityoSelite] = useState("");
    const [hitausTuntityoSelite, setHitausTuntityoSelite] = useState("");
    const [puhallusLisatietoja, setPuhallusLisatietoja] = useState("");
    const [kayttoonottoLisatietoja, setKayttoonottoLisatietoja] = useState("");

    const [is_asfalttia, setIsAsfalttia] = useState(0);
    const [suunnittelu_tuntia, setSuunnitteluTuntia] = useState(0);
    const [sisa_asennus_tuntia, setSisaAsennusTuntia] = useState(0);
    const [kayttoon_otto_tuntia, setKayttoonOttoTuntia] = useState(0);
    const [puhallus_tuntia, setPuhallusTuntia] = useState(0);
    const [kaivuuTapa, setKaivuuTapa] = useState(0);







    const [nimimerkki, setNimimerkki] = useState(user.email);
    const [tyonNimi, setTyonNimi] = useState(tyon_nimi);
    const [tonttisuunnittelu, setTonttisuunnittelu] = useState(0);
    const [tonttisuunnitteluTime, setTonttisuunnitteluTime] = useState({hours: 0, minutes: 0});

    const [sisaAsennus, setSisaAsennus] = useState(0);
    const [sisaAsennusTime, setSisaAsennusTime] = useState({hours: 0, minutes: 0});

    const [sisaAsennusLaitteenKanssa, setSisaAsennusLaitteenKanssa] = useState(0);
    const [sisaAsennusLaitteenKanssaTime, setSisaAsennusLaitteenKanssaTime] = useState({hours: 0, minutes: 0});
    const [pistemainenKaivuu, setPistemainenKaivuu] = useState(0);


    const [oktTonttisuunnittelu, setOktTonttisuunnittelu] = useState(0);
    const [oktTonttisuunnitteluTime, setOktTonttisuunnitteluTime] = useState({hours: 0, minutes: 0});
    const [kuitupuhallusMetria, setKuitupuhallusMetria] = useState(0);
    const [kuitupuhallusMetriaTime, setKuitupuhallusMetriaTime] = useState({hours: 0, minutes: 0});
    const [tonttikaivuuMax30Metria, setTonttikaivuuMax30Metria] = useState(0);
    const [tonttikaivuuMax30MetriaTime, setTonttikaivuuMax30MetriaTime] = useState({hours: 0, minutes: 0});
    {/*Tonttikaivuu yli 30 metria = tonttikaivuu metria.*/
    }
    const [tonttikaivuuYli30Metria, setTonttikaivuuYli30Metria] = useState(0);
    const [tonttikaivuuYli30MetriaTime, setTonttikaivuuYli30MetriaTime] = useState({hours: 0, minutes: 0});
    const [katukiviLaattaM2, setKatukiviLaattaM2] = useState(0);
    const [katukiviLaattaM2Time, setKatukiviLaattaM2Time] = useState({hours: 0, minutes: 0});
    const [kaivinkonetyotunti, setKaivinkonetyotunti] = useState(0);
    const [kaivinkonetyotuntiTime, setKaivinkonetyotuntiTime] = useState({hours: 0, minutes: 0});
    const [aputyotunti, setAputyotunti] = useState(0);
    const [aputyotuntiTime, setAputyotuntiTime] = useState({hours: 0, minutes: 0});
    const [kuormaAutoTyotunti, setKuormaAutoTyotunti] = useState(0);
    const [kuormaAutoTyotuntiTime, setKuormaAutoTyotuntiTime] = useState({hours: 0, minutes: 0});

    const [jatkamisen_valmistelu, setJatkamisen_valmistelu] = useState(0);
    const [varauksen_liittaminen_runkoon, setVarauksen_liittaminen_runkoon] = useState(0);
    const [runkokaapelin_kasittely, setRunkokaapelin_kasittely] = useState(0);
    const [tilaajakaapelin_kasittely, setTilaajakaapelin_kasittely] = useState(0);
    const [valokuitujen_kasittely, setValokuitujen_kasittely] = useState(0);
    const [kuitujen_jatkaminen, setKuitujen_jatkaminen] = useState(0);
    const [liittyman_kayttoonotto, setLiittymankayttoonotto] = useState(0);
    const [liittyman_kayttoonotto_time, setLiittymankayttoonotto_time] = useState({hours: 0, minutes: 0});
    const [etakayttoonotto, setEtakayttoonotto] = useState(0);

    const [jatkamisen_valmistelu_time, setJatkamisen_valmisteluTime] = useState({hours: 0, minutes: 0});
    const [runkokaapelin_kasittely_time, setRunkokaapelin_kasittelyTime] = useState({hours: 0, minutes: 0});
    const [tilaajakaapelin_kasittely_time, setTilaajakaapelin_kasittelyTime] = useState({hours: 0, minutes: 0});
    const [valokuitujen_kasittely_time, setValokuitujen_kasittelyTime] = useState({hours: 0, minutes: 0});
    const [kuitujen_jatkaminen_time, setKuitujen_jatkaminenTime] = useState({hours: 0, minutes: 0});

    const [muut, setMuut] = useState('');
    const [muutTime, setMuutTime] = useState({hours: 0, minutes: 0});


    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);

    const [isClicked, setIsClicked] = useState(false);


    const httpLink = createHttpLink({
        uri: 'https://kuoppapekka.fi/v1/graphql',
    });

    const authLink = setContext((_, {headers}) => {
        const token = localStorage.getItem('accessToken');

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    const GET_PROJECTS_AND_ADDRESSES = gql`
  query GetCustomerAndTheirProjects($customerName: String!) {
    addresses {
      address
      address_id
      project_id
    }
    customers(where: {customer_name: {_eq: $customerName}}) {
      customer_id
      customer_name
      customers_projects {
        project_id
        project_name
        addresses {
          address_id
          address
        }
      }
    }
    kaivuutapa {
      id
      kaivuu_tapa
    }
  }
`;

    const INSERT_CONSTRUCTION_DATA = gql`
  mutation InsertConstructionData(
    $tyomaanid: String
    $tyomaanosoite: String
    $tyo_alkoi: date
    $tyo_loppui: date
    $kaytetty_aika: String
    $okt_tonttisuunnittelu: Int
    $okt_tonttisuunnittelu_time: String
    $sisa_asennus: Int
    $sisa_asennus_time: String
    $sisa_asennus_laitteen_kanssa: Int
    $sisa_asennus_laitteen_kanssa_time: String
    $okt_tonttikaivuu: Int
    $okt_tonttikaivuu_time: String
    $tonttikaivuu_metri: Int
    $tonttikaivuu_metri_time: String
    $kaivinkonetyotunti: Int
    $kaivinkonetyotunti_time: String
    $aputyotunti: Int
    $aputyotunti_time: String
    $kuorma_auto_tyotunti: Int
    $kuorma_auto_tyotunti_time: String
    $taloyhtio_metri_kaivuu: Int
    $taloyhtio_metri_kaivuu_time: String
    $tyon_nimi: String
    $nimimerkki: String
    $muu_tyo: String
    $muu_tyo_time: String
    $kuitupuhallus_metria: Int
    $kuitupuhallus_metria_time: String
    $tonttikaivuu_max30_metria: Int
    $tonttikaivuu_max30_metria_time: String
    $tonttikaivuu_yli30_metria: Int
    $tonttikaivuu_yli30_metria_time: String
    $katukivi_laatta_m2: float8
    $katukivi_laatta_m2_time: String
    $jatkamisen_valmistelu: Int
$jatkamisen_valmistelu_time: String
$runkokaapelin_kasittely: Int
$runkokaapelin_kasittely_time: String
$tilaajakaapelin_kasittely: Int
$tilaajakaapelin_kasittely_time: String
$valokuitujen_kasittely: Int
$valokuitujen_kasittely_time: String
$kuitujen_jatkaminen: Int
$kuitujen_jatkaminen_time: String
$liittyman_kayttoonotto: Int
$etakayttoonotto: Int
$liittyman_kayttoonotto_time: String
$varauksen_liittaminen_runkoon: Int
$pistemainen_kaivuu_kpl: Int
$tyon_alatyyli: String
$teletyo: Int
$suunnitteluLisatietoja: String
$sisaAsennusLisatietoja: String
$kaivuuTuntityoSelite: String
$hitausTuntityoSelite: String
$puhallusLisatietoja: String
$kayttoonottoLisatietoja: String
$is_asfalttia: Int
$suunnittelu_tuntia: Int
$sisa_asennus_tuntia: Int
$kayttoon_otto_tuntia: Int
$puhallus_tuntia: Int
$kaivuu_tapa: Int
  ) {
    insert_construction_data(
      objects: {
        tyomaanid: $tyomaanid
        tyomaanosoite: $tyomaanosoite
        tyo_alkoi: $tyo_alkoi
        tyo_loppui: $tyo_loppui
        kaytetty_aika: $kaytetty_aika
        okt_tonttisuunnittelu: $okt_tonttisuunnittelu
        okt_tonttisuunnittelu_time: $okt_tonttisuunnittelu_time
        sisa_asennus: $sisa_asennus
        sisa_asennus_time: $sisa_asennus_time
        sisa_asennus_laitteen_kanssa: $sisa_asennus_laitteen_kanssa
        sisa_asennus_laitteen_kanssa_time: $sisa_asennus_laitteen_kanssa_time
        okt_tonttikaivuu: $okt_tonttikaivuu
        okt_tonttikaivuu_time: $okt_tonttikaivuu_time
        tonttikaivuu_metri: $tonttikaivuu_metri
        tonttikaivuu_metri_time: $tonttikaivuu_metri_time
        kaivinkonetyotunti: $kaivinkonetyotunti
        kaivinkonetyotunti_time: $kaivinkonetyotunti_time
        aputyotunti: $aputyotunti
        aputyotunti_time: $aputyotunti_time
        kuorma_auto_tyotunti: $kuorma_auto_tyotunti
        kuorma_auto_tyotunti_time: $kuorma_auto_tyotunti_time
        taloyhtio_metri_kaivuu: $taloyhtio_metri_kaivuu
        taloyhtio_metri_kaivuu_time: $taloyhtio_metri_kaivuu_time
        tyon_nimi: $tyon_nimi
        nimimerkki: $nimimerkki
        muu_tyo: $muu_tyo
        muu_tyo_time: $muu_tyo_time
        kuitupuhallus_metria: $kuitupuhallus_metria
        kuitupuhallus_metria_time: $kuitupuhallus_metria_time
        tonttikaivuu_max30_metria: $tonttikaivuu_max30_metria
        tonttikaivuu_max30_metria_time: $tonttikaivuu_max30_metria_time
        tonttikaivuu_yli30_metria: $tonttikaivuu_yli30_metria
        tonttikaivuu_yli30_metria_time: $tonttikaivuu_yli30_metria_time
        katukivi_laatta_m2: $katukivi_laatta_m2
        katukivi_laatta_m2_time: $katukivi_laatta_m2_time
        jatkamisen_valmistelu: $jatkamisen_valmistelu
jatkamisen_valmistelu_time: $jatkamisen_valmistelu_time
runkokaapelin_kasittely: $runkokaapelin_kasittely
runkokaapelin_kasittely_time: $runkokaapelin_kasittely_time
tilaajakaapelin_kasittely: $tilaajakaapelin_kasittely
tilaajakaapelin_kasittely_time: $tilaajakaapelin_kasittely_time
valokuitujen_kasittely: $valokuitujen_kasittely
valokuitujen_kasittely_time: $valokuitujen_kasittely_time
kuitujen_jatkaminen: $kuitujen_jatkaminen
kuitujen_jatkaminen_time: $kuitujen_jatkaminen_time
liittyman_kayttoonotto : $liittyman_kayttoonotto
etakayttoonotto : $etakayttoonotto
liittyman_kayttoonotto_time : $liittyman_kayttoonotto_time
varauksen_liittaminen_runkoon : $varauksen_liittaminen_runkoon
pistemainen_kaivuu_kpl: $pistemainen_kaivuu_kpl
tyon_alatyyli: $tyon_alatyyli
  teletyo: $teletyo
suunnittelu_lisatietoja: $suunnitteluLisatietoja
sisa_asennus_lisatietoja: $sisaAsennusLisatietoja
kaivuu_tuntityo_selite: $kaivuuTuntityoSelite
hitaus_tuntityo_selite: $hitausTuntityoSelite
puhallus_lisatietoja: $puhallusLisatietoja
kayttoonotto_lisatietoja: $kayttoonottoLisatietoja
is_asfalttia: $is_asfalttia
suunnittelu_tuntia: $suunnittelu_tuntia
sisa_asennus_tuntia: $sisa_asennus_tuntia
kayttoon_otto_tuntia: $kayttoon_otto_tuntia
puhallus_tuntia: $puhallus_tuntia
kaivuu_tapa: $kaivuu_tapa
      }
    ) {
      affected_rows
    }
  }
`;


    const handleCloseSend = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        window.location.reload();
    };

    const {loading, error, data} = useQuery(GET_PROJECTS_AND_ADDRESSES, {
        variables: {customerName: tyonNimi}
    });


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    //   const projectNames = Object.keys(data.projects);


    // const projectNames = data.customers.customer_projects.map(project => project.project_name);

    const projectsWithAddresses = data.customers[0].customers_projects.map(project => ({
        name: project.project_name,
        project_id: project.project_id,  // Include the project_id here
        addresses: data.addresses
            .filter(address => address.project_id === project.project_id)
            .map(addressObj => addressObj.address)
    }));

    const relevantAddresses = projectsWithAddresses.find(project => project.name === tyomaaId)?.addresses || [];


    const handleSubmit = async (e) => {

        if (teletyo > 0 && hitausTuntityoSelite.length < 1) {
            alert('Lisää teletyölle tuntiselite!');
            return; // Stop the form submission
        }

        if (
            (kaivinkonetyotunti > 0 || aputyotunti > 0 || kuormaAutoTyotunti > 0) && kaivuuTuntityoSelite.length < 1) {
            alert('Lisää kaivuutuntitöille selite!');
            return; // Stop the form submission
        }

        if (suunnittelu_tuntia > 0 && suunnitteluLisatietoja.length < 1) {
            alert('Lisää suunnittelutuntitöille tuntiselite!');
            return; // Stop the form submission
        }

        if (kayttoon_otto_tuntia > 0 && kayttoonottoLisatietoja.length < 1) {
            alert('Lisää käyttöönottotuntitöille tuntiselite!');
            return; // Stop the form submission
        }

        if (puhallus_tuntia > 0 && puhallusLisatietoja.length < 1) {
            alert('Lisää käyttöönottotuntitöille tuntiselite!');
            return; // Stop the form submission
        }

        if (sisa_asennus_tuntia > 0 && sisaAsennusLisatietoja.length < 1) {
            alert('Lisää sisäasennustuntitöille tuntiselite!');
            return; // Stop the form submission
        }

        if (tonttikaivuuYli30Metria > 0 && kaivuuTapa === 0 ) {
            alert('Lisää kaivuutapa!');
            return; // Stop the form submission
        }



        if (isClicked) {
            e.preventDefault();
            return;
        }


        const CHECK_CONSTRUCTION_DATA_QUERY = gql`
  query CheckConstructionData($tyomaanosoite: String!) {
    construction_data(where: {tyomaanosoite: {_eq: $tyomaanosoite}}) {
      okt_tonttisuunnittelu
      sisa_asennus
      tonttikaivuu_yli30_metria
    }
  }
`;

        try {
            // Execute the validation query
            const {data} = await client.query({
                query: CHECK_CONSTRUCTION_DATA_QUERY,
                variables: {tyomaanosoite},
            });

            // Check if any entry in the construction_data array has okt_tonttisuunnittelu set
            const hasOktTonttisuunnittelu = data.construction_data.some(entry => entry.okt_tonttisuunnittelu >= 1);

            // Perform your validation checks
            if (sisaAsennus && data.construction_data.some(entry => entry.sisa_asennus >= 1)) {
                alert('Sisa Asennus on jo kirjattu tietokantaan. Oletko varma, että koitat lisätä oikeata työtä?');
                return; // Stop the form submission
            }

            if (tonttisuunnittelu >= 1 && hasOktTonttisuunnittelu) {
                console.log("tulee tänne")
                alert('Tonttisuunnittelu on jo kirjattu tietokantaan. Oletko varma, että koitat lisätä oikeata työtä?');
                return; // Stop the form submission
            }

            if (tonttikaivuuYli30Metria && data.construction_data.some(entry => entry.tonttikaivuu_yli30_metria >= 1)) {
                alert('Tonttikaivuu on jo kirjattu tietokantaan. Oletko varma, että koitat lisätä oikeata työtä?');
                return; // Stop the form submission
            }


        } catch (error) {
            // Handle any errors from the query or insert operation
            console.error('Error during form submission:', error);
            alert('An error occurred. Please try again.');
        }

        // Set button as clicked
        setIsClicked(true);


        client.mutate({
            mutation: INSERT_CONSTRUCTION_DATA,
            variables: {
                tyomaanid: tyomaaId,
                tyomaanosoite: tyomaanosoite,
                tyo_alkoi: tyoAlkoi !== "" ? tyoAlkoi : undefined,
                tyo_loppui: tyoLoppui !== "" ? tyoLoppui : undefined,
                kaytetty_aika: `${kaytettyAika.hours}:${kaytettyAika.minutes}`,
                okt_tonttisuunnittelu: tonttisuunnittelu,
                okt_tonttisuunnittelu_time: `${tonttisuunnitteluTime.hours}:${tonttisuunnittelu.minutes}`,
                sisa_asennus: sisaAsennus,
                sisa_asennus_time: `${sisaAsennusTime.hours}:${sisaAsennusTime.minutes}`,
                sisa_asennus_laitteen_kanssa: sisaAsennusLaitteenKanssa,
                sisa_asennus_laitteen_kanssa_time: `${sisaAsennusLaitteenKanssaTime.hours}:${sisaAsennusLaitteenKanssaTime.minutes}`,
                kuitupuhallus_metria: kuitupuhallusMetria,
                kuitupuhallus_metria_time: `${kuitupuhallusMetriaTime.hours}:${kuitupuhallusMetriaTime.minutes}`,
                tonttikaivuu_max30_metria: tonttikaivuuMax30Metria,
                tonttikaivuu_max30_metria_time: `${tonttikaivuuMax30MetriaTime.hours}:${tonttikaivuuMax30MetriaTime.minutes}`,
                tonttikaivuu_yli30_metria: tonttikaivuuYli30Metria,
                tonttikaivuu_yli30_metria_time: `${tonttikaivuuYli30MetriaTime.hours}:${tonttikaivuuYli30MetriaTime.minutes}`,
                katukivi_laatta_m2: katukiviLaattaM2,
                katukivi_laatta_m2_time: `${katukiviLaattaM2Time.hours}:${katukiviLaattaM2Time.minutes}`,
                kaivinkonetyotunti: kaivinkonetyotunti,
                kaivinkonetyotunti_time: `${kaivinkonetyotuntiTime.hours}:${kaivinkonetyotuntiTime.minutes}`,
                aputyotunti: aputyotunti,
                aputyotunti_time: `${aputyotuntiTime.hours}:${aputyotuntiTime.minutes}`,
                kuorma_auto_tyotunti: kuormaAutoTyotunti,
                kuorma_auto_tyotunti_time: `${kuormaAutoTyotuntiTime.hours}:${kuormaAutoTyotuntiTime.minutes}`,
                tyon_nimi: tyonNimi,
                nimimerkki: nimimerkki,
                jatkamisen_valmistelu: jatkamisen_valmistelu,
                jatkamisen_valmistelu_time: `${jatkamisen_valmistelu_time.hours}:${jatkamisen_valmistelu_time.minutes}`,
                runkokaapelin_kasittely: runkokaapelin_kasittely,
                runkokaapelin_kasittely_time: `${runkokaapelin_kasittely_time.hours}:${runkokaapelin_kasittely_time.minutes}`,
                tilaajakaapelin_kasittely: tilaajakaapelin_kasittely,
                tilaajakaapelin_kasittely_time: `${tilaajakaapelin_kasittely_time.hours}:${tilaajakaapelin_kasittely_time.minutes}`,
                valokuitujen_kasittely: valokuitujen_kasittely,
                valokuitujen_kasittely_time: `${valokuitujen_kasittely_time.hours}:${valokuitujen_kasittely_time.minutes}`,
                kuitujen_jatkaminen: kuitujen_jatkaminen,
                kuitujen_jatkaminen_time: `${kuitujen_jatkaminen_time.hours}:${kuitujen_jatkaminen_time.minutes}`,
                liittyman_kayttoonotto: liittyman_kayttoonotto,
                etakayttoonotto: etakayttoonotto,
                liittyman_kayttoonotto_time: `${liittyman_kayttoonotto_time.hours}:${liittyman_kayttoonotto_time.minutes}`,
                muu_tyo: muut,
                muu_tyo_time: `${muutTime.hours}:${muutTime.minutes}`,
                varauksen_liittaminen_runkoon: varauksen_liittaminen_runkoon,
                pistemainen_kaivuu_kpl: pistemainenKaivuu,
                tyon_alatyyli: tyon_alatyyli,
                teletyo: teletyo,
                suunnitteluLisatietoja: suunnitteluLisatietoja,
                sisaAsennusLisatietoja: sisaAsennusLisatietoja,
                kaivuuTuntityoSelite: kaivuuTuntityoSelite,
                hitausTuntityoSelite: hitausTuntityoSelite,
                puhallusLisatietoja: puhallusLisatietoja,
                kayttoonottoLisatietoja: kayttoonottoLisatietoja,
                is_asfalttia: is_asfalttia,
                suunnittelu_tuntia: suunnittelu_tuntia,
                sisa_asennus_tuntia: sisa_asennus_tuntia,
                kayttoon_otto_tuntia: kayttoon_otto_tuntia,
                puhallus_tuntia: puhallus_tuntia,
                kaivuu_tapa: kaivuuTapa
            },
        }).then(data => {
            setOpen(true);
        }).catch(error => console.error(error));
    };


    const formatDuration = (duration) => {
        return `${duration.hours} hours, ${duration.minutes} minutes`;
    };

    if (page === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    gap: '16px', // Space between the form elements
                    padding: '16px', // Add padding around the form
                }}
            >
                {/* Tämä oli ennen työmaan id */}
                <Typography variant="body1">
                    Projektit
                </Typography>
                <InputLabel id="projektit-label">Valitse projekti</InputLabel>
                <Select
                    style={{width: '60vw'}}  // Set width as 60% of the viewport width
                    labelId="projektit-label"
                    value={tyomaaId}
                    onChange={(e) => setTyomaaId(e.target.value)}
                >
                    {projectsWithAddresses.map((project, index) => (
                        <MenuItem key={index} value={project.name}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
                <AddressComponent
                    setTyomaanosoite={setTyomaanosoite}
                    tyomaanosoite={tyomaanosoite}
                    projectName={tyomaaId}
                    relevantAddresses={relevantAddresses}
                    projectsWithAddresses={projectsWithAddresses}
                />
                <Typography variant="body1">
                    Työ alkoi
                </Typography>
                <TextField
                    type="date"
                    value={tyoAlkoi}
                    onChange={(e) => setTyoalkoi(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Työ loppui
                </Typography>
                <TextField
                    type="date"
                    value={tyoLoppui}
                    onChange={(e) => setTyoLoppui(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/*               <Typography variant="body1">
                    Käyetty aika
                </Typography>
                <DurationPicker
                    value={kaytettyAika}
                    onChange={setKaytettyAika}
                    label="Lisää aika"
                />
                <p>{formatDuration(kaytettyAika)}</p>
                */}
                <Button
                    variant="contained"
                    onClick={() => setPage(1)}
                    disabled={!tyomaanosoite || tyomaanosoite.trim() === ''}
                >
                    Jatka eteenpäin
                </Button>
            </Box>
        );
    }

    if (open === true) {
        return (
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSend}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Alert onClose={handleCloseSend} severity="success" sx={{width: '100%'}}>
                    Lähetys onnistui!
                </Alert>
            </Snackbar>
        )
    }

    if (page === 1) {
        return (
            <div>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                        gap: '16px', // Space between the form elements
                        padding: '16px', // Add padding around the form
                    }}
                >
                    <Button variant="contained" color="primary" onClick={() => setPage(0)}>
                        Palaa taaksepäin
                    </Button>

                    {tyon_alatyyli === "Teletyo" ? (
                        <>

                            <div className={'div-page3'}>
                                <h2 className="heading">Hitsaustyöt</h2>
                                <div>
                                    <p>Kuitujen jatkaminen (kpl)</p>
                                    <input
                                        type="number"
                                        value={kuitujen_jatkaminen}
                                        onChange={(e) => setKuitujen_jatkaminen(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Jatkamisen tai päättämisen valmistelu ja lopputyöt (kpl)</p>
                                    <input
                                        type="number"
                                        value={jatkamisen_valmistelu}
                                        onChange={(e) => setJatkamisen_valmistelu(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Runkokaapelin pään käsittely (kpl)</p>
                                    <input
                                        type="number"
                                        value={runkokaapelin_kasittely}
                                        onChange={(e) => setRunkokaapelin_kasittely(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Tilaajakaapelin pään käsittely (kpl)</p>
                                    <input
                                        type="number"
                                        value={tilaajakaapelin_kasittely}
                                        onChange={(e) => setTilaajakaapelin_kasittely(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Valokuitujen käsittely (kpl)</p>
                                    <input
                                        type="number"
                                        value={valokuitujen_kasittely}
                                        onChange={(e) => setValokuitujen_kasittely(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Teletyö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={teletyo}
                                        onChange={(e) => setTeletyo(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Teletyöt Tuntityöselite {teletyo > 0 && hitausTuntityoSelite.length < 1 &&
                                        <span style={{color: 'red'}}>* lisää selite teletöille!</span>}</p>
                                    <input
                                        type="text"
                                        value={hitausTuntityoSelite}
                                        onChange={(e) => setHitausTuntityoSelite(e.target.value)}
                                        required={teletyo > 0 && hitausTuntityoSelite.length <= 1}
                                    />
                                </div>
                            </div>

                            <div className={'div-page3'}>
                                <h2 className="heading">Käyttöönotto</h2>
                                <div>
                                    <p>Liittymän käyttöönotto</p>
                                    <RadioGroup
                                        name="liittymanKayttoonOttoChoice"
                                        value={liittyman_kayttoonotto.toString()}
                                        onChange={(e) => setLiittymankayttoonotto(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </div>
                                <div>
                                    <p>Liittymän etäkäyttöönotto</p>
                                    <RadioGroup
                                        name="liittymanKayttoonOttoChoice"
                                        value={etakayttoonotto.toString()}
                                        onChange={(e) => setEtakayttoonotto(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </div>
                                <div>
                                    <p>Käyttöönotto tuntityö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={kayttoon_otto_tuntia}
                                        onChange={(e) => setKayttoonOttoTuntia(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <p>Käyttöönotto tuntityö selite /
                                        lisätietoja {kayttoon_otto_tuntia > 0 && kayttoonottoLisatietoja.length < 1 &&
                                            <span
                                                style={{color: 'red'}}>* lisää selite käyttöönoton tuntitöille!</span>}</p>
                                    <input
                                        type="text"
                                        value={kayttoonottoLisatietoja}
                                        onChange={(e) => setKayttoonottoLisatietoja(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={'div-page3'}>
                                <h2 className="heading">Puhallus</h2>
                                <div>
                                    <p>Kuitupuhallus (Metriä)</p>
                                    <input
                                        type="number"
                                        value={kuitupuhallusMetria}
                                        onChange={(e) => setKuitupuhallusMetria(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Puhallus tele/tuntityö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={puhallus_tuntia}
                                        onChange={(e) => setPuhallusTuntia(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <p>Puhallus tuntityö selite / lisätietoja
                                        {puhallus_tuntia > 0 && puhallusLisatietoja.length < 1 &&
                                            <span style={{color: 'red'}}>* lisää selite puhallustuntityölle!</span>}</p>
                                    <input
                                        type="text"
                                        value={puhallusLisatietoja}
                                        onChange={(e) => setPuhallusLisatietoja(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={'div-page3'}>
                                <h2 className="heading">Lisätietoja</h2>
                                <p>Lisätietoja (max 255 merkkiä)</p>
                                <input
                                    type="text"
                                    value={muut}
                                    onChange={(e) => setMuut(e.target.value)}
                                />
                                <DurationPicker value={muutTime} onChange={setMuutTime} label="Lisää aika"/>
                                <p>{formatDuration(muutTime)}</p>
                            </div>

                        </>
                    ) : (
                        <>
                            <div className={'div-page3'}>
                                <h2 className="heading">Suunnittelu</h2>
                                <p>Tonttisuunnittelu (kpl)</p>
                                <Grid item>
                                    <RadioGroup
                                        name="tonttisuunnitteluChoice"
                                        value={tonttisuunnittelu.toString()}
                                        onChange={(e) => setTonttisuunnittelu(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </Grid>
                                <div>
                                    <p>Suunnittelu tuntityö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={suunnittelu_tuntia}
                                        onChange={(e) => setSuunnitteluTuntia(Number(e.target.value))}
                                    />
                                </div>
                                <p>Suunnittelu tuntityöselite / lisätietoja
                                    {suunnittelu_tuntia > 0 && suunnitteluLisatietoja.length < 1 &&
                                        <span style={{color: 'red'}}>* lisää selite suunnittelutuntitöille!</span>}</p>
                                <input
                                    type="text"
                                    value={suunnitteluLisatietoja}
                                    onChange={(e) => setSuunnitteluLisatietoja(e.target.value)}
                                />
                            </div>

                            <div className={'div-page3'}>
                                <h2 className="heading">Sisäasennus</h2>
                                <p>Sisäasennus (kpl)</p>
                                <Grid item>
                                    <RadioGroup
                                        name="sisaAsennusLaitteenKanssaChoice"
                                        value={sisaAsennus.toString()}
                                        onChange={(e) => setSisaAsennus(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </Grid>
                                <div>
                                    <p>Sisäasennus tuntityö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={sisa_asennus_tuntia}
                                        onChange={(e) => setSisaAsennusTuntia(Number(e.target.value))}
                                    />
                                </div>
                                <p>Sisäasennus tuntityö selite / lisätietoja
                                    {sisa_asennus_tuntia > 0 && sisaAsennusLisatietoja.length < 1 &&
                                        <span style={{color: 'red'}}>* lisää selite sisäasennuksentuntitöille!</span>}</p>
                                <input
                                    type="text"
                                    value={sisaAsennusLisatietoja}
                                    onChange={(e) => setSisaAsennusLisatietoja(e.target.value)}
                                />
                            </div>

                            <div className={'div-page3'}>
                                <h2 className="heading">Kaivuusuoritteet</h2>
                                <div>
                                    <p>Pistemainen Kaivuu (kpl)</p>
                                    <input
                                        type="number"
                                        value={pistemainenKaivuu}
                                        onChange={(e) => setPistemainenKaivuu(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Tonttikaivuu, pituus Metriä (m)</p>
                                    <input
                                        type="number"
                                        value={tonttikaivuuYli30Metria}
                                        onChange={(e) => setTonttikaivuuYli30Metria(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Katukivi/laatta (M²)</p>
                                    <input
                                        type="number"
                                        value={katukiviLaattaM2}
                                        onChange={(e) => setKatukiviLaattaM2(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <InputLabel>Valitse kaivuutapa</InputLabel>
                                    <Select
                                        value={kaivuuTapa}
                                        onChange={(e) => setKaivuuTapa(e.target.value)}
                                    >
                                        {data.kaivuutapa.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.kaivuu_tapa}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {tonttikaivuuYli30Metria > 0 && !kaivuuTapa && (
                                        <FormHelperText style={{ color: 'red' }}>* Valitse kaivuutapa</FormHelperText>
                                    )}
                                </div>
                                <div>
                                    <p>Kaivinkonetyötunti (tuntia)</p>
                                    <input
                                        type="number"
                                        value={kaivinkonetyotunti}
                                        onChange={(e) => setKaivinkonetyotunti(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Aputyötunti (tuntia)</p>
                                    <input
                                        type="number"
                                        value={aputyotunti}
                                        onChange={(e) => setAputyotunti(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Kuorma-auto Työtunti (tuntia)</p>
                                    <input
                                        type="number"
                                        value={kuormaAutoTyotunti}
                                        onChange={(e) => setKuormaAutoTyotunti(e.target.value)}
                                    />
                                </div>


                                {tyonNimi === "CableCrew" && (
                                    <div>
                                        <p>Varauksen liittäminen runkoon (kpl)</p>
                                        <input
                                            type="number"
                                            name="varauksen_liittaminen_runkoon"
                                            value={varauksen_liittaminen_runkoon}
                                            onChange={(e) => setVarauksen_liittaminen_runkoon(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div>
                                    <p>Kaivinkone, aputyö ja kuorma-auto tuntiselite /
                                        lisätietoja{(kaivinkonetyotunti > 0 || aputyotunti > 0 || kuormaAutoTyotunti > 0) && kaivuuTuntityoSelite.length < 1 &&
                                            <span style={{color: 'red'}}>* lisää selite kaivuu töille!</span>}</p>

                                    <input
                                        type="text"
                                        value={kaivuuTuntityoSelite}
                                        onChange={(e) => setKaivuuTuntityoSelite(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Oliko asfalttia?</p>
                                    <RadioGroup
                                        name="isAsfalttiaChoice"
                                        value={is_asfalttia.toString()}
                                        onChange={(e) => setIsAsfalttia(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </div>
                            </div>


                            <div className={'div-page3'}>
                                <h2 className="heading">Hitsaustyöt</h2>
                                <div>
                                    <p>Kuitujen jatkaminen (kpl)</p>
                                    <input
                                        type="number"
                                        value={kuitujen_jatkaminen}
                                        onChange={(e) => setKuitujen_jatkaminen(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Jatkamisen tai päättämisen valmistelu ja lopputyöt (kpl)</p>
                                    <input
                                        type="number"
                                        value={jatkamisen_valmistelu}
                                        onChange={(e) => setJatkamisen_valmistelu(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Runkokaapelin pään käsittely (kpl)</p>
                                    <input
                                        type="number"
                                        value={runkokaapelin_kasittely}
                                        onChange={(e) => setRunkokaapelin_kasittely(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Tilaajakaapelin pään käsittely (kpl)</p>
                                    <input
                                        type="number"
                                        value={tilaajakaapelin_kasittely}
                                        onChange={(e) => setTilaajakaapelin_kasittely(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Valokuitujen käsittely (kpl)</p>
                                    <input
                                        type="number"
                                        value={valokuitujen_kasittely}
                                        onChange={(e) => setValokuitujen_kasittely(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Tuntityö / Teletyö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={teletyo}
                                        onChange={(e) => setTeletyo(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Teletyöt Tuntityöselite {teletyo > 0 && hitausTuntityoSelite.length < 1 &&
                                        <span style={{color: 'red'}}>* lisää selite tunti/teletöille!</span>}</p>
                                    <input
                                        type="text"
                                        value={hitausTuntityoSelite}
                                        onChange={(e) => setHitausTuntityoSelite(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={'div-page3'}>
                                <h2 className="heading">Käyttöönotto</h2>
                                <div>
                                    <p>Liittymän käyttöönotto</p>
                                    <RadioGroup
                                        name="liittymanKayttoonOttoChoice"
                                        value={liittyman_kayttoonotto.toString()}
                                        onChange={(e) => setLiittymankayttoonotto(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </div>
                                <div>
                                    <p>Liittymän etäkäyttöönotto</p>
                                    <RadioGroup
                                        name="liittymanKayttoonOttoChoice"
                                        value={etakayttoonotto.toString()}
                                        onChange={(e) => setEtakayttoonotto(Number(e.target.value))}
                                    >
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Kyllä"/>
                                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="Ei"/>
                                    </RadioGroup>
                                </div>
                                <div>
                                    <p>Käyttöönotto tuntityö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={kayttoon_otto_tuntia}
                                        onChange={(e) => setKayttoonOttoTuntia(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <p>Käyttöönotto tuntityö selite /
                                        lisätietoja {kayttoon_otto_tuntia > 0 && kayttoonottoLisatietoja.length < 1 &&
                                            <span
                                                style={{color: 'red'}}>* lisää selite käyttöönoton tuntitöille!</span>}</p>
                                    <input
                                        type="text"
                                        value={kayttoonottoLisatietoja}
                                        onChange={(e) => setKayttoonottoLisatietoja(e.target.value)}
                                    />
                                </div>
                            </div>


                            <div className={'div-page3'}>
                                <h2 className="heading">Puhallus</h2>
                                <div>
                                    <p>Kuitupuhallus (Metriä)</p>
                                    <input
                                        type="number"
                                        value={kuitupuhallusMetria}
                                        onChange={(e) => setKuitupuhallusMetria(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <p>Puhallus tele/tuntityö (tuntia)</p>
                                    <input
                                        type="number"
                                        value={puhallus_tuntia}
                                        onChange={(e) => setPuhallusTuntia(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <p>Puhallus tuntityö selite / lisätietoja
                                        {puhallus_tuntia > 0 && puhallusLisatietoja.length < 1 &&
                                            <span style={{color: 'red'}}>* lisää selite suunnittelutuntitöille!</span>}</p>
                                    <input
                                        type="text"
                                        value={puhallusLisatietoja}
                                        onChange={(e) => setPuhallusLisatietoja(e.target.value)}
                                    />
                                </div>
                            </div>


                            <div className={'div-page3'}>
                                <h2 className="heading">Lisätietoja</h2>
                                <p>Lisätietoja (max 255 merkkiä)</p>
                                <input
                                    type="text"
                                    value={muut}
                                    onChange={(e) => setMuut(e.target.value)}
                                />
                                <DurationPicker value={muutTime} onChange={setMuutTime} label="Lisää aika"/>
                                <p>{formatDuration(muutTime)}</p>
                            </div>

                        </>
                    )}

                    <Button variant="contained" color="primary" onClick={(e) => handleSubmit(e)}>
                        Submit
                    </Button>
                </Box>
            </div>
        );
    }
}


export default EnersenseUudisrakentaminen;