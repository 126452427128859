import React, {useCallback, useEffect, useRef, useState} from 'react';
import {gql, useLazyQuery, useQuery} from '@apollo/client';
import * as XLSX from 'xlsx';

const FETCH_ALL_DATA = gql`
  query FetchAllData(
    $startDate: timestamp!,
    $endDatePlusOne: timestamp!,
    $tyomaaId: String
  ) {
    construction_data(
      order_by: [
        {tyomaanosoite: asc} 
      ],
      where: {
        created_date: {_gte: $startDate, _lt: $endDatePlusOne},
        tyomaanid: { _eq: $tyomaaId }
      }
    ) {
      okt_tonttisuunnittelu, 
      okt_tonttikaivuu, 
      sisa_asennus, 
      sisa_asennus_laitteen_kanssa, 
      runkokaapelin_kasittely, 
      tilaajakaapelin_kasittely, 
      valokuitujen_kasittely, 
      kuitujen_jatkaminen, 
      aputyotunti_time, 
      jatkamisen_valmistelu_time, 
      kaivinkonetyotunti_time, 
      katukivi_laatta_m2_time, 
      kaytetty_aika, 
      kuitujen_jatkaminen_time, 
      kuitupuhallus_ftth_time, 
      kuitupuhallus_metria_time, 
      kuorma_auto_tyotunti_time, 
      muu_tyo_time, 
      muu_tyo,
      nimimerkki, 
      okt_tonttikaivuu_time, 
      okt_tonttisuunnittelu_time, 
      pistemainen_kaivuu_kpl_time, 
      runkokaapelin_kasittely_time, 
      sisa_asennus_time, 
      taloyhtio_metri_kaivuu_time, 
      tilaajakaapelin_kasittely_time, 
      tontin_sisainen_kaivuu_paallystamaton_time, 
      tontin_sisainen_kaivuu_paallystetty_time, 
      tontinulkoinen_kaivuu_paallystamaton_metria_time, 
      tontinulkoinen_kaivuu_paallystetty_metria_time, 
      tontinulkopuolinen_nurmikko_m2_time, 
      tonttikaivuu_max30_metria_time, 
      tonttikaivuu_metri_time, 
      tonttikaivuu_yli30_metria_time, 
      tyomaanid, 
      tyomaanosoite, 
      tyon_nimi, 
      valokuitujen_kasittely_time, 
      tyo_alkoi, 
      tyo_loppui, 
      aputyotunti, 
      id, 
      jatkamisen_valmistelu, 
      kaivinkonetyotunti, 
      katukivi_laatta_m2, 
      kuitupuhallus_ftth, 
      kuitupuhallus_metria, 
      kuorma_auto_tyotunti, 
      pistemainen_kaivuu_kpl, 
      taloyhtio_metri_kaivuu, 
      tontin_sisainen_kaivuu_paallystamaton, 
      tontin_sisainen_kaivuu_paallystetty, 
      tontinulkoinen_kaivuu_paallystamaton_metria, 
      tontinulkoinen_kaivuu_paallystetty_metria, 
      tontinulkopuolinen_nurmikko_m2, 
      tonttikaivuu_max30_metria, 
      tonttikaivuu_metri, 
      tonttikaivuu_yli30_metria, 
      created_date,
      liittyman_kayttoonotto,
      liittyman_kayttoonotto_time
      varauksen_liittaminen_runkoon
sisa_asennus_lisatietoja
kaivuu_tuntityo_selite
hitaus_tuntityo_selite
puhallus_lisatietoja
kayttoonotto_lisatietoja
suunnittelu_tuntia
sisa_asennus_tuntia
kayttoon_otto_tuntia
puhallus_tuntia
    }
  }
`;

const FETCH_ALL_DATA_EXCEL_ENERSENSE = gql`
  query FetchAllDataExcelEnersense(
    $startDate: timestamp!,
    $endDatePlusOne: timestamp!,
    $tyomaaId: String
  ) {
    construction_data(
      order_by: [
        {tyomaanosoite: asc} 
      ],
      where: {
        created_date: {_gte: $startDate, _lt: $endDatePlusOne},
        tyomaanid: { _eq: $tyomaaId }
      }
    ) {
      okt_tonttisuunnittelu, 
      okt_tonttikaivuu, 
      sisa_asennus, 
      sisa_asennus_laitteen_kanssa, 
      aputyotunti_time, 
      kaivinkonetyotunti_time, 
      katukivi_laatta_m2_time, 
      kaytetty_aika, 
      kuitupuhallus_ftth_time, 
      kuitupuhallus_metria_time, 
      kuorma_auto_tyotunti_time, 
      muu_tyo_time, 
      muu_tyo,
      nimimerkki, 
      okt_tonttikaivuu_time, 
      okt_tonttisuunnittelu_time, 
      pistemainen_kaivuu_kpl_time, 
      sisa_asennus_time, 
      taloyhtio_metri_kaivuu_time, 
      tontin_sisainen_kaivuu_paallystamaton_time, 
      tontin_sisainen_kaivuu_paallystetty_time, 
      tontinulkoinen_kaivuu_paallystamaton_metria_time, 
      tontinulkoinen_kaivuu_paallystetty_metria_time, 
      tontinulkopuolinen_nurmikko_m2_time, 
      tonttikaivuu_max30_metria_time, 
      tonttikaivuu_metri_time, 
      tonttikaivuu_yli30_metria_time, 
      tyomaanid, 
      tyomaanosoite, 
      tyon_nimi, 
      tyo_alkoi, 
      tyo_loppui, 
      aputyotunti, 
      id, 
      kaivinkonetyotunti, 
      katukivi_laatta_m2, 
      kuorma_auto_tyotunti, 
      pistemainen_kaivuu_kpl, 
      taloyhtio_metri_kaivuu, 
      tontin_sisainen_kaivuu_paallystamaton, 
      tontin_sisainen_kaivuu_paallystetty, 
      tontinulkoinen_kaivuu_paallystamaton_metria, 
      tontinulkoinen_kaivuu_paallystetty_metria, 
      tontinulkopuolinen_nurmikko_m2, 
      tonttikaivuu_max30_metria, 
      tonttikaivuu_metri, 
      tonttikaivuu_yli30_metria, 
      created_date,
      liittyman_kayttoonotto_time,
      varauksen_liittaminen_runkoon
     kuitupuhallus_metria
    jatkamisen_valmistelu
    runkokaapelin_kasittely
    tilaajakaapelin_kasittely
    valokuitujen_kasittely
    kuitujen_jatkaminen
    liittyman_kayttoonotto
    teletyo
kaivuu_tuntityo_selite
hitaus_tuntityo_selite
puhallus_lisatietoja
kayttoonotto_lisatietoja
suunnittelu_tuntia
sisa_asennus_tuntia
sisa_asennus_lisatietoja
    }
  }
`;


const fieldMap = {
    "sisa_asennus": "Sisäasennus",
    "sisa_asennus_laitteen_kanssa": "Sisäasennus",
    "okt_tonttikaivuu": "Tonttikaivuu",
    "okt_tonttisuunnittelu": "Tonttisuunnittelu",
    "muu_tyo": "Muu Työ / Lisätietoja",
    "tonttikaivuu_metri": "Tonttikaivuu Metri",
    "kaivinkonetyotunti": "Kaivinkone",
    "kaivinkonetyotunti_time": "Kaivinkone Työtunti",
    "aputyotunti_time": "Aputyötunti",
    "kuorma_auto_tyotunti_time": "Kuorma-auto",
    "taloyhtio_metri_kaivuu": "Taloyhtiö Metri Kaivuu",
    "tonttikaivuu_max30_metria": "Tonttikaivuu Max 30 m",
    "tonttikaivuu_yli30_metria": "Tonttikaivuu, yli 30 m",
    "kuitupuhallus_metria": "Nano puhallus",
    "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
    "pistemainen_kaivuu_kpl": "Pistemäinen Kaivuu Kpl",
    "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoisen Kaivuu Päällystetty m",
    "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoisen Kaivuu Päällystämätön m",
    "tontinulkopuolinen_nurmikko_m2": "Tontinulkopuolinen Nurmikko m²",
    "tontin_sisainen_kaivuu_paallystetty": "Tontin Sisäinen Kaivuu Päällystetty",
    "tontin_sisainen_kaivuu_paallystamaton": "Tontin Sisäinen Kaivuu Päällystämätön",
    "jatkamisen_valmistelu": "Jatkamisen tai päättämisen valmistelu ja lopputyöt",
    "katukivi_laatta_m2": "Pihalaatta",
    "kuitujen_jatkaminen": "Kuitujen Jatkaminen",
    "runkokaapelin_kasittely": "Runkokaapelin pään käsittely",
    "tilaajakaapelin_kasittely": "Tilaajakaapelin pään käsittely",
    "valokuitujen_kasittely": "Valokuitujen Käsittely",
    "tyo_alkoi": "Työ Alkoi",
    "tyo_loppui": "Työ Loppui",
    "aputyotunti": "Aputyötunti",
    "kuorma_auto_tyotunti": "Kuorma-auto",
    "liittyman_kayttoonotto": "Liittyman käyttöönotto",
    "varauksen_liittaminen_runkoon": "Varauksen liittäminen runkoon",
    "teletyo": "Teletyö tuntia",
    "suunnittelu_tuntia": "Suunnittelu tuntia",
    "sisa_asennus_tuntia": "Sisa asennus tuntia",
    "kayttoon_otto_tuntia": "Kayttoonotto tuntia",
    "puhallus_tuntia": "Puhallus tuntia",
    "etakayttoonotto": "Etäkäyttöönotto"
};

const fieldKeys = [
    "kuitupuhallus_metria",
    "jatkamisen_valmistelu",
    "runkokaapelin_kasittely",
    "tilaajakaapelin_kasittely",
    "valokuitujen_kasittely",
    "kuitujen_jatkaminen",
    "liittyman_kayttoonotto",
    "kayttoon_otto_tuntia",
    "etakayttoonotto",
    "suunnittelu_tuntia",
    "puhallus_tuntia",
    "teletyo",
    "muu_tyo"
];



function Download({tyomaa, customeri}) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [tyontekija, setTyontekija] = useState("kaikki");
    const tyomaaId = tyomaa;
    const [endDatePlusOne, setEndDatePlusOne] = useState("");
    const [customer, setCustomer] = useState(customeri.customeri);
    const [checkboxStates, setCheckboxStates] = useState(() => {
        const initialState = {};
        fieldKeys.forEach(field => {
            initialState[field] = true;
        });
        return initialState;
    });
    const buttonClickedRef = useRef(false); // Ref to track button click

    useEffect(() => {
        setCustomer(customeri.customeri);
    }, [customeri]); // Dependency array includes 'customeri' to watch for changes


    const handleCheckboxChange = useCallback((field) => {
        setCheckboxStates((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    }, []);

    const selectedFields = fieldKeys.filter(field => checkboxStates[field]);
    const selectedFieldsString = selectedFields.join(', ');

    const FETCH_ALL_DATA_JEHO = gql`
  query FetchAllDataJeho(
    $startDate: timestamp!,
    $endDatePlusOne: timestamp!,
    $tyomaaId: String!
  ) {
    construction_data(
      order_by: [
        { tyomaanosoite: asc } 
      ],
      where: {
        created_date: { _gte: $startDate, _lt: $endDatePlusOne },
        tyomaanid: { _eq: $tyomaaId }
      }
    ) {
      tyo_alkoi,
      tyo_loppui,
      nimimerkki,
      tyomaanid, 
      tyomaanosoite, 
      tyon_nimi, 
      kaivuu_tuntityo_selite,
      hitaus_tuntityo_selite,
      puhallus_lisatietoja,
      kayttoonotto_lisatietoja,
      ${selectedFieldsString}
    }
    customers(where: {customers_projects: {addresses: {project: {project_name: {_eq: $tyomaaId}}}}}) {
      customer_id
    }
  }
`;






    const handleExportToExcelEnersense = (fetchedData) => {

        const formattedData = [];
        const excludedAttributes = [
            "__typename",
            "tyomaanosoite",
            "tyomaanid",
            "nimimerkki",
            "tyon_nimi",
            "created_date",
            "id",
            "tyo_alkoi",
            "tyo_loppui",
            "kuitupuhallus_metria",
            "jatkamisen_valmistelu",
            "runkokaapelin_kasittely",
            "tilaajakaapelin_kasittely",
            "valokuitujen_kasittely",
            "kuitujen_jatkaminen",
            "liittyman_kayttoonotto",
            "teletyo",
            "sisa_asennus_lisatietoja",
            "kaivuu_tuntityo_selite",
            "hitaus_tuntityo_selite",
            "puhallus_lisatietoja",
            "kayttoonotto_lisatietoja",
        ];

        const TYON_NIMI_MAP = {
            'Eltel jalkiliittyma': 'Eltel Jälkiliittymä',
            'Eltel uudisrakentaminen': 'Eltel uudiskohde',
            'EnersenseJalkiliittyminen': 'Enersense Jälkiliittymä',
            'EnersenseUudisrakentaminen': 'Enersense uudiskohde',
        };

        const fieldMap = {
            "sisa_asennus": "Sisäasennus",
            "sisa_asennus_laitteen_kanssa": "Sisäasennus",
            "okt_tonttikaivuu": "Tonttikaivuu",
            "okt_tonttisuunnittelu": "Tonttisuunnittelu",
            "muu_tyo": "Muu Työ",
            "tonttikaivuu_metri": "Tonttikaivuu Metri",
            "kaivinkonetyotunti": "Kaivinkone",
            "kaivinkonetyotunti_time": "Kaivinkone Työtunti",
            "aputyotunti_time": "Aputyötunti",
            "kuorma_auto_tyotunti_time": "Kuorma-auto",
            "taloyhtio_metri_kaivuu": "Taloyhtiö Metri Kaivuu",
            "tonttikaivuu_max30_metria": "Tonttikaivuu Max 30 m",
            "tonttikaivuu_yli30_metria": "Tonttikaivuu, yli 30 m",
            "kuitupuhallus_metria": "Nano puhallus",
            "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
            "pistemainen_kaivuu_kpl": "Pistemäinen Kaivuu Kpl",
            "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoisen Kaivuu Päällystetty m",
            "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoisen Kaivuu Päällystämätön m",
            "tontinulkopuolinen_nurmikko_m2": "Tontinulkopuolinen Nurmikko m²",
            "tontin_sisainen_kaivuu_paallystetty": "Tontin Sisäinen Kaivuu Päällystetty",
            "tontin_sisainen_kaivuu_paallystamaton": "Tontin Sisäinen Kaivuu Päällystämätön",
            "jatkamisen_valmistelu": "Jatkamisen tai päättämisen valmistelu ja lopputyöt",
            "katukivi_laatta_m2": "Pihalaatta",
            "kuitujen_jatkaminen": "Kuitujen Jatkaminen",
            "runkokaapelin_kasittely": "Runkokaapelin pään käsittely",
            "tilaajakaapelin_kasittely": "Tilaajakaapelin pään käsittely",
            "valokuitujen_kasittely": "Valokuitujen Käsittely",
            "tyo_alkoi": "Työ Alkoi",
            "tyo_loppui": "Työ Loppui",
            "aputyotunti": "Aputyötunti",
            "kuorma_auto_tyotunti": "Kuorma-auto",
            "liittyman_kayttoonotto": "Liittyman käyttöönotto",
            "varauksen_liittaminen_runkoon": "Varauksen liittäminen runkoon",
            "teletyo": "Teletyö tuntia",
            "suunnittelu_tuntia": "Suunnittelu tuntia",
            "sisa_asennus_tuntia": "Sisäasennus tuntia",
            "kayttoon_otto_tuntia": "Käyttöönotto tuntia",
            "puhallus_tuntia": "Puhallus tuntia"
        };

        const priceMap = {
            "sisa_asennus": 145, // Sisäasennus
            "sisa_asennus_laitteen_kanssa": 145, // Sisäasennus
            "okt_tonttikaivuu": 0,
            "okt_tonttisuunnittelu": 90,
            "muu_tyo": 0,
            "tonttikaivuu_metri": 0,
            "kaivinkonetyotunti": 58,
            "kaivinkonetyotunti_time": 58,
            "aputyotunti_time": 38,
            "kuorma_auto_tyotunti": 58,
            "kuorma_auto_tyotunti_time": 58,
            "taloyhtio_metri_kaivuu": 0,
            "tonttikaivuu_max30_metria": 340,
            "tonttikaivuu_yli30_metria": 9,
            "kuitupuhallus_metria": 1.2,
            "kuitupuhallus_ftth": 0,
            "pistemainen_kaivuu_kpl": 120,
            "tontinulkoinen_kaivuu_paallystetty_metria": 0,
            "tontinulkoinen_kaivuu_paallystamaton_metria": 0,
            "tontinulkopuolinen_nurmikko_m2": 0,
            "tontin_sisainen_kaivuu_paallystetty": 0,
            "tontin_sisainen_kaivuu_paallystamaton": 0,
            "jatkamisen_valmistelu": 70,
            "katukivi_laatta_m2": 60,
            "kuitujen_jatkaminen": 4,
            "runkokaapelin_kasittely": 47,
            "tilaajakaapelin_kasittely": 20,
            "valokuitujen_kasittely": 1.5 ,
            "tyo_alkoi": 0,
            "tyo_loppui": 0,
            "aputyotunti": 38,
            "liittyman_kayttoonotto": 75,
            "suunnittelu_tuntia": 0,
            "sisa_asennus_tuntia": 0,
            "kayttoon_otto_tuntia": 0,
            "puhallus_tuntia": 0
        };


        // Add the General Information section once at the beginning
        const firstItem = fetchedData.construction_data[0];
        const firstTyo = TYON_NIMI_MAP[firstItem.tyon_nimi] || firstItem.tyon_nimi;
        formattedData.push({ A: "OY KUOKKA - PEKKA AB", B: "TYÖERITTELY", C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAJA", B: firstItem.nimimerkki, C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAMISAIKA", B: "" });
        formattedData.push({ A: "", B: "" });
        formattedData.push({ A: "PROJEKTI", B: "UUDISRAKENTAMINEN" });
        formattedData.push({ A: "PROJEKTIN KOHDE", B: firstItem.tyomaanid });
        formattedData.push({ A: "PROJEKTIPÄÄLIKKÖ", B: "" });
        formattedData.push({ A: "OSTOTILAUKSEN POEP NUMERO", B: "" });
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});


        const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];

        // Add work entries

        const desiredOrder = [
            "okt_tonttisuunnittelu",
            "okt_tonttikaivuu",
            "sisa_asennus",
            "pistemainen_kaivuu_kpl",
            "tonttikaivuu_max30_metria",
            "tonttikaivuu_yli30_metria",
            "katukivi_laatta_m2",
            "kuorma_auto_tyotunti",
            "kaivinkone_tyotunti",
            "aputyotunti",
            "sisa_asennus_laitteen_kanssa",
        ];

        const combinedByAddress = fetchedData.construction_data.reduce((acc, curr) => {
            if (!acc[curr.tyomaanosoite]) {
                acc[curr.tyomaanosoite] = {
                    tyomaanosoite: curr.tyomaanosoite // Explicitly set tyomaanosoite here
                };

                // Initialize the desired fields with a default value
                desiredOrder.forEach(field => {
                    acc[curr.tyomaanosoite][field] = 0;
                });

                // Initialize 'muu_tyo' as an empty string to concatenate later
                acc[curr.tyomaanosoite]['muu_tyo'] = '';
                acc[curr.tyomaanosoite]['sisa_asennus_lisatietoja'] = '';
                acc[curr.tyomaanosoite]['kaivuu_tuntityo_selite'] = '';
                acc[curr.tyomaanosoite]['hitaus_tuntityo_selite'] = '';
                acc[curr.tyomaanosoite]['puhallus_lisatietoja'] = '';
                acc[curr.tyomaanosoite]['kayttoonotto_lisatietoja'] = '';
            }



            // Combine fields
            for (let [field, value] of Object.entries(curr)) {
                if (field === '__typename') {
                    continue; // Skip read-only property
                }

                if (typeof value === 'number' || /^[0-9.]+$/.test(value)) {
                    // For numerical fields, aggregate their values
                    acc[curr.tyomaanosoite][field] = (parseFloat(acc[curr.tyomaanosoite][field]) || 0) + parseFloat(value);
                } else if (field === 'muu_tyo' && value.trim() !== '') {
                    // Concatenate 'muu_tyo' with existing value using '+', if not empty
                    acc[curr.tyomaanosoite]['muu_tyo'] = acc[curr.tyomaanosoite]['muu_tyo']
                        ? acc[curr.tyomaanosoite]['muu_tyo'] + ' + ' + value
                        : value;
                } else if (!desiredOrder.includes(field)) {
                    // For non-numerical and non-desired fields, overwrite the value
                    acc[curr.tyomaanosoite][field] = value;
                }
            }

            return acc;
        }, {});

        const hasValidFields = (item) => {
            const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];
            const isValid = Object.entries(item).some(([field, value]) => {
                if (field === "muu_tyo" && value.trim() !== "") {
                    return true;
                }

                if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 1) {
                    return true;
                }

                if (desiredFields.includes(field) && value !== "0:0") {
                    return true;
                }

                if (
                    !field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== "" &&
                    value !== undefined &&
                    value !== "0:undefined"
                ) {
                    return true;
                }

                return false;
            });

            return isValid;
        };


// Convert back to array
        const combinedDataArray = Object.values(combinedByAddress);
        let validIndex = 0; // Separate counter for valid items
        combinedDataArray.forEach((item, index) => {
            const tyo = TYON_NIMI_MAP[item.tyon_nimi] || item.tyon_nimi;
            let jobTotal = 0; // Initialize a total sum for this job

            console.log("Processing item:", item);

            if (!hasValidFields(item)) {
                console.log("Skipping item due to no valid fields:", item);
                return; // Skip items with no valid fields
            }

            validIndex++; // Increment only for valid items

            formattedData.push({ A: "", B: "" });

            // Work entry
            formattedData.push({ A: validIndex + ".", B: item.tyomaanosoite, C: item.tyomaanid });

            formattedData.push({});
            const muuTyoMaxChars = 35;

            const splitTextOnWords = (text, maxChars) => {
                let result = [];
                let words = text.split(' ');
                let currentLine = words[0];

                for (let i = 1; i < words.length; i++) {
                    if (currentLine.length + words[i].length + 1 <= maxChars) {
                        currentLine += ' ' + words[i];
                    } else {
                        result.push(currentLine);
                        currentLine = words[i];
                    }
                }
                result.push(currentLine);
                return result;
            };

            let muuTyoLines = item.muu_tyo && item.muu_tyo.trim() !== "" ? splitTextOnWords(item.muu_tyo, muuTyoMaxChars) : [""];

// Insert the lines into formattedData
            muuTyoLines.forEach((line, index) => {
                formattedData.push({
                    A: line,
                    B: index === 0 ? "Tuote" : "",
                    C: index === 0 ? "Yksikkö" : "",
                    D: index === 0 ? "Hinta yksikkö / € (alv 0 %)" : "",
                    E: index === 0 ? "Yksikköä kpl" : "",
                    F: index === 0 ? "Yhteensä € (alv 0 %)" : ""
                });
            });

            let lisatietojaSet = false; // Flag to check if lisatietoja is already set


            for (let [field, value] of Object.entries(item)) {

                if (field === "muu_tyo" && value.trim() !== "") {

                    continue; // Skip further processing for "muu_tyo"
                }

                const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];
                if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 1) {
                    const friendlyFieldMax30 = fieldMap["tonttikaivuu_max30_metria"];
                    const unitMax30 = "m";
                    const priceMax30 = priceMap["tonttikaivuu_max30_metria"] || 0;
                    // Since it's 1 unit
                    formattedData.push({B: friendlyFieldMax30, C: unitMax30, D: priceMax30, E: 1, F: priceMax30});

                    if (typeof priceMax30 !== 'undefined') {
                        jobTotal += priceMax30;
                    }

                    if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 31) {

                        value = (parseFloat(value) - 30); // Subtract 30 from the value
                    } else {
                        continue;
                    }
                }

                if (desiredFields.includes(field) && value !== "0:0") {

                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas

                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                            "Kpl";

                    console.log(unit + ": unitti. fieldi -> " + field )
                    formattedData.push({ A: "", B: friendlyField, C: unit, D: "", E: value, F: "" }); // Price and total placeholders

                }
                else if (
                    !field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== ""
                )
                {


                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";
 let price = priceMap[field] || 0; // Get the price from priceMap
                    let total = price * parseFloat(value); // Compute total. Ensure value is converted to float.



                    let lisatietoja = "";

                    if (field === 'sisa_asennus_tuntia' && parseFloat(value) > 0) {
                        lisatietoja = item.sisa_asennus_lisatietoja || "";
                    }
                    if (['aputyotunti', 'kaivinkonetyotunti', 'kuorma_auto_tyotunti'].includes(field) && parseFloat(value) > 0 && !lisatietojaSet) {
                        lisatietoja = item.kaivuu_tuntityo_selite || "";
                        lisatietojaSet = true; // Set the flag
                    }
                    if (field === 'teletyo' && parseFloat(value) > 0) {
                        lisatietoja = item.hitaus_tuntityo_selite || "";
                    }
                    if (field === 'puhallus_tuntia' && parseFloat(value) > 0) {
                        lisatietoja = item.puhallus_lisatietoja || "";
                    }
                    if (field === 'kayttoon_otto_tuntia' && parseFloat(value) > 0) {
                        lisatietoja = item.kayttoonotto_lisatietoja || "";
                    }
// Split 'lisatietoja' into chunks of 15 characters and add each chunk as a separate row
                    const maxChars = 40;

                    const splitText = (text, maxChars) => {
                        let result = [];
                        let words = text.split(' ');
                        let currentLine = words[0];

                        for (let i = 1; i < words.length; i++) {
                            if (currentLine.length + words[i].length + 1 <= maxChars) {
                                currentLine += ' ' + words[i];
                            } else {
                                result.push(currentLine);
                                currentLine = words[i];
                            }
                        }
                        result.push(currentLine);
                        return result;
                    };

                    if (lisatietoja.length > maxChars) {
                        let splitLisatietoja = splitText(lisatietoja, maxChars);
                        splitLisatietoja.forEach((chunk, index) => {
                            formattedData.push({
                                A: chunk,
                                B: index === 0 ? friendlyField : "", // Only include friendlyField on the first row
                                C: index === 0 ? unit : "",           // Only include unit on the first row
                                D: index === 0 ? price : "",          // Only include price on the first row
                                E: index === 0 ? value : "",          // Only include value on the first row
                                F: index === 0 ? total : ""           // Only include total on the first row
                            });
                        });
                    } else {
                        formattedData.push({ A: lisatietoja, B: friendlyField, C: unit, D: price, E: value, F: total });
                    }

                    if (typeof total !== 'undefined') {
                        jobTotal += total;
                    }

                }

            }
            formattedData.push({ A: "", B: "Yhteensä", F: jobTotal }); // Total placeholder
            formattedData.push({}); // 3 empty rows
        });

        const ws = XLSX.utils.json_to_sheet(formattedData, { header: ["A", "B", "C", "D", "E", "F"], skipHeader: true });


        // Set column widths
        ws['!cols'] = [
            { wch: 35 }, // Column A
            { wch: 30 }, // Column B
            { wch: 25 }, // Column C
            { wch: 15 }, // Column D
            { wch: 20 }  // Column E
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx", { cellStyles: true });
    };


    const [fetchData, { loading, error, data }] = useLazyQuery(
        customer === "EnersenseUudisrakentaminen" ? FETCH_ALL_DATA_EXCEL_ENERSENSE :
            customer === "Jeho" ? FETCH_ALL_DATA_JEHO :
                FETCH_ALL_DATA,
        {
            onCompleted: data => {
                const customerId = data.customers?.[0]?.customer_id ?? 1;
                // Conditional logic based on the 'customer' state
                if (customer === "EnersenseUudisrakentaminen") {
                    console.log("enersense");
                    handleExportToExcelEnersense(data);
                } else if (customer === "CableCrew") {
                    console.log("cablecrew");
                    handleExportToExcelCableCrew(data);
                } else if (customer === "Valoo") {
                    console.log("Valoo");
                    handleExportToExcelValoo(data);
                } else if (customer === "Jeho") {
                    console.log("Jeho");
                    handleExportToExcelJehoOy(data, customerId);
                } else {
                    console.log(customer);
                }
        }
    });

    const handleFetchAndExport = useCallback(() => {
        if (!buttonClickedRef.current) {
            buttonClickedRef.current = true; // Set ref to true to indicate the button has been clicked
            fetchData({ variables: { startDate, endDatePlusOne, tyomaaId } }).then(() => {
                buttonClickedRef.current = false; // Reset ref after fetch is completed
            });
        }
    }, [startDate, endDatePlusOne, tyomaaId, fetchData]);

    function addOneDay(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Add one day
        return date.toISOString().split('T')[0]; // Convert back to 'YYYY-MM-DD' format
    }

// Your input field's onChange handler
    function handleEndDateChange(e) {
        const newEndDate = e.target.value; // Get the new end date value from the input field
        setEndDate(newEndDate); // Update your state or variable with the original end date

        const endDatePlusOneconst = addOneDay(newEndDate); // Calculate the end date plus one day
        setEndDatePlusOne(endDatePlusOneconst);

        // Use endDatePlusOne in your GraphQL query
        // For example, you might set it in your query's variables or state
    }



    ///exportti koodit Valoo

    const handleExportToExcelValoo = (fetchedData) => {
        const formattedData = [];
        const excludedAttributes = [
            "__typename",
            "tyomaanosoite",
            "tyomaanid",
            "nimimerkki",
            "tyon_nimi",
            "created_date",
            "id",
            "tyo_alkoi",
            "tyo_loppui",
            "sisa_asennus_lisatietoja",
            "kaivuu_tuntityo_selite",
            "hitaus_tuntityo_selite",
            "puhallus_lisatietoja",
            "kayttoonotto_lisatietoja",
        ];

        const TYON_NIMI_MAP = {
            'Eltel jalkiliittyma': 'Eltel Jälkiliittymä',
            'Eltel uudisrakentaminen': 'Eltel uudiskohde',
            'EnersenseJalkiliittyminen': 'Enersense Jälkiliittymä',
            'EnersenseUudisrakentaminen': 'Enersense uudiskohde',
        };

        const fieldMap = {
            "sisa_asennus": "Sisäasennus",
            "sisa_asennus_laitteen_kanssa": "Sisäasennus",
            "okt_tonttikaivuu": "Tonttikaivuu",
            "okt_tonttisuunnittelu": "Tonttisuunnittelu",
            "muu_tyo": "Muu Työ",
            "tonttikaivuu_metri": "Tonttikaivuu Metri",
            "kaivinkonetyotunti": "Kaivinkone",
            "kaivinkonetyotunti_time": "Kaivinkone Työtunti",
            "aputyotunti_time": "Aputyötunti",
            "kuorma_auto_tyotunti_time": "Kuorma-auto",
            "taloyhtio_metri_kaivuu": "Taloyhtiö Metri Kaivuu",
            "tonttikaivuu_max30_metria": "Tonttikaivuu Max 30 m",
            "tonttikaivuu_yli30_metria": "Tonttikaivuu, yli 30 m",
            "kuitupuhallus_metria": "Nano puhallus",
            "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
            "pistemainen_kaivuu_kpl": "Pistemäinen Kaivuu Kpl",
            "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoisen Kaivuu Päällystetty m",
            "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoisen Kaivuu Päällystämätön m",
            "tontinulkopuolinen_nurmikko_m2": "Tontinulkopuolinen Nurmikko m²",
            "tontin_sisainen_kaivuu_paallystetty": "Tontin Sisäinen Kaivuu Päällystetty",
            "tontin_sisainen_kaivuu_paallystamaton": "Tontin Sisäinen Kaivuu Päällystämätön",
            "jatkamisen_valmistelu": "Jatkamisen tai päättämisen valmistelu ja lopputyöt",
            "katukivi_laatta_m2": "Pihalaatta",
            "kuitujen_jatkaminen": "Kuitujen Jatkaminen",
            "runkokaapelin_kasittely": "Runkokaapelin pään käsittely",
            "tilaajakaapelin_kasittely": "Tilaajakaapelin pään käsittely",
            "valokuitujen_kasittely": "Valokuitujen Käsittely",
            "tyo_alkoi": "Työ Alkoi",
            "tyo_loppui": "Työ Loppui",
            "aputyotunti": "Aputyötunti",
            "kuorma_auto_tyotunti": "Kuorma-auto",
            "liittyman_kayttoonotto": "Liittyman käyttöönotto",
            "varauksen_liittaminen_runkoon": "Varauksen liittäminen runkoon",
            "teletyo": "Teletyö tuntia",
            "suunnitteluAndKaivuuAndsisaAsennus": "Suunnittelu, kaivuu ja sisäasennus",
            "suunnitteluAndSisaAsennus": "Suunnittelu ja sisäasennus"
        };

        const priceMap = {
            "sisa_asennus": 0, // Sisäasennus
            "sisa_asennus_laitteen_kanssa": 0, // Sisäasennus
            "okt_tonttikaivuu": 235,
            "okt_tonttisuunnittelu": 0,
            "muu_tyo": 0,
            "tonttikaivuu_metri": 0,
            "kaivinkonetyotunti": 0,
            "kaivinkonetyotunti_time": 0,
            "aputyotunti_time": 0,
            "kuorma_auto_tyotunti_time": 0,
            "taloyhtio_metri_kaivuu": 0,
            "tonttikaivuu_max30_metria": 235,
            "tonttikaivuu_yli30_metria": 0,
            "kuitupuhallus_metria": 0,
            "kuitupuhallus_ftth": 0,
            "pistemainen_kaivuu_kpl": 0,
            "tontinulkoinen_kaivuu_paallystetty_metria": 0,
            "tontinulkoinen_kaivuu_paallystamaton_metria": 0,
            "tontinulkopuolinen_nurmikko_m2": 0,
            "tontin_sisainen_kaivuu_paallystetty": 0,
            "tontin_sisainen_kaivuu_paallystamaton": 0,
            "jatkamisen_valmistelu": 0,
            "katukivi_laatta_m2": 0,
            "kuitujen_jatkaminen": 0,
            "runkokaapelin_kasittely": 0,
            "tilaajakaapelin_kasittely": 0,
            "valokuitujen_kasittely": 0 ,
            "tyo_alkoi": 0,
            "tyo_loppui": 0,
            "aputyotunti": 0,
            "liittyman_kayttoonotto": 0,
            "suunnitteluAndKaivuuAndsisaAsennus": 350,
            "suunnitteluAndSisaAsennus": 125,
        };



        // Add the General Information section once at the beginning
        const firstItem = fetchedData.construction_data[0];
        const firstTyo = TYON_NIMI_MAP[firstItem.tyon_nimi] || firstItem.tyon_nimi;
        formattedData.push({ A: "OY KUOKKA - PEKKA AB", B: "TYÖERITTELY", C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAJA", B: firstItem.nimimerkki, C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAMISAIKA", B: "" });
        formattedData.push({ A: "", B: "" });
        formattedData.push({ A: "PROJEKTI", B: "UUDISRAKENTAMINEN" });
        formattedData.push({ A: "PROJEKTIN KOHDE", B: firstItem.tyomaanid });
        formattedData.push({ A: "PROJEKTIPÄÄLIKKÖ", B: "" });
        formattedData.push({ A: "OSTOTILAUKSEN POEP NUMERO", B: "" });
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});


        const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];

        // Add work entries

        const desiredOrder = [
            "okt_tonttisuunnittelu",
            "okt_tonttikaivuu",
            "sisa_asennus",
            "pistemainen_kaivuu_kpl",
            "tonttikaivuu_max30_metria",
            "tonttikaivuu_yli30_metria",
            "katukivi_laatta_m2",
            "kuorma_auto_tyotunti",
            "kaivinkone_tyotunti",
            "aputyotunti",
            "sisa_asennus_laitteen_kanssa",
            'jatkamisen_valmistelu', // Assuming these are the correct field keys for your desired columns
            'runkokaapelin_kasittely',
            'tilaajakaapelin_kasittely',
            'valokuitujen_kasittely',
            'kuitujen_jatkaminen',
        ];

        const combinedByAddress = fetchedData.construction_data.reduce((acc, curr) => {
            if (!acc[curr.tyomaanosoite]) {
                acc[curr.tyomaanosoite] = {
                    tyomaanosoite: curr.tyomaanosoite // Explicitly set tyomaanosoite here
                };

                // Initialize the desired fields with a default value
                desiredOrder.forEach(field => {
                    acc[curr.tyomaanosoite][field] = 0;
                });

                // Initialize 'muu_tyo' as an empty string to concatenate later
                acc[curr.tyomaanosoite]['muu_tyo'] = '';
            }

            // Combine fields
            for (let [field, value] of Object.entries(curr)) {
                if (field === '__typename') {
                    continue; // Skip read-only property
                }

                if (typeof value === 'number' || /^[0-9.]+$/.test(value)) {
                    // For numerical fields, aggregate their values
                    acc[curr.tyomaanosoite][field] = (parseFloat(acc[curr.tyomaanosoite][field]) || 0) + parseFloat(value);
                } else if (field === 'muu_tyo' && value.trim() !== '') {
                    // Concatenate 'muu_tyo' with existing value using '+', if not empty
                    acc[curr.tyomaanosoite]['muu_tyo'] = acc[curr.tyomaanosoite]['muu_tyo']
                        ? acc[curr.tyomaanosoite]['muu_tyo'] + ' + ' + value
                        : value;
                } else if (!desiredOrder.includes(field)) {
                    // For non-numerical and non-desired fields, overwrite the value
                    acc[curr.tyomaanosoite][field] = value;
                }
            }

            // Check specific conditions and set new fields as required
            if (acc[curr.tyomaanosoite]['okt_tonttisuunnittelu'] === 1 && acc[curr.tyomaanosoite]['sisa_asennus'] === 1 && acc[curr.tyomaanosoite]['tonttikaivuu_yli30_metria'] === 0) {
                acc[curr.tyomaanosoite]['suunnitteluAndSisaAsennus'] = 1;
                delete acc[curr.tyomaanosoite]['sisa_asennus'];
                delete acc[curr.tyomaanosoite]['okt_tonttisuunnittelu'];
            }

            if (acc[curr.tyomaanosoite]['okt_tonttisuunnittelu'] === 1 && acc[curr.tyomaanosoite]['tonttikaivuu_yli30_metria'] > 0 && acc[curr.tyomaanosoite]['sisa_asennus'] === 1) {
                acc[curr.tyomaanosoite]['suunnitteluAndKaivuuAndsisaAsennus'] = 1;
                delete acc[curr.tyomaanosoite]['sisa_asennus'];
                delete acc[curr.tyomaanosoite]['tonttikaivuu_yli30_metria'];
                delete acc[curr.tyomaanosoite]['okt_tonttisuunnittelu'];
            }

            return acc;
        }, {});



// Convert back to array
        const combinedDataArray = Object.values(combinedByAddress);
        combinedDataArray.forEach((item, index) => {
            const tyo = TYON_NIMI_MAP[item.tyon_nimi] || item.tyon_nimi;
            let jobTotal = 0; // Initialize a total sum for this job

            formattedData.push({ A: "", B: "" });

            // Work entry
            formattedData.push({ A: index +1  + ".", B: item.tyomaanosoite, C: item.tyomaanid });

            formattedData.push({});
            formattedData.push({ A: item.muu_tyo && item.muu_tyo.trim() !== "" ? item.muu_tyo : "", B: "Tuote", C: "Yksikkö", D: "Hinta yksikkö / € (alv 0 %)", E: "Yksikköä kpl", F: "Yhteensä € (alv 0 %)" });

            console.log(item)

            for (let [field, value] of Object.entries(item)) {

                if (field === "muu_tyo" && value.trim() !== "") {

                    continue; // Skip further processing for "muu_tyo"
                }

                const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];
                if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 1) {
                    const friendlyFieldMax30 = fieldMap["tonttikaivuu_max30_metria"];
                    const unitMax30 = "m";
                    const priceMax30 = priceMap["tonttikaivuu_max30_metria"] || 0;
                    // Since it's 1 unit
                    formattedData.push({B: friendlyFieldMax30, C: unitMax30, D: priceMax30, E: 1, F: priceMax30});

                    if (typeof priceMax30 !== 'undefined') {
                        jobTotal += priceMax30;
                    }

                    if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 31) {

                        value = (parseFloat(value) - 30); // Subtract 30 from the value
                    } else {
                        continue;
                    }
                }

                if (desiredFields.includes(field) && value !== "0:0") {

                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";
formattedData.push({ A: "", B: friendlyField, C: unit, D: "", E: value, F: "" }); // Price and total placeholders

                }
                else if (
                    !field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== ""
                )
                {


                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";
                     let price = priceMap[field] || 0; // Get the price from priceMap
                    let total = price * parseFloat(value); // Compute total. Ensure value is converted to float.



                    formattedData.push({ A: "", B: friendlyField, C: unit, D: price, E: value, F: total }); // Price and total placeholders
                    if (typeof total !== 'undefined') {
                        jobTotal += total;
                    }

                }

            }
            formattedData.push({ A: "", B: "Yhteensä", F: jobTotal }); // Total placeholder
            formattedData.push({}); // 3 empty rows
        });

        const ws = XLSX.utils.json_to_sheet(formattedData, { header: ["A", "B", "C", "D", "E", "F"], skipHeader: true });


        // Set column widths
        ws['!cols'] = [
            { wch: 35 }, // Column A
            { wch: 30 }, // Column B
            { wch: 25 }, // Column C
            { wch: 15 }, // Column D
            { wch: 20 }  // Column E
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx", { cellStyles: true });
    };

    //CABLE CREW HINNAT

    const handleExportToExcelCableCrew = (fetchedData) => {
        const formattedData = [];
        const excludedAttributes = [
            "__typename",
            "tyomaanosoite",
            "tyomaanid",
            "nimimerkki",
            "tyon_nimi",
            "created_date",
            "id",
            "tyo_alkoi",
            "tyo_loppui",
            "sisa_asennus_lisatietoja",
            "kaivuu_tuntityo_selite",
            "hitaus_tuntityo_selite",
            "puhallus_lisatietoja",
            "kayttoonotto_lisatietoja",
            "kuitupuhallus_metria"
        ];

        const TYON_NIMI_MAP = {
            'Eltel jalkiliittyma': 'Eltel Jälkiliittymä',
            'Eltel uudisrakentaminen': 'Eltel uudiskohde',
            'EnersenseJalkiliittyminen': 'Enersense Jälkiliittymä',
            'EnersenseUudisrakentaminen': 'Enersense uudiskohde',
        };

        const fieldMap = {
            "sisa_asennus": "Sisäasennus",
            "sisa_asennus_laitteen_kanssa": "Sisäasennus",
            "okt_tonttikaivuu": "Tonttikaivuu",
            "okt_tonttisuunnittelu": "Tonttisuunnittelu",
            "muu_tyo": "Muu Työ",
            "tonttikaivuu_metri": "Tonttikaivuu Metri",
            "kaivinkonetyotunti": "Kaivinkone",
            "kaivinkonetyotunti_time": "Kaivinkone Työtunti",
            "aputyotunti_time": "Aputyötunti",
            "kuorma_auto_tyotunti_time": "Kuorma-auto",
            "taloyhtio_metri_kaivuu": "Taloyhtiö Metri Kaivuu",
            "tonttikaivuu_max30_metria": "Tonttikaivuu Max 15 m",
            "tonttikaivuu_yli30_metria": "Tonttikaivuu, yli 15 m",
            "kuitupuhallus_metria": "Nano puhallus",
            "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
            "pistemainen_kaivuu_kpl": "Pistemäinen Kaivuu Kpl",
            "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoisen Kaivuu Päällystetty m",
            "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoisen Kaivuu Päällystämätön m",
            "tontinulkopuolinen_nurmikko_m2": "Tontinulkopuolinen Nurmikko m²",
            "tontin_sisainen_kaivuu_paallystetty": "Tontin Sisäinen Kaivuu Päällystetty",
            "tontin_sisainen_kaivuu_paallystamaton": "Tontin Sisäinen Kaivuu Päällystämätön",
            "jatkamisen_valmistelu": "Jatkamisen tai päättämisen valmistelu ja lopputyöt",
            "katukivi_laatta_m2": "Pihalaatta",
            "kuitujen_jatkaminen": "Kuitujen Jatkaminen",
            "runkokaapelin_kasittely": "Runkokaapelin pään käsittely",
            "tilaajakaapelin_kasittely": "Tilaajakaapelin pään käsittely",
            "valokuitujen_kasittely": "Valokuitujen Käsittely",
            "tyo_alkoi": "Työ Alkoi",
            "tyo_loppui": "Työ Loppui",
            "aputyotunti": "Aputyötunti",
            "kuorma_auto_tyotunti": "Kuorma-auto",
            "liittyman_kayttoonotto": "Liittyman käyttöönotto",
            "varauksen_liittaminen_runkoon": "Varauksen liittäminen runkoon",
            "teletyo": "Teletyö tuntia",
            "suunnittelu_tuntia": "Suunnittelu tuntia",
            "sisa_asennus_tuntia": "Sisa asennus tuntia",
            "kayttoon_otto_tuntia": "Kayttoonotto tuntia",
            "puhallus_tuntia": "Puhallus tuntia"
        };

        let priceMap = {
            "sisa_asennus": 110, // Sisäasennus
            "sisa_asennus_laitteen_kanssa": 110, // Sisäasennus
            "okt_tonttikaivuu": 0,
            "okt_tonttisuunnittelu": 75,
            "muu_tyo": 0,
            "tonttikaivuu_metri": 0,
            "kaivinkonetyotunti": 60,
            "kaivinkonetyotunti_time": 60,
            "aputyotunti_time": 40,
            "kuorma_auto_tyotunti": 60,
            "kuorma_auto_tyotunti_time": 60,
            "taloyhtio_metri_kaivuu": 0,
            "tonttikaivuu_max30_metria": 275,
            "tonttikaivuu_yli30_metria": 7.5,
            "kuitupuhallus_metria": 0.8,
            "kuitupuhallus_ftth": 0.8,
            "pistemainen_kaivuu_kpl": 80,
            "tontinulkoinen_kaivuu_paallystetty_metria": 0,
            "tontinulkoinen_kaivuu_paallystamaton_metria": 0,
            "tontinulkopuolinen_nurmikko_m2": 0,
            "tontin_sisainen_kaivuu_paallystetty": 0,
            "tontin_sisainen_kaivuu_paallystamaton": 0,
            "jatkamisen_valmistelu": 70,
            "katukivi_laatta_m2": 50,
            "kuitujen_jatkaminen": 70,
            "varauksen_liittaminen_runkoon": 25,
            "runkokaapelin_kasittely": 47,
            "tilaajakaapelin_kasittely": 20,
            "valokuitujen_kasittely": 1.5,
            "tyo_alkoi": 0,
            "tyo_loppui": 0,
            "aputyotunti": 40,
            "liittyman_kayttoonotto": 75,
            "suunnittelu_tuntia": 0,
            "sisa_asennus_tuntia": 0,
            "kayttoon_otto_tuntia": 0,
            "puhallus_tuntia": 0
        };

       // Check for project ID and update priceMap if it matches 303
       if (fetchedData.construction_data[0].tyomaanid === "VK, Jälkiliittyjät") {
           priceMap = {
               "sisa_asennus": 120, // Updated price
               "sisa_asennus_laitteen_kanssa": 120, // Updated price
               "okt_tonttikaivuu": 0,
               "okt_tonttisuunnittelu": 80, // Updated price
               "muu_tyo": 0,
               "tonttikaivuu_metri": 0,
               "kaivinkonetyotunti": 60, // Updated price
               "kaivinkonetyotunti_time": 60, // Updated price
               "aputyotunti_time": 40, // Updated price
               "kuorma_auto_tyotunti": 60,
               "kuorma_auto_tyotunti_time": 60, // Updated price
               "taloyhtio_metri_kaivuu": 0,
               "tonttikaivuu_max30_metria": 310, // Updated price
               "tonttikaivuu_yli30_metria": 8, // Updated price
               "kuitupuhallus_metria": 0.8, // Updated price
               "kuitupuhallus_ftth": 0.8, // Updated price
               "pistemainen_kaivuu_kpl": 90, // Updated price
               "tontinulkoinen_kaivuu_paallystetty_metria": 16,
               "tontinulkoinen_kaivuu_paallystamaton_metria": 8,
               "tontinulkopuolinen_nurmikko_m2": 0,
               "tontin_sisainen_kaivuu_paallystetty": 0,
               "tontin_sisainen_kaivuu_paallystamaton": 0,
               "jatkamisen_valmistelu": 70, // Updated price
               "katukivi_laatta_m2": 50, // Updated price
               "kuitujen_jatkaminen": 70, // Updated price
               "varauksen_liittaminen_runkoon": 25, // Updated price
               "runkokaapelin_kasittely": 47, // Updated price
               "tilaajakaapelin_kasittely": 20, // Updated price
               "valokuitujen_kasittely": 1.5, // Updated price
               "tyo_alkoi": 0,
               "tyo_loppui": 0,
               "aputyotunti": 40, // Updated price
               "liittyman_kayttoonotto": 75, // Updated price
               "suunnittelu_tuntia": 0,
               "sisa_asennus_tuntia": 0,
               "kayttoon_otto_tuntia": 0,
               "puhallus_tuntia": 0
           };
       }

        // Add the General Information section once at the beginning
        const firstItem = fetchedData.construction_data[0];
        const firstTyo = TYON_NIMI_MAP[firstItem.tyon_nimi] || firstItem.tyon_nimi;
        formattedData.push({ A: "OY KUOKKA - PEKKA AB", B: "TYÖERITTELY", C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAJA", B: firstItem.nimimerkki, C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAMISAIKA", B: "" });
        formattedData.push({ A: "", B: "" });
        formattedData.push({ A: "PROJEKTI", B: "UUDISRAKENTAMINEN" });
        formattedData.push({ A: "PROJEKTIN KOHDE", B: firstItem.tyomaanid });
        formattedData.push({ A: "PROJEKTIPÄÄLIKKÖ", B: "" });
        formattedData.push({ A: "OSTOTILAUKSEN POEP NUMERO", B: "" });
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});


        const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];

        // Add work entries

        const desiredOrder = [
            "okt_tonttisuunnittelu",
            "okt_tonttikaivuu",
            "sisa_asennus",
            "pistemainen_kaivuu_kpl",
            "tonttikaivuu_max30_metria",
            "tonttikaivuu_yli30_metria",
            "katukivi_laatta_m2",
            "kuorma_auto_tyotunti",
            "kaivinkone_tyotunti",
            "aputyotunti",
            "sisa_asennus_laitteen_kanssa",
            'jatkamisen_valmistelu', // Assuming these are the correct field keys for your desired columns
            'runkokaapelin_kasittely',
            'tilaajakaapelin_kasittely',
            'valokuitujen_kasittely',
            'kuitujen_jatkaminen',
        ];

        const combinedByAddress = fetchedData.construction_data.reduce((acc, curr) => {
            if (!acc[curr.tyomaanosoite]) {
                acc[curr.tyomaanosoite] = {
                    tyomaanosoite: curr.tyomaanosoite // Explicitly set tyomaanosoite here
                };

                // Initialize the desired fields with a default value
                desiredOrder.forEach(field => {
                    acc[curr.tyomaanosoite][field] = 0;
                });

                // Initialize 'muu_tyo' as an empty string to concatenate later
                acc[curr.tyomaanosoite]['muu_tyo'] = '';
                acc[curr.tyomaanosoite]['sisa_asennus_lisatietoja'] = '';
                acc[curr.tyomaanosoite]['kaivuu_tuntityo_selite'] = '';
                acc[curr.tyomaanosoite]['hitaus_tuntityo_selite'] = '';
                acc[curr.tyomaanosoite]['puhallus_lisatietoja'] = '';
                acc[curr.tyomaanosoite]['kayttoonotto_lisatietoja'] = '';
            }

            // Combine fields
            for (let [field, value] of Object.entries(curr)) {
                if (field === '__typename') {
                    continue; // Skip read-only property
                }

                if (typeof value === 'number' || /^[0-9.]+$/.test(value)) {
                    // For numerical fields, aggregate their values
                    acc[curr.tyomaanosoite][field] = (parseFloat(acc[curr.tyomaanosoite][field]) || 0) + parseFloat(value);
                } else if (field === 'muu_tyo' && value.trim() !== '') {
                    // Concatenate 'muu_tyo' with existing value using '+', if not empty
                    acc[curr.tyomaanosoite]['muu_tyo'] = acc[curr.tyomaanosoite]['muu_tyo']
                        ? acc[curr.tyomaanosoite]['muu_tyo'] + ' + ' + value
                        : value;
                } else if (!desiredOrder.includes(field)) {
                    // For non-numerical and non-desired fields, overwrite the value
                    acc[curr.tyomaanosoite][field] = value;
                }
            }

            return acc;
        }, {});

        // Convert back to array
        const combinedDataArray = Object.values(combinedByAddress);
        combinedDataArray.forEach((item, index) => {
            const tyo = TYON_NIMI_MAP[item.tyon_nimi] || item.tyon_nimi;
            let jobTotal = 0; // Initialize a total sum for this job

            formattedData.push({ A: "", B: "" });

            // Work entry
            formattedData.push({ A: index + 1 + ".", B: item.tyomaanosoite, C: item.tyomaanid });

            formattedData.push({});
            const muuTyoMaxChars = 35;

            const splitTextOnWords = (text, maxChars) => {
                let result = [];
                let words = text.split(' ');
                let currentLine = words[0];

                for (let i = 1; i < words.length; i++) {
                    if (currentLine.length + words[i].length + 1 <= maxChars) {
                        currentLine += ' ' + words[i];
                    } else {
                        result.push(currentLine);
                        currentLine = words[i];
                    }
                }
                result.push(currentLine);
                return result;
            };

            let muuTyoLines = item.muu_tyo && item.muu_tyo.trim() !== "" ? splitTextOnWords(item.muu_tyo, muuTyoMaxChars) : [""];

// Insert the lines into formattedData
            muuTyoLines.forEach((line, index) => {
                formattedData.push({
                    A: line,
                    B: index === 0 ? "Tuote" : "",
                    C: index === 0 ? "Yksikkö" : "",
                    D: index === 0 ? "Hinta yksikkö / € (alv 0 %)" : "",
                    E: index === 0 ? "Yksikköä kpl" : "",
                    F: index === 0 ? "Yhteensä € (alv 0 %)" : ""
                });
            });

            let lisatietojaSet = false; // Flag to check if lisatietoja is already set

            for (let [field, value] of Object.entries(item)) {
                if (field === "muu_tyo" && value.trim() !== "") {
                    continue; // Skip further processing for "muu_tyo"
                }

                const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];
                if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 1) {
                    const friendlyFieldMax30 = fieldMap["tonttikaivuu_max30_metria"];
                    const unitMax30 = "m";
                    const priceMax30 = priceMap["tonttikaivuu_max30_metria"] || 0;
                    // Since it's 1 unit
                    formattedData.push({ B: friendlyFieldMax30, C: unitMax30, D: priceMax30, E: 1, F: priceMax30 });

                    if (typeof priceMax30 !== 'undefined') {
                        jobTotal += priceMax30;
                    }

                    if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 16) {
                        value = (parseFloat(value) - 15); // Subtract 30 from the value
                    } else {
                        continue;
                    }
                }

                if (desiredFields.includes(field) && value !== "0:0") {
                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";
formattedData.push({ A: "", B: friendlyField, C: unit, D: "", E: value, F: "" }); // Price and total placeholders

                } else if (
                    !field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== ""
                ) {
                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";
let price = priceMap[field] || 0; // Get the price from priceMap
                    let total = price * parseFloat(value); // Compute total. Ensure value is converted to float.
                    let lisatietoja = "";

                    if (field === 'sisa_asennus' && parseFloat(value) >= 1) {
                        lisatietoja = item.sisa_asennus_lisatietoja || "";
                    }
                    if (['aputyotunti', 'kaivinkonetyotunti', 'kuorma_auto_tyotunti'].includes(field) && parseFloat(value) > 0 && !lisatietojaSet) {
                        lisatietoja = item.kaivuu_tuntityo_selite || "";
                        lisatietojaSet = true; // Set the flag
                    }
                    if (field === 'teletyo' && parseFloat(value) > 0) {
                        lisatietoja = item.hitaus_tuntityo_selite || "";
                    }
                    if (field === 'puhallus_tuntia' && parseFloat(value) > 0 ) {
                        lisatietoja = item.puhallus_lisatietoja || "";
                    }
                    if (field === 'kayttoon_otto_tuntia' && parseFloat(value) > 0) {
                        lisatietoja = item.kayttoonotto_lisatietoja || "";
                    }

// Split 'lisatietoja' into chunks of 15 characters and add each chunk as a separate row
                    const maxChars = 40;

                    const splitText = (text, maxChars) => {
                        let result = [];
                        let words = text.split(' ');
                        let currentLine = words[0];

                        for (let i = 1; i < words.length; i++) {
                            if (currentLine.length + words[i].length + 1 <= maxChars) {
                                currentLine += ' ' + words[i];
                            } else {
                                result.push(currentLine);
                                currentLine = words[i];
                            }
                        }
                        result.push(currentLine);
                        return result;
                    };

                    if (lisatietoja.length > maxChars) {
                        let splitLisatietoja = splitText(lisatietoja, maxChars);
                        splitLisatietoja.forEach((chunk, index) => {
                            formattedData.push({
                                A: chunk,
                                B: index === 0 ? friendlyField : "", // Only include friendlyField on the first row
                                C: index === 0 ? unit : "",           // Only include unit on the first row
                                D: index === 0 ? price : "",          // Only include price on the first row
                                E: index === 0 ? value : "",          // Only include value on the first row
                                F: index === 0 ? total : ""           // Only include total on the first row
                            });
                        });
                    } else {
                        formattedData.push({ A: lisatietoja, B: friendlyField, C: unit, D: price, E: value, F: total });
                    }

                    if (typeof total !== 'undefined') {
                        jobTotal += total;
                    }
                }
            }
            formattedData.push({ A: "", B: "Yhteensä", F: jobTotal }); // Total placeholder
            formattedData.push({}); // 3 empty rows
        });

        const ws = XLSX.utils.json_to_sheet(formattedData, { header: ["A", "B", "C", "D", "E", "F"], skipHeader: true });

        // Set column widths
        ws['!cols'] = [
            { wch: 35 }, // Column A
            { wch: 30 }, // Column B
            { wch: 25 }, // Column C
            { wch: 15 }, // Column D
            { wch: 20 }  // Column E
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx", { cellStyles: true });
    };




    //enersense jeho oy hitsaus jutut

    const handleExportToExcelJehoOy = (fetchedData, customerId) => {
        const formattedData = [];

        const TYON_NIMI_MAP = {
            'Eltel jalkiliittyma': 'Eltel Jälkiliittymä',
            'Eltel uudisrakentaminen': 'Eltel uudiskohde',
            'EnersenseJalkiliittyminen': 'Enersense Jälkiliittymä',
            'EnersenseUudisrakentaminen': 'Enersense uudiskohde',
        };

        const fieldMap = {
            "sisa_asennus": "Sisäasennus",
            "sisa_asennus_laitteen_kanssa": "Sisäasennus",
            "okt_tonttikaivuu": "Tonttikaivuu",
            "okt_tonttisuunnittelu": "Tonttisuunnittelu",
            "muu_tyo": "Muu Työ",
            "tonttikaivuu_metri": "Tonttikaivuu Metri",
            "kaivinkonetyotunti": "Kaivinkone",
            "kaivinkonetyotunti_time": "Kaivinkone Työtunti",
            "aputyotunti_time": "Aputyötunti",
            "kuorma_auto_tyotunti_time": "Kuorma-auto",
            "taloyhtio_metri_kaivuu": "Taloyhtiö Metri Kaivuu",
            "tonttikaivuu_max30_metria": "Tonttikaivuu Max 30 m",
            "tonttikaivuu_yli30_metria": "Tonttikaivuu, yli 30 m",
            "kuitupuhallus_metria": "Nano puhallus",
            "kuitupuhallus_ftth": "Kuitupuhallus FTTH",
            "pistemainen_kaivuu_kpl": "Pistemäinen Kaivuu Kpl",
            "tontinulkoinen_kaivuu_paallystetty_metria": "Tontinulkoisen Kaivuu Päällystetty m",
            "tontinulkoinen_kaivuu_paallystamaton_metria": "Tontinulkoisen Kaivuu Päällystämätön m",
            "tontinulkopuolinen_nurmikko_m2": "Tontinulkopuolinen Nurmikko m²",
            "tontin_sisainen_kaivuu_paallystetty": "Tontin Sisäinen Kaivuu Päällystetty",
            "tontin_sisainen_kaivuu_paallystamaton": "Tontin Sisäinen Kaivuu Päällystämätön",
            "jatkamisen_valmistelu": "Jatkamisen tai päättämisen valmistelu ja lopputyöt",
            "katukivi_laatta_m2": "Pihalaatta",
            "kuitujen_jatkaminen": "Kuitujen Jatkaminen",
            "runkokaapelin_kasittely": "Runkokaapelin pään käsittely",
            "tilaajakaapelin_kasittely": "Tilaajakaapelin pään käsittely",
            "valokuitujen_kasittely": "Valokuitujen Käsittely",
            "tyo_alkoi": "Työ Alkoi",
            "tyo_loppui": "Työ Loppui",
            "aputyotunti": "Aputyötunti",
            "kuorma_auto_tyotunti": "Kuorma-auto",
            "liittyman_kayttoonotto": "Liittyman käyttöönotto",
            "varauksen_liittaminen_runkoon": "Varauksen liittäminen runkoon",
            "teletyo": "Teletyö tuntia",
            "suunnittelu_tuntia": "Suunnittelu tuntia",
            "sisa_asennus_tuntia": "Sisäasennus tuntia",
            "kayttoon_otto_tuntia": "Käyttöönotto tuntia",
            "puhallus_tuntia": "Puhallus tuntia",
            "etakayttoonotto": "Etäkäyttöönotto"
        };

        const priceMap = {
            "sisa_asennus": 145, // Sisäasennus
            "sisa_asennus_laitteen_kanssa": 145, // Sisäasennus
            "okt_tonttikaivuu": 0,
            "okt_tonttisuunnittelu": 90,
            "muu_tyo": 0,
            "tonttikaivuu_metri": 0,
            "kaivinkonetyotunti": 58,
            "kaivinkonetyotunti_time": 58,
            "aputyotunti_time": 38,
            "kuorma_auto_tyotunti": 58,
            "kuorma_auto_tyotunti_time": 58,
            "taloyhtio_metri_kaivuu": 0,
            "tonttikaivuu_max30_metria": 340,
            "tonttikaivuu_yli30_metria": 9,
            "kuitupuhallus_metria": 1.2,
            "kuitupuhallus_ftth": 1.2,
            "pistemainen_kaivuu_kpl": 120,
            "tontinulkoinen_kaivuu_paallystetty_metria": 0,
            "tontinulkoinen_kaivuu_paallystamaton_metria": 0,
            "tontinulkopuolinen_nurmikko_m2": 0,
            "tontin_sisainen_kaivuu_paallystetty": 0,
            "tontin_sisainen_kaivuu_paallystamaton": 0,
            "jatkamisen_valmistelu": 70,
            "katukivi_laatta_m2": 60,
            "kuitujen_jatkaminen": 4,
            "runkokaapelin_kasittely": 47,
            "tilaajakaapelin_kasittely": 20,
            "valokuitujen_kasittely": 1.5 ,
            "tyo_alkoi": 0,
            "tyo_loppui": 0,
            "aputyotunti": 38,
            "liittyman_kayttoonotto": 75,
            "teletyo": 45,
            "suunnittelu_tuntia": 0,
            "sisa_asennus_tuntia": 0,
            "kayttoon_otto_tuntia": 0,
            "puhallus_tuntia": 0,
            "etakayttoonotto": 0
        };

        if (customerId === 2) {
            priceMap["kuitupuhallus_metria"] = 0.8;
        } else {
            priceMap["kuitupuhallus_metria"] = 1.2;
        }



        // Add the General Information section once at the beginning
        const firstItem = fetchedData.construction_data[0];

        let excludedAttributes = [
            "__typename",
            "tyomaanosoite",
            "tyomaanid",
            "nimimerkki",
            "tyon_nimi",
            "created_date",
            "id",
            "tyo_alkoi",
            "tyo_loppui",
            "sisa_asennus_lisatietoja",
            "kaivuu_tuntityo_selite",
            "hitaus_tuntityo_selite",
            "puhallus_lisatietoja",
            "kayttoonotto_lisatietoja",
            "muu_tyo"
        ];

        if (customerId === 2) {
            excludedAttributes = [
                ...excludedAttributes,
                "jatkamisen_valmistelu",
                "runkokaapelin_kasittely",
                "tilaajakaapelin_kasittely",
                "valokuitujen_kasittely",
                "kuitujen_jatkaminen",
                "liittyman_kayttoonotto",
                "teletyo"
            ];
        }


        const firstTyo = TYON_NIMI_MAP[firstItem.tyon_nimi] || firstItem.tyon_nimi;
        formattedData.push({ A: "Jeho Kuitutyö Oy", B: "TYÖERITTELY", C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAJA", B: firstItem.nimimerkki, C: "" });
        formattedData.push({ A: "", B: "", C: "" });
        formattedData.push({ A: "TYÖN SUORITTAMISAIKA", B: "" });
        formattedData.push({ A: "", B: "" });
        formattedData.push({ A: "PROJEKTI", B: "UUDISRAKENTAMINEN" });
        formattedData.push({ A: "PROJEKTIN KOHDE", B: firstItem.tyomaanid });
        formattedData.push({ A: "PROJEKTIPÄÄLIKKÖ", B: "" });
        formattedData.push({ A: "OSTOTILAUKSEN POEP NUMERO", B: "" });
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});
        formattedData.push({});


        const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];

        // Add work entries

        const desiredOrder = [
            "okt_tonttisuunnittelu",
            "okt_tonttikaivuu",
            "sisa_asennus",
            "pistemainen_kaivuu_kpl",
            "tonttikaivuu_max30_metria",
            "tonttikaivuu_yli30_metria",
            "katukivi_laatta_m2",
            "kuorma_auto_tyotunti",
            "kaivinkone_tyotunti",
            "aputyotunti",
            "sisa_asennus_laitteen_kanssa",
            'jatkamisen_valmistelu', // Assuming these are the correct field keys for your desired columns
            'runkokaapelin_kasittely',
            'tilaajakaapelin_kasittely',
            'valokuitujen_kasittely',
            'kuitujen_jatkaminen',
        ];

        const combinedByAddress = fetchedData.construction_data.reduce((acc, curr) => {
            if (!acc[curr.tyomaanosoite]) {
                acc[curr.tyomaanosoite] = {
                    tyomaanosoite: curr.tyomaanosoite // Explicitly set tyomaanosoite here
                };

                // Initialize the desired fields with a default value
                desiredOrder.forEach(field => {
                    acc[curr.tyomaanosoite][field] = 0;
                });

                // Initialize 'muu_tyo' and additional fields as empty strings to concatenate later
                acc[curr.tyomaanosoite]['muu_tyo'] = '';
                acc[curr.tyomaanosoite]['sisa_asennus_lisatietoja'] = '';
                acc[curr.tyomaanosoite]['kaivuu_tuntityo_selite'] = '';
                acc[curr.tyomaanosoite]['hitaus_tuntityo_selite'] = '';
                acc[curr.tyomaanosoite]['puhallus_lisatietoja'] = '';
                acc[curr.tyomaanosoite]['kayttoonotto_lisatietoja'] = '';
            }

            // Combine fields
            for (let [field, value] of Object.entries(curr)) {
                if (field === '__typename') {
                    continue; // Skip read-only property
                }

                if (typeof value === 'number' || /^[0-9.]+$/.test(value)) {
                    // For numerical fields, aggregate their values
                    acc[curr.tyomaanosoite][field] = (parseFloat(acc[curr.tyomaanosoite][field]) || 0) + parseFloat(value);
                } else if (field === 'muu_tyo' && value.trim() !== '') {
                    // Concatenate 'muu_tyo' with existing value using '+', if not empty
                    acc[curr.tyomaanosoite]['muu_tyo'] = acc[curr.tyomaanosoite]['muu_tyo']
                        ? acc[curr.tyomaanosoite]['muu_tyo'] + ' + ' + value
                        : value;
                } else if (!desiredOrder.includes(field)) {
                    // For non-numerical and non-desired fields, overwrite the value
                    acc[curr.tyomaanosoite][field] = value;
                }
            }

            return acc;
        }, {});

        const hasValidFields = (item) => {
            const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];
            const isValid = Object.entries(item).some(([field, value]) => {


                if (desiredFields.includes(field) && value !== "0:0") {
                    return true;
                }

                if (
                    !field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== "" &&
                    value !== undefined &&
                    value !== "0:undefined"
                ) {
                    return true;
                }

                return false;
            });

            return isValid;
        };

// Convert back to array
        const combinedDataArray = Object.values(combinedByAddress);
        let validIndex = 0; // Separate counter for valid items
        combinedDataArray.forEach((item, index) => {

            let lisatietojaSet = false; // Flag to check if lisatietoja is already set


            console.log("Processing item:", item);
            console.log(hasValidFields(item))

            if (!hasValidFields(item)) {
                return; // Skip items with no valid fields
            }
            validIndex++; // Increment only for valid items

            const tyo = TYON_NIMI_MAP[item.tyon_nimi] || item.tyon_nimi;
            let jobTotal = 0; // Initialize a total sum for this job

            formattedData.push({ A: "", B: "" });

            // Work entry
            formattedData.push({ A: validIndex + ".", B: item.tyomaanosoite, C: item.tyomaanid });

            formattedData.push({});
            const muuTyoMaxChars = 35;

            const splitTextOnWords = (text, maxChars) => {
                let result = [];
                let words = text.split(' ');
                let currentLine = words[0];

                for (let i = 1; i < words.length; i++) {
                    if (currentLine.length + words[i].length + 1 <= maxChars) {
                        currentLine += ' ' + words[i];
                    } else {
                        result.push(currentLine);
                        currentLine = words[i];
                    }
                }
                result.push(currentLine);
                return result;
            };

            let muuTyoLines = item.muu_tyo && item.muu_tyo.trim() !== "" ? splitTextOnWords(item.muu_tyo, muuTyoMaxChars) : [""];

// Insert the lines into formattedData
            muuTyoLines.forEach((line, index) => {
                formattedData.push({
                    A: line,
                    B: index === 0 ? "Tuote" : "",
                    C: index === 0 ? "Yksikkö" : "",
                    D: index === 0 ? "Hinta yksikkö / € (alv 0 %)" : "",
                    E: index === 0 ? "Yksikköä kpl" : "",
                    F: index === 0 ? "Yhteensä € (alv 0 %)" : ""
                });
            });

            for (let [field, value] of Object.entries(item)) {

                if (field === "muu_tyo" && value.trim() !== "") {
                    continue; // Skip further processing for "muu_tyo"
                }



                const desiredFields = ['kaivinkonetyotunti_time', 'aputyotunti_time', 'kuorma_auto_tyotunti_time'];
                if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 1) {
                    const friendlyFieldMax30 = fieldMap["tonttikaivuu_max30_metria"];
                    const unitMax30 = "m";
                    const priceMax30 = priceMap["tonttikaivuu_max30_metria"] || 0;
                    // Since it's 1 unit
                    formattedData.push({ B: friendlyFieldMax30, C: unitMax30, D: priceMax30, E: 1, F: priceMax30 });

                    if (typeof priceMax30 !== 'undefined') {
                        jobTotal += priceMax30;
                    }

                    if (field === "tonttikaivuu_yli30_metria" && parseFloat(value) >= 31) {
                        value = (parseFloat(value) - 30); // Subtract 30 from the value
                    } else {
                        continue;
                    }
                }

                if (desiredFields.includes(field) && value !== "0:0") {
                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";


                    formattedData.push({ A: "", B: friendlyField, C: unit, D: "", E: value, F: "" }); // Price and total placeholders


                } else if (
                    !field.endsWith('_time') &&
                    !excludedAttributes.includes(field) &&
                    value !== null &&
                    value !== 0 &&
                    value !== "0:0" &&
                    value !== ""
                ) {
                    let friendlyField = fieldMap[field] ? fieldMap[field] : field;
                    let normalizedField = field.replace(/[,]/g, ''); // Remove commas
                    let unit = field.includes("metri") ? "m" :
                        field.includes("tunti") ? "H" : field.includes("elety") ? "H" :
                            field.includes("katukivi") ? "m2" :
                                "Kpl";


                    let price = priceMap[field] || 0; // Get the price from priceMap
                    let total = price * parseFloat(value); // Compute total. Ensure value is converted to float.


                    let lisatietoja = "";

                    if (field === 'sisa_asennus' && parseFloat(value) >= 1) {
                        lisatietoja = item.sisa_asennus_lisatietoja || "";
                    }
                    if (['aputyotunti', 'kaivinkonetyotunti', 'kuorma_auto_tyotunti'].includes(field) && parseFloat(value) > 0 && !lisatietojaSet) {
                        lisatietoja = item.kaivuu_tuntityo_selite || "";
                        lisatietojaSet = true; // Set the flag
                    }
                    if (field === 'teletyo' && parseFloat(value) > 0) {
                        lisatietoja = item.hitaus_tuntityo_selite || "";
                    }
                    if (field === 'puhallus_tuntia' && parseFloat(value) > 0) {
                        lisatietoja = item.puhallus_lisatietoja || "";
                    }
                    if (field === 'kayttoon_otto_tuntia' && parseFloat(value) > 0) {
                        lisatietoja = item.kayttoonotto_lisatietoja || "";
                    }

// Split 'lisatietoja' into chunks of 15 characters and add each chunk as a separate row
                    const maxChars = 40;

                    const splitText = (text, maxChars) => {
                        let result = [];
                        let words = text.split(' ');
                        let currentLine = words[0];

                        for (let i = 1; i < words.length; i++) {
                            if (currentLine.length + words[i].length + 1 <= maxChars) {
                                currentLine += ' ' + words[i];
                            } else {
                                result.push(currentLine);
                                currentLine = words[i];
                            }
                        }
                        result.push(currentLine);
                        return result;
                    };

                    if (lisatietoja.length > maxChars) {
                        let splitLisatietoja = splitText(lisatietoja, maxChars);
                        splitLisatietoja.forEach((chunk, index) => {
                            formattedData.push({
                                A: chunk,
                                B: index === 0 ? friendlyField : "", // Only include friendlyField on the first row
                                C: index === 0 ? unit : "",           // Only include unit on the first row
                                D: index === 0 ? price : "",          // Only include price on the first row
                                E: index === 0 ? value : "",          // Only include value on the first row
                                F: index === 0 ? total : ""           // Only include total on the first row
                            });
                        });
                    } else {
                        formattedData.push({ A: lisatietoja, B: friendlyField, C: unit, D: price, E: value, F: total });
                    }

                    if (typeof total !== 'undefined') {
                        jobTotal += total;
                    }

                }
            }
            formattedData.push({ A: "", B: "Yhteensä", F: jobTotal }); // Total placeholder
            formattedData.push({}); // 3 empty rows
        });

        const ws = XLSX.utils.json_to_sheet(formattedData, { header: ["A", "B", "C", "D", "E", "F"], skipHeader: true });

// Set column widths
        ws['!cols'] = [
            { wch: 35 }, // Column A
            { wch: 30 }, // Column B
            { wch: 25 }, // Column C
            { wch: 15 }, // Column D
            { wch: 20 }  // Column E
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx", { cellStyles: true });

    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;


    return (
        <div>
            <label>
                Alku pvm:
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
            </label>
            <label>
                Loppu pvm:
                <input type="date" value={endDate} onChange={handleEndDateChange} />
            </label>
            {customer === "Jeho" && (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {fieldKeys.map(field => (
                        <label key={field} style={{marginBottom: '8px'}}>
                            <input
                                type="checkbox"
                                checked={checkboxStates[field]}
                                onChange={() => handleCheckboxChange(field)}
                            />
                            {fieldMap[field]}
                        </label>
                    ))}
                </div>
            )}
            <button onClick={handleFetchAndExport}>Lataa excel, Pasin ikioma nappula</button>
        </div>
    );

}

export default Download;
